import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { errorNotify } from 'utils/toaster';
import { useQuery, useMutation } from 'react-apollo';
import moment from 'moment';

//Import Breadcrumb
import CustomSpinner from '../../ui/CustomSpinner';
import { DetailLayoutView } from 'components/DetailView';
import { GetAGroupQuery } from 'graphql/query/getAGroup';
import { InserAdminMessage } from 'graphql/insert/notify_admin';
import { UpadatePlanMutation } from 'graphql/update/updatePlan'
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { pluralizeText } from 'utils/pluralizeText';
import { UserContext } from 'context/UserContext';

const GroupDetail = () => {
	const { id } = useParams();
	const { isAdmin, user, isAdminPortal } = useContext(UserContext);
	const [groupVisibility, setGroupVisibility] = useState(undefined);
	const [groupStatus, setGroupStatus] = useState(undefined);

	const [deactivateSelection, setDeactivateSelection] = useState(undefined);
	const [updateGroup, { loading }] = useMutation(UpadatePlanMutation);
	const [insertAdminMessage] = useMutation(InserAdminMessage);

	const { data: groupData, loading: groupLoading } = useQuery(GetAGroupQuery, {
		variables: {
			planId: id,
		},
	});

	const groupCount = groupData?.plan[0]?.users_aggregate?.aggregate?.count + 1;
	const group = groupData?.plan[0];

	const image = group?.books[0]?.book?.imageUrl;

	let GroupVisibilitySelection = [
		{ label: 'Public', value: 'Public' },
		{ label: 'Private', value: 'Private' },
	];

	let GroupStatusSelection = [
		{ label: 'Live', value: 'Live' },
		{ label: 'Deleted', value: 'Deleted' },
	]

	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	}
 
	const SaveChanges = async () => {
	   // helps to deactivate active select fields state
	   const result = getRandomInt(1, 1000);
 
	  try {
			await updateGroup({
				variables: {
					where: { id: { _eq: group?.id } },
					set: {
					private: groupVisibility === undefined ? group?.private : groupVisibility === "Private" ? true : false,
					deleted: groupStatus === undefined ? group?.deleted : groupStatus === "Live" ? false : true,
					},
				},
				refetchQueries: [{ 
					query: GetAGroupQuery, 
					variables: { planId: id }
				}]
		  });

			if (groupVisibility !== undefined && isAdminPortal) {
				const message = `${user?.username} changed ${group?.name} group visibility to ${groupVisibility}`;
				await insertAdminMessage({
					variables: {
						message
					}
				})
			}

			if (groupStatus !== undefined && isAdminPortal) {
				const message = `${user?.username} changed ${group?.name} group status to ${groupStatus}`;
				await insertAdminMessage({
					variables: {
						message
					}
				})
			}

	  } catch (e) {
		  console.log(e.message)
		  errorNotify('Your changes was not saved, Try Again!');
	  } finally {
			setGroupStatus(undefined);
			setGroupVisibility(undefined);
		  setDeactivateSelection(result);
	  }
	}

	return (
		<Wrapper>
			{groupLoading ? (
				<CustomSpinner />
			) : (
				<DetailLayoutView
					image={image}
					group
					breadcrumbTitle='Groups'
					breadcrumbItem={group?.name}
					breadcrumbRoute={isAdmin ? '/groups' : '/group-owner'}
				>
					<NewDetailView
					    onSelectChange={({value}) => {
							if (value === 'Live' || value === 'Deleted') {
								setGroupStatus(value);
							} else {
								setGroupVisibility(value);
							}
						}}
						onActionButtonClick={SaveChanges}
						deactivateSelection={deactivateSelection}
						key1={{
							label: 'Group Name',
			 				placeholder: 'Group Name',
							name: 'name',
							status: 'edit',
							type: 'text',
							value: group?.name,
						}}
						key2={{
							label: 'Group Owner',
							status: 'create',
							type: 'text',
							value: `${group?.user?.firstName} ${group?.user?.lastName}`,
						}}
						key3={{
							label: 'Group Owner Email',
							status: 'create',
							type: 'text',
							value: group?.user?.email ?? "",
						}}
						key4={{
							label: 'Group Description',
							placeholder: 'Lounge Description',
							name: 'description',
							status: 'edit',
							type: 'description',
							value: group?.description ? group?.description : 'N/A',
						}}
						key5={{
							label: 'Visibility',
							name: 'isActive',
							status: 'edit',
							type: 'select',
							isEditable: true,
							options: GroupVisibilitySelection,
							value: group?.private ? 'Private' : 'Public',
						}}
						key6={{
							label: 'Status',
							name: 'status',
							status: 'edit',
							type: 'select',
							isEditable: true,
							options: GroupStatusSelection,
							value: group?.deleted ? 'Deleted' : 'Live',
						}}
						key7={{
							label: 'Created At',
							type: 'text',
							value: moment(group?.createdAt).fromNow(),
						}}
						key8={{
							label: 'Participants',
							type: 'text',
							value: groupCount ? pluralizeText(groupCount, 'User') : 'N/A',
						}}
						actionButton={{
							disabled: loading ? true : false,
							type: 'button',
							label: `${loading ? 'Saving Changes...': 'Save Changes'}`
						}}
					/>
					
				</DetailLayoutView>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: calc(100vh - 10vh);
	margin-top: 10vh;
	overflow-y: auto;
`;

export default GroupDetail;
