import React, { useState, useRef } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { Config } from '../../config';

import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo.svg';

const ForgetPasswordPage = () => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const formRef = useRef();

	const handleValidSubmit = async (event, values) => {
		// console.log(values, 'values from form');
		const host = window.location.host;
		setLoading(true);
		await fetch(`${Config.restApiUrl}/auth/forgot-password`, {
			method: 'POST',
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...values,
				host,
			}),
		});
		setLoading(false);
		setSuccess(true);
		formRef.current.reset();
	};

	return (
		<React.Fragment>
			<div className='home-btn d-none d-sm-block'>
				<Link to='/' className='text-dark'>
					<i className='bx bx-home h2'></i>
				</Link>
			</div>
			<div className='account-pages my-5 pt-sm-5'>
				<Container>
					<Row className='justify-content-center'>
						<Col md={8} lg={6} xl={5}>
							<Card className='overflow-hidden'>
								<div className='bg-soft-primary'>
									<Row>
										<Col className='col-7'>
											<div className='text-primary p-4'>
												<h5 className='text-primary'>Welcome Back !</h5>
												<p>Sign in to continue to DYSH.</p>
											</div>
										</Col>
										<Col className='col-5 align-self-end'>
											<img src={profile} alt='' className='img-fluid' />
										</Col>
									</Row>
								</div>
								<CardBody className='pt-0'>
									<div>
										<Link to='/'>
											<div className='avatar-md profile-user-wid mb-4'>
												<span className='avatar-title rounded-circle bg-light'>
													<img
														src={logo}
														alt=''
														className='rounded-circle'
														height='34'
													/>
												</span>
											</div>
										</Link>
									</div>
									<div className='p-2'>
										{success ? (
											<Alert color='success' style={{ marginTop: '13px' }}>
												A password reset link has been sent to your mail if you
												have an account with us
											</Alert>
										) : null}
										<AvForm
											className='form-horizontal mt-4'
											onValidSubmit={handleValidSubmit}
											ref={formRef}
										>
											<div className='form-group'>
												<AvField
													name='email'
													label='Please Enter Your Email Address'
													className='form-control'
													placeholder='Email Address'
													type='email'
													validate={{
														pattern: {
															value:
																"/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
															errorMessage:
																'Please enter a valid email address',
														},
														required: {
															value: true,
															errorMessage: 'Please enter your email address',
														},
													}}
												/>
											</div>
											<Row className='form-group'>
												<Col className='text-right'>
													<button
														className='btn btn-primary w-md waves-effect waves-light'
														type='submit'
														disabled={loading}
													>
														{loading ? 'Please wait...' : 'Submit'}
													</button>
												</Col>
											</Row>
										</AvForm>
									</div>
								</CardBody>
							</Card>
							<div className='mt-5 text-center'>
								<p>
									Go back to{' '}
									<Link to='login' className='font-weight-medium text-primary'>
										Login
									</Link>{' '}
								</p>
								<p>
									© {new Date().getFullYear()} DYSH. Crafted with{' '}
									<i className='mdi mdi-heart text-danger'></i> by Bearingspoint
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(ForgetPasswordPage);
