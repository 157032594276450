import React from "react";
import { Button, Spinner } from "reactstrap";
import { Flex } from "../style";

const DetailActionBtn = ({
  create,
  loading,
  handleCreate,
  handleUpdate,
  setState,
  setEditValues,
  isContent,
  setPublishModal,
  setSaveAsDraftModal,
  isExtName,
  book,
  isDraft,
}) => {
  return (
    <Flex padding="20px 5%">
      {isContent && (isExtName || book?.bookUrl) && (
        <a
          href={book?.bookUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "" }}
          className="text-info btn btn-outline-info"
        >
          Preview
        </a>
      )}
      <div style={{ margin: "0 10px" }}></div>
      {isContent && (
        <Button
          color="warning w-100"
          outline
          className="waves-effect waves-light"
          onClick={() => {
            setSaveAsDraftModal(true);
          }}
        >
          {loading && isDraft && (
            <Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
          )}
          {create ? "Save As Draft" : "Set To Draft"}
        </Button>
      )}
      {isContent && <div style={{ margin: "0 10px" }}></div>}
      <Button
        color="primary w-100"
        outline
        className="waves-effect waves-light"
        onClick={() => {
          if (create) {
            if (isContent) {
              return setPublishModal(true);
            }
            handleCreate();
          } else {
            if (isContent) {
              return setPublishModal(true);
            }
            handleUpdate();
          }
        }}
      >
        {loading && !isDraft && (
          <Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
        )}
        {create
          ? isContent
            ? "Publish"
            : "Create"
          : isContent
          ? "Publish"
          : "Update"}
      </Button>
    </Flex>
  );
};

export default DetailActionBtn;
