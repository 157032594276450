import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useQuery } from "react-apollo";
import moment from "moment";

import { LatestTransactionQuery } from "graphql/query/latestTransactionQuery";
import { formatPrice } from "utils/formatPrice";
import NewTableList from "components/TableList/NewTable";
import ImageUi from "components/Common/ImageUi";
import Breadcrumbs from "components/Common/Breadcrumb";

const limit = 12;

const TransactionAll = () => {
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");

  const { data: latestTransactionData, loading: latestTransactionLoading } =
    useQuery(LatestTransactionQuery, {
      variables: {
        where: {
          _or: [
            { book: { title: { _ilike: `%${query}%` } } },
            { user: { username: { _ilike: `%${query}%` } } },
            { user: { email: { _ilike: `%${query}%` } } },
          ],
        },
        limit,
        offset,
      },
    });

  const transactions = latestTransactionData?.userPurchase;

  const transactionAggregate =
    latestTransactionData?.userPurchase_aggregate?.aggregate?.count;

  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "Content Title" },
    { id: 3, title: "Author" },
    { id: 4, title: "Bought By" },
    { id: 5, title: "Status" },
    { id: 6, title: "Price" },
    { id: 7, title: "Date" },
    { id: 8, title: "Receipt" },
  ];

  const data = _.map(transactions, (transaction) => ({
    id: transaction?.id,
    image: (
      <ImageUi
        imageUrl={transaction?.book?.imageUrl}
        text={transaction?.book?.title}
      />
    ),
    title: transaction?.book?.title,
    author: transaction?.book?.author,
    boughtBy: transaction?.user?.username,
    badge: (
      <span>
        {transaction?.success ? (
          <i className="bx bx-check-double bx-sm text-success"></i>
        ) : (
          <i className="bx bx-x bx-sm text-danger"></i>
        )}
      </span>
    ),
    price: formatPrice(transaction?.book?.priceValue),
    date: moment(transaction?.createdAt).fromNow(),
    receipt: (
      <a
        href={transaction?.receiptUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Receipt
      </a>
    ),
  }));

  const handleQuery = ({ target: { value } }) => {
    setQuery(value);
  };

  return (
    <Wrapper>
      {/* <TableSearchAndCreate
        onChange={handleQuery}
        placeholder="Search reviews by content name, username of buyer or email of buyer..."
        btnLabel=""
        route=""
      /> */}
      <Breadcrumbs title="Transactions" breadcrumbItem="All Transactions" />
      <NewTableList
        onChange={handleQuery}
        placeholder="Search reviews by content name, username of buyer or email of buyer..."
        btnLabel=""
        route=""
        data={data}
        columns={columns}
        // title="All Transactions"
        totalCount={transactionAggregate}
        limit={limit}
        setOffset={setOffset}
        paginate
        loading={latestTransactionLoading}
        search
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 50px;
  padding-top: 15vh;
  overflow-y: auto;
`;

export default TransactionAll;
