import React from "react";
import _ from "lodash";
import moment from "moment";
import ImageUi from "components/Common/ImageUi";

export const loungesWithMostUsers = (lounges) => {
  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "name" },
    { id: 3, title: "User Count" },
    { id: 4, title: "Last Joined" },
  ];

  const data = _.map(lounges, (lounge) => ({
    id: lounge?.id,
    image: <ImageUi imageUrl={lounge?.avatarUrl} text={lounge?.name} />,
    name: lounge?.name,
    count: lounge?.count,
    date: moment(lounge?.updatedAt).fromNow(),
  }));

  return {
    columns,
    data,
  };
};

export const LoungePortalLoungeUser = (lounges) => {
  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "name" },
    { id: 3, title: "Last Joined" },
  ];

  const data = _.map(lounges, (lounge) => ({
    id: lounge?.user?.id,
    image: (
      <ImageUi
        imageUrl={lounge?.user?.avatarUrl}
        text={lounge?.user?.username}
      />
    ),
    name: lounge?.user?.username,
    date: moment(lounge?.user?.createdAt).fromNow(),
  })).slice(0, 3);

  return {
    columns,
    data,
  };
};
