import gql from 'graphql-tag'

export const RemoveBookMutation = gql`
  mutation deleteBook($bookId: uuid!) {
    delete_book(where: {id: {_eq: $bookId}}) {
      returning {
        id
        title
      }
    }
  }
`