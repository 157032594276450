import gql from "graphql-tag";

export const QueryContentDrafts = gql`
  query contentDraftsQuery($where: contentDrafts_bool_exp) {
    contentDrafts(where: $where, order_by: { createdAt: desc }) {
      id
      title
      description
      author
      imageUrl
      bookUrl
      priceValue
      priceCurrency
      isPublished
      createdAt
      genre {
        id
        title
      }
      lounge {
        id
        name
        description
        createdAt
      }
    }
  }
`;

export const ContentDraftsAggregate = gql`
  query contentDraftsAggregate {
    contentDrafts_aggregate {
      aggregate {
        count
      }
    }
  }
`;
