import gql from 'graphql-tag';

export const GetLoungeOwnerAdmins = gql`
	query GetLoungeOwnerAdmins($adminAccountId: uuid) {
		admin(where: { adminAccountId: { _eq: $adminAccountId } }) {
			id
			emailConfirmed
			suspended
			createdAt
			updatedAt
			adminAccount {
				id
				entityName
			}
			user {
				id
				username
				email
				firstName
				lastName
				avatarUrl
			}
			role {
				id
				name
			}
		}
	}
`;

export const GetInvitedUsers = gql`
	query GetInvitedUsers($ownerId: uuid, $adminAccountId: uuid) {
		loungeTeamMembersApplications(
			where: {
				adminAccountId: { _eq: $adminAccountId }
				loungeOwnerId: { _eq: $ownerId }
				approvedAt: { _is_null: true }
			}
		) {
			id
			invitedUserId
			loungeOwnerId
			user {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			owner {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			approvedAt
			declinedAt
			createdAt
		}
	}
`;

export const GetInvitedLoungeOwner = gql`
	query getInviteUsers($ownerId: uuid, $adminAccountId: uuid) {
		loungeOwnershipInviteApplications(
			where: {
				adminAccountId: { _eq: $adminAccountId }
				loungeOwnerId: { _eq: $ownerId }
				approvedAt: { _is_null: true }
			}
		) {
			id
			invitedUserId
			loungeOwnerId
			invitedUser {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			owner {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			approvedAt
			declinedAt
			createdAt
		}
	}
`;
