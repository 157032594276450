import React, { useEffect, useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Config } from '../../config';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { errorNotify, successNotify } from 'utils/toaster';
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo.svg';

function ResetPassword() {
	const [token, setToken] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const params = new URLSearchParams(window.location.search);
	const urlToken = params.get('token');
	const history = useHistory();

	useEffect(() => {
		if (!urlToken) window.location.href = '/login';
	}, []);

	useEffect(() => {
		setToken(urlToken);
	}, [token]);

	async function handleSubmit(events, values) {
		const { password1, password2 } = values;
		if (password1 !== password2) {
			setError("Your password doesn't match");
		} else {
			try {
				setLoading(true);
				const res = await fetch(`${Config.restApiUrl}/auth/reset-password`, {
					method: 'POST',
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						password: password1,
						token,
					}),
				});
				setLoading(false);
				if (res.status > 299) {
					errorNotify('Unable to reset password');
				} else {
					successNotify('You have successfully changed your password');
				}
				history.replace('/login');
			} catch (e) {
				console.log(e.message);
			}
		}
	}

	return (
		<React.Fragment>
			<div className='home-btn d-none d-sm-block'>
				<Link to='/' className='text-dark'>
					<i className='bx bx-home h2'></i>
				</Link>
			</div>
			<div className='account-pages my-5 pt-sm-5'>
				<Container>
					<Row className='justify-content-center'>
						<Col md={8} lg={6} xl={5}>
							<Card className='overflow-hidden'>
								<div className='bg-soft-primary'>
									<Row>
										<Col className='col-7'>
											<div className='text-primary p-4'>
												<h5 className='text-primary'>Welcome Back !</h5>
												<p>Reset Password to continue with DYSH.</p>
											</div>
										</Col>
										<Col className='col-5 align-self-end'>
											<img src={profile} alt='' className='img-fluid' />
										</Col>
									</Row>
								</div>
								<CardBody className='pt-0'>
									<div>
										<Link to='/'>
											<div className='avatar-md profile-user-wid mb-4'>
												<span className='avatar-title rounded-circle bg-light'>
													<img
														src={logo}
														alt=''
														className='rounded-circle'
														height='34'
													/>
												</span>
											</div>
										</Link>
									</div>

									<div className='p-2'>
										{!!error ? (
											<Alert color='danger' style={{ marginTop: '13px' }}>
												{error}
											</Alert>
										) : null}

										<AvForm
											className='form-horizontal mt-4'
											onValidSubmit={handleSubmit}
										>
											<div className='form-group'>
												<AvField
													name='password1'
													label='New Password'
													className='form-control'
													placeholder='New Password'
													type='password'
													validate={{
														required: {
															value: true,
															errorMessage: 'Please enter new password',
														},
														minLength: {
															value: 6,
															errorMessage:
																'Your password must be between 6 and 16 characters',
														},
														maxLength: {
															value: 16,
															errorMessage:
																'Your password must be between 6 and 16 characters',
														},
													}}
												/>
												<AvField
													name='password2'
													label='New Password Confirm'
													className='form-control'
													placeholder='New Password Confirm'
													type='password'
													validate={{
														required: {
															value: true,
															errorMessage: 'Please re-enter new password',
														},
														minLength: {
															value: 6,
															errorMessage:
																'Your password must be between 6 and 16 characters',
														},
														maxLength: {
															value: 16,
															errorMessage:
																'Your password must be between 6 and 16 characters',
														},
													}}
												/>
											</div>
											<Row className='form-group'>
												<Col className='text-right'>
													<button
														className='btn btn-primary w-md waves-effect waves-light'
														disabled={loading}
														type='submit'
													>
														{loading ? 'Please Wait...' : 'Reset Password'}
													</button>
												</Col>
											</Row>
										</AvForm>
									</div>
								</CardBody>
							</Card>
							<div className='mt-5 text-center'>
								<p>
									Go back to{' '}
									<Link to='login' className='font-weight-medium text-primary'>
										Login
									</Link>{' '}
								</p>
								<p>
									© {new Date().getFullYear()} DYSH. Crafted with{' '}
									<i className='mdi mdi-heart text-danger'></i> by Bearingspoint
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
}

export default ResetPassword;
