import gql from 'graphql-tag'

export const AddLoungeMutation = gql`
  mutation addLounge($objects: [lounge_insert_input!]!) {
    insert_lounge(objects: $objects) {
      returning {
        id
        name
      }
    }
  }
`