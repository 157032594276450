/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// Import Routes
import {
	adminAuthProtectedRoutes,
	loungeAuthProtectedRoutes,
	publicRoutes,
} from './routes/';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import fackbackend Configuration file
import fakeBackend from './helpers/AuthType/fakeBackend';
import { UserContext } from './context/UserContext';

// Import scss
import './assets/scss/theme.scss';
import 'antd/dist/antd.css';

import 'react-toastify/dist/ReactToastify.css';

// Activating fake backend
fakeBackend();

const App = (props) => {
	const { getUserDetailsFromLocalStorage, findRole, isAdmin } =
		useContext(UserContext);

	useEffect(() => {
		getUserDetailsFromLocalStorage();
		findRole();
	}, []);

	const getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case 'horizontal':
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	const Layout = getLayout();

	return (
		<React.Fragment>
			<Router>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{!isAdmin &&
						loungeAuthProtectedRoutes.map((route, idx) => (
							<AppRoute
								exact
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}

					{isAdmin &&
						adminAuthProtectedRoutes.map((route, idx) => (
							<AppRoute
								exact
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
				</Switch>
				<ToastContainer />
			</Router>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps, null)(App);
