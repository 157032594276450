import { useMutation, useQuery } from '@apollo/react-hooks';
import { DetailLayoutView } from 'components/DetailView';
import React, { useEffect, useState } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Col,
	Row, 
} from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { errorNotify, successNotify } from 'utils/toaster';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { useHistory, useParams } from 'react-router-dom';
import { GetVideoQuery, UpdateVideoMutation } from 'graphql/query/video';

const UpdateVideo = React.memo(() => {
  const { id } = useParams();
	const [updateVideo, { loading: updatingVideo }] = useMutation(UpdateVideoMutation); 
  const [updateDraft, setUpdateDraft] = useState(false);
  const [publishVideo, setPublishVideo] = useState(false);

	const [publishModal, setPublishModal] = useState(false);
	const [draftModal, setDraftModal] = useState(false);

  const { data: videoData, loading: videoLoading, refetch } = useQuery(GetVideoQuery, {
    variables: {
      videoId: id
    }
  });

	const [values, setValues] = useState({
		description: '',
	});

  const video = videoData?.video[0];

  useEffect(() => {
    setValues({
      description: video?.description,
    });
  }, [videoData]);

	const handleUpdateVideo = async (arg) => {
		const {
			description,
		} = values;

		const isDraft = !!arg;

    if (isDraft) {
      setUpdateDraft(true);
    } else {
      setPublishVideo(true);
    }
    const isPublished = isDraft ? false : true;

		try {
			if ( !_.isEmpty(description)) {
        await updateVideo({
          variables: {
            videoId: video?.id,
            set: {
              isPublished,
              description
            }
          }
        });
        await refetch();
        successNotify(`Video Updated Successfully`);
			}
			
		} catch (error) {
			console.log(error.message)
			const err = error?.toString()?.split(':')[2]?.trim();
			errorNotify(err || 'Something went wrong');
		} finally {
			setPublishModal(false);
			setDraftModal(false);
      setUpdateDraft(false);
      setPublishVideo(false);
		}
	};

	return (
		<Wrapper>
			<Row>
				<Col>
					<DetailLayoutView
						uploadName='file'
						breadcrumbTitle='Playlists'
						breadcrumbItem={video?.description}
						breadcrumbRoute={`/playlists/${video?.playlist?.id}`}
            update
						showVideo={true}
						videoUrlFile={video?.videoUrl}
						hideImage={false}
					>
						<NewDetailView
							key1={{
								label: 'Video Description',
								placeholder: 'Video Description',
								name: 'description',
								status: 'edit',
								type: 'description',
                isEditable: true,
                value: video?.description
							}}
							key2={{
								label: 'Playlist',
								status: 'edit',
								name: 'playlist',
								type: 'select',
								options: [],
                value: video?.playlist?.title
							}}
							action={{
								type: 'action',
								actionText: {
									btn2Text: updateDraft && updatingVideo ? 'Saving To Draft...' : 'Save Changes To Draft',
									btn3Text: publishVideo && updatingVideo ? 'Publishing Your Video...' : 'Publish',
								},
								action: {
									btn2: () => setDraftModal(true),
									btn3: () => setPublishModal(true),
								},
								actionLoading: {
								  btn2Loading: updateDraft && updatingVideo,
								  btn3Loading: publishVideo && updatingVideo,
								},
							}}
							values={values}
							setValues={setValues}
							setDraftModal={() => setDraftModal(true)}
						/>
					</DetailLayoutView>
				</Col>
			</Row>

			{/* publish publishModal */}
			<Modal isOpen={publishModal} centered={true}>
          <ModalBody>
            <div>
              <p>Are you sure you want to publish this video?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={() => setPublishModal(false)}>
              No
            </Button>{' '}
            <Button
              color='primary'
              onClick={() => {
                handleUpdateVideo();
              }}
            >
              Yes
            </Button>
          </ModalFooter>
			</Modal>

			{/* draft publishModal */}
			<Modal isOpen={draftModal} centered={true}>
        <ModalBody>
          <p>
            Are you sure you want to save {values.title?.toUpperCase()} to Draft
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => setDraftModal(false)}>
            No
          </Button>{' '}
          <Button
            color='primary'
            onClick={() => {
              handleUpdateVideo("draft");
            }}
          >
            Yes
          </Button>
        </ModalFooter>
			</Modal>
			<ToastContainer />
		</Wrapper>
	);
});

export const Wrapper = styled.div`
	margin-top: 10vh;
`;

export const UploadProgressWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 14px; 
		margin-top: 15px;
	}
`;

export default UpdateVideo;
