import { Colors } from 'assets/colors/Colors';
import React from 'react';
import { useHistory } from 'react-router';
import { Col, Row, Spinner } from 'reactstrap';
import styled from 'styled-components';
import ContentItem from './Items/ContentItem';
import LoungeItem from './Items/LoungeItem';

// const lounges = [
//   {
//     id: 1,
//     name: "Faith",
//     description:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, assumenda.",
//     user: 16,
//     books: 43,
//     groups: 23,
//   },
//   {
//     id: 1,
//     name: "Wellbeing",
//     description:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, assumenda.",
//     user: 26,
//     books: 23,
//     groups: 13,
//   },
// ];

const CardView = ({
	createLabel,
	btnLabel,
	createRoute,
	data,
	routeName,
	lounge,
	loading,
}) => {
	const history = useHistory();

	return (
		<Wrapper>
			{loading ? (
				<div
					style={{
						height: '50vh',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner />
				</div>
			) : (
				<Row>
					<Col>
						<div className='row'>
							<div
								className='col-sm-12 col-md-6 col-lg-3'
								onClick={() => history.push(createRoute)}
							>
								<div className='card' style={{ height: 284 }}>
									<div className='card-body p-0 m-0'>
										<div className='border-dash' style={{ height: 284 }}>
											<i className='bx bx-plus-medical plus mb-3'></i>
											<p className='create'>{createLabel}</p>
										</div>
									</div>
								</div>
							</div>
							{data?.map((item) => (
								<>
									{lounge ? (
										<LoungeItem item={item} routeName={routeName} />
									) : (
										<ContentItem item={item} routeName={routeName} />
									)}
								</>
							))}
						</div>
					</Col>
				</Row>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	.border-dash {
		display: block;
		width: 100%;
		height: 250px;
		border-radius: 8px;
		border: 5px dashed #aaaaaa;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.plus {
			font-size: 35px;
			color: #aaaaaa;
		}

		.create {
			font-size: 30;
			color: #aaaaaa;
			font-weight: bolder;
		}
	}

	.input-upload {
		display: none;
	}

	.content {
		min-height: 250px;
		border: 1px solid #eeeeee;

		.content-body {
			cursor: pointer;
		}

		.content-title {
			font-weight: 500;
			margin-bottom: 5px;
		}

		.content-author {
			color: ${Colors.gray500};
			font-weight: 500;
			margin-bottom: 5px;
		}

		.content-price {
			color: ${Colors.dyshPurple};
			font-weight: 500;
		}

		.menu {
			cursor: pointer;
		}

		p {
			margin: 0;
		}

		.content-footer {
			border-top: 2px solid #aaaaaa;
			padding: 15px 0;
		}
	}
`;

export default CardView;
