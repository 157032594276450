import gql from "graphql-tag";

export const AddToContentDraftMutation = gql`
  mutation contentDrafts($objects: [contentDrafts_insert_input!]!) {
    insert_contentDrafts(objects: $objects) {
      returning {
        id
        title
        author
      }
    }
  }
`;
