import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { UsersQuery } from "graphql/users";
import styled from "styled-components";
import ErrorHandler from "utils/CatchError";
import Pages500 from "pages/Utility/pages-500";
import { GetRoles } from "graphql/query/getRoles";
import { errorNotify, successNotify } from "utils/toaster";
import { Config, Roles } from "../../config";
import { Colors } from "assets/colors/Colors";
import { AdminAccountQuery } from "graphql/query/adminAccount";
import { UserContext } from "context/UserContext";

export const isErrorHelperFunc = (error) => {
  const key = Object.keys(error)[0];
  if (key === "error") {
    return true;
  }
  return false;
};

const InviteUserForm = ({ setIsOpen, setIsAdminAcountOpen }) => {
  const { isAdmin, adminAccountName } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    userId: "",
    adminAccountId: "",
    roleId: "",
    username: "",
  });

  const [queryUsername, setQueryUsername] = useState("");

  const { data, userError } = useQuery(UsersQuery, {
    variables: {
      search: {
        _or: [{ username: { _ilike: `%${queryUsername}%` } }],
      },
      limit: 5,
    },
  });

  const { data: rolesData } = useQuery(GetRoles);

  const { data: adminAccountData } = useQuery(AdminAccountQuery, {
    variables: {
      search: {
        shortName: { _eq: adminAccountName },
      },
    },
  });

  const users = data?.user;
  let roles = rolesData?.roles;
  let adminAccounts = adminAccountData?.adminAccount;

  roles = _.map(roles, (role) => ({
    label: role?.name?.toUpperCase(),
    value: role?.id,
  }))?.slice(1);

  const loungeOwnerRole = _.filter(
    roles,
    (role) => role?.label === Roles.TEAMMEMBER
  );

  adminAccounts = _.map(adminAccounts, (admin) => ({
    label: admin?.shortName?.toUpperCase(),
    value: admin?.id,
  }));

  const error = ErrorHandler.getUserFriendlyError(userError?.toString());

  const inviteUser = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${Config.restApiUrl}/auth/admin/invite`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      setLoading(false);

      const response = await res.json();

      const isError = isErrorHelperFunc(response);

      if (isError) {
        return errorNotify(response?.error);
      }

      setValues({
        userId: "",
        roleId: "",
        username: "",
        adminAccountId: "",
      });
      successNotify("successfully invite a user to be an admin");
      setIsOpen(false);
    } catch (error) {
      errorNotify(error?.response?.data?.message);
    }
  };

  if (userError?.toString()) {
    return <Pages500 error={error} />;
  }

  return (
    <div>
      <Form>
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">DYSH Username</Label>
          <Col md={8}>
            <Div>
              <Input
                type="search"
                value={values.username}
                onChange={({ target: { value } }) => {
                  setQueryUsername(value);
                  setValues({
                    ...values,
                    username: value,
                  });
                }}
                placeholder="Select a user..."
              />
            </Div>
            {queryUsername && (
              <CardStyle>
                {_.map(users, ({ username, id }) => (
                  <CardBodyStyle
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setValues({
                        ...values,
                        userId: id,
                        username,
                      });
                      setQueryUsername("");
                    }}
                  >
                    {username}
                  </CardBodyStyle>
                ))}
              </CardStyle>
            )}
          </Col>
        </FormGroup>
        {isAdmin && (
          <FormGroup className="mb-4 row">
            <Label className="col-form-label col-md-4">DYSH Account</Label>
            <Col md={8}>
              <Select
                options={adminAccounts}
                onChange={(value) =>
                  setValues({
                    ...values,
                    adminAccountId: value?.value,
                  })
                }
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">Roles</Label>
          <Col md={8}>
            <Select
              options={isAdmin ? roles : loungeOwnerRole}
              disabled={!isAdmin}
              onChange={(value) =>
                setValues({
                  ...values,
                  roleId: value?.value,
                })
              }
            />
          </Col>
        </FormGroup>
        <ButtonGroup>
          <Button className="mr-4" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          {isAdmin && (
            <Button onClick={() => setIsAdminAcountOpen(true)} className="mr-4">
              Create New Account
            </Button>
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Button
              color="primary"
              disabled={!values.username}
              onClick={inviteUser}
            >
              Invite
            </Button>
          )}
        </ButtonGroup>
      </Form>
    </div>
  );
};

const Div = styled.div`
  position: relative;
`;

const CardStyle = styled(Card)`
  position: absolute;
  top: 40px;
  width: 90%;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
`;

const CardBodyStyle = styled(CardBody)`
  padding: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${Colors.dyshPurple};
    color: white;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

export default InviteUserForm;
