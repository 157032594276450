import gql from "graphql-tag";

export const AdminUserQuery = gql`
  query adminUserQuery($adminId: uuid) {
    admin(where: { id: { _eq: $adminId } }) {
      id
      suspended
      emailConfirmed
      onBoarded
      role {
        id
        name
      }
      user {
        id
        username
        firstName
        lastName
        email
        avatarUrl
      }
    }
  }
`;
