import gql from 'graphql-tag'

export const GetALoungeQuery = gql`
  query getALounge($loungeId: uuid!) {
    lounge(where: {id: {_eq: $loungeId}}) {
      id
      name
      description
      message
      ageRestriction
      imageUrl
      isActive
      isPrivate
      approvedAt
      createdAt
      admin {
        user {
          id
          username
          firstName
          lastName
        }
      }
      plans{
        plan{
          id
          name
          books{
            book{
              id
              title
              description
              author
              bookUrl
              createdAt
              imageUrl
            }
          }
        }
      }
      loungeUsers {
        id
        user {
          id
          username
          firstName
          lastName
          avatarUrl
        }
      }
      plans_aggregate {
        aggregate {
          count
        }
      }
      user {
        username
        plans_aggregate {
          aggregate {
            count
          }
        }
      }
      loungeUsers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`