import React, { useContext, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { UserContext } from 'context/UserContext';
import LoungeOwnershipInvite from 'components/InviteUserForm/loungeOwnershipInvite';

const InviteNewLoungeOwnerButton = ({ label = 'Invite Admin' }) => {
	const { isAdmin } = useContext(UserContext);
	const [isOpen, setIsOpen] = useState(false);

	if (isAdmin) return null;

	return (
		<div>
			<div
				className='mr-3 text-white d-flex align-self-center'
				style={{ height: '100%' }}
			>
				<span
					className='mr-3 text-white d-flex align-items-center'
					style={{ cursor: 'pointer' }}
					onClick={() => setIsOpen(true)}
				>
					<i className='bx bx-user font-size-17 align-middle mr-1'></i>
					{label}
				</span>
			</div>

			<Modal isOpen={isOpen}>
				<ModalBody style={{ position: 'relative' }}>
					<h3 className='text-center mb-5'>Lounge Ownership Transfer</h3>
					<span
						onClick={() => setIsOpen(!isOpen)}
						style={{
							fontSize: 25,
							position: 'absolute',
							top: 10,
							right: 15,
							cursor: 'pointer',
						}}
					>
						&times;
					</span>
					<LoungeOwnershipInvite setIsOpen={setIsOpen} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default InviteNewLoungeOwnerButton;
