import gql from 'graphql-tag';

export const UpdateChatObjectionableContentMutation = gql`
	mutation updateObjectionableContent(
		$chatId: uuid!
		$set: objectionableContent_set_input
	) {
		update_objectionableContent(
			where: { chatId: { _eq: $chatId } }
			_set: $set
		) {
			returning {
				id
				reviewedAsObjectionable
				reviewedByAdminId
			}
		}
	}
`;

export const UpdateObjectionableContentMutation = gql`
	mutation updateObjectionableContent(
		$reviewId: uuid!
		$set: objectionableContent_set_input
	) {
		update_objectionableContent(
			where: { reviewId: { _eq: $reviewId } }
			_set: $set
		) {
			returning {
				id
				reviewedAsObjectionable
				reviewedByAdminId
			}
		}
	}
`;

export const UpdateReviewReplyObjectionableContentMutation = gql`
	mutation updateObjectionableContent(
		$reviewReplyId: uuid!
		$set: objectionableContent_set_input
	) {
		update_objectionableContent(
			where: { reviewReplyId: { _eq: $reviewReplyId } }
			_set: $set
		) {
			returning {
				id
				reviewedAsObjectionable
				reviewedByAdminId
			}
		}
	}
`;
