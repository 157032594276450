import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import moment from "moment";
import {
  Badge,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import { AddLoungeMutation } from "graphql/insert/insert_lounge";
import { GetAllLoungeOwnerApplication } from "graphql/query/loungeOwnerApplication";
import { textTruncate } from "utils/textTruncate";
import ImageUi from "components/Common/ImageUi";
import { Colors } from "assets/colors/Colors";
import CustomTable from "components/TableList/CustomTable";
import { defaultImage } from "constant/defaultImage";
import { errorNotify, successNotify } from "utils/toaster";
import { FlexDiv } from "components/DetailView/style";
import { UpdateLoungeOwnerApplicationMutation } from "graphql/update/update_lounge_owner_application";
import { CreateAdminAccountMutation } from "graphql/insert/insert_admin_account";
import { DeleteAdminAccountByNameMutation } from "graphql/delete/delete_admin_account";
import { Config, Roles } from "config";
import { isErrorHelperFunc } from "components/InviteUserForm";
import { GetRoles } from "graphql/query/getRoles";

const Applications = () => {
  const history = useHistory();

  const [current, setCurrent] = useState(1);

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [applicationState, setApplicationState] = useState("");

  const { data: rolesData } = useQuery(GetRoles, {
    variables: {
      where: { name: { _eq: Roles.ADMIN } },
    },
  });

  const { data: loungeOwnerApplicationData, refetch } = useQuery(
    GetAllLoungeOwnerApplication
  );

  const [addLounge, { loading: addLoungeLoading }] =
    useMutation(AddLoungeMutation);

  const [createAdminAccount] = useMutation(CreateAdminAccountMutation);

  const [deleteAdminAccount] = useMutation(DeleteAdminAccountByNameMutation);

  const [updateLoungeOwnerApplication] = useMutation(
    UpdateLoungeOwnerApplicationMutation
  );

  const revertApproveLounge = async (applicationId) => {
    try {
      await updateLoungeOwnerApplication({
        refetchQueries: [
          {
            query: GetAllLoungeOwnerApplication,
          },
        ],
        variables: {
          where: {
            id: { _eq: applicationId },
          },
          set: {
            acceptedAt: null,
          },
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const createAdminFunc = async (application) => {
    try {
      const adminAccount = await createAdminAccount({
        variables: {
          objects: {
            shortName: application?.entityName
              ? application?.entityName
              : application?.user?.username,
            entityName: application?.entityName
              ? application?.entityName
              : application?.user?.username,
            type: "LOUNGEOWNER",
          },
        },
      });

      const adminAccountId =
        adminAccount?.data?.insert_adminAccount?.returning[0]?.id;
      const adminAccountName =
        adminAccount?.data?.insert_adminAccount?.returning[0]?.entityName;
      const roleId = rolesData?.roles[0]?.id;

      await addLounge({
        variables: {
          objects: {
            name: application?.offeredLoungeName,
            description: application?.description,
            imageUrl: defaultImage ?? null,
            isActive: false,
            isPrivate: false,
            userId: application?.requestingUserId,
            ownedByAccountId: adminAccountId,
          },
        },
      });

      const values = {
        userId: application?.requestingUserId,
        adminAccountId,
        roleId,
        isAccepted: true,
      };

      if (adminAccountId && roleId) {
        setLoading(true);

        const res = await fetch(`${Config.restApiUrl}/auth/admin/invite`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        setLoading(false);

        const response = await res.json();

        const isError = isErrorHelperFunc(response);

        if (isError) {
          await deleteAdminAccount({
            variables: {
              adminAccountName,
            },
          });
          throw new Error(response?.error);
        }
      } else {
        await deleteAdminAccount({
          variables: {
            adminAccountName,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const approveLounge = async (application) => {
    const applicationId = application?.id;

    try {
      await createAdminFunc(application);

      await updateLoungeOwnerApplication({
        refetchQueries: [
          {
            query: GetAllLoungeOwnerApplication,
          },
        ],
        variables: {
          where: {
            id: { _eq: application?.id },
          },
          set: {
            acceptedAt: new Date().toISOString(),
          },
        },
      });

      setAcceptModal(false);
      successNotify("Lounge Approved");
    } catch (error) {
      await revertApproveLounge(applicationId);
      errorNotify(error.toString());
    }
  };

  const rejectLounge = async (application) => {
    try {
      setRejectLoading(true);
      const res = await fetch(
        `${Config.restApiUrl}/auth/admin/reject-lounge-owner-application`,
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...application,
            reason: reason ? reason : null,
            applicationId: application?.id,
            email: application?.user?.email,
            firstname: application?.user?.firstName,
            isRejected: true,
          }),
        }
      );

      const response = await res.json();

      setRejectLoading(false);

      if (refetch) {
        refetch();
      }

      const isError = isErrorHelperFunc(response);

      if (isError) {
        return errorNotify(response?.error);
      }

      setRejectModal(false);
      successNotify("Lounge Rejected");
    } catch (error) {
      setRejectLoading(false);
      errorNotify(error.toString());
    }
  };

  useEffect(() => {
    if (refetch) {
      refetch();
    }

    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "Image",
      selector: "image",
      sortable: true,
    },
    {
      name: "User",
      selector: "user",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Lounge Name",
      selector: "loungeName",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
    },
    {
      name: "Entity Name",
      selector: "entityName",
      sortable: true,
    },
    {
      name: "Date Created",
      selector: "date",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: true,
    },
  ];

  const count =
    loungeOwnerApplicationData?.loungeOwnerApplications_aggregate?.aggregate
      ?.count;

  const data = _.map(
    loungeOwnerApplicationData?.loungeOwnerApplications,
    (application) => ({
      //   id: application?.id,
      image: (
        <ImageUi
          width={30}
          height={30}
          imageUrl={application?.avatarUrl}
          text={application?.user?.username}
        />
      ),
      user: textTruncate(
        `${application?.user?.firstName} ${application?.user?.lastName}`
      ),
      email: application?.user?.email,
      loungeName: textTruncate(application?.offeredLoungeName),
      description: textTruncate(application?.description),
      entityName: textTruncate(application?.entityName),
      date: moment(application?.createdAt).fromNow(),
      actions: (
        <div>
          <Badge
            color={Colors.green800}
            style={{
              background: Colors.green100,
              color: Colors.green800,
              cursor: "pointer",
              marginRight: 10,
            }}
            onClick={() => {
              setAcceptModal(true);
              setTitle(application?.offeredLoungeName);
              setApplicationState(application);
            }}
          >
            Accept
          </Badge>
          <Badge
            color={Colors.red800}
            style={{
              background: Colors.red100,
              color: Colors.red800,
              cursor: "pointer",
            }}
            onClick={() => {
              setRejectModal(true);
              setTitle(application?.offeredLoungeName);
              setApplicationState(application);
            }}
          >
            Reject
          </Badge>
        </div>
      ),
      id: application?.id,
    })
  );

  return (
    <Wrapper>
      <Breadcrumbs
        title="Applications"
        breadcrumbItem="Lounge Owner Applications"
      />
      <CustomTable
        data={data}
        columns={columns}
        paginate
        current={current}
        setCurrent={setCurrent}
        total={count}
        onClick={(row) => history.push(`/applications/${row?.id}`)}
        style={{ cursor: "pointer" }}
      />

      {/* Accept Modal */}
      <Modal isOpen={acceptModal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to accept {title} lounge request?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setAcceptModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (applicationState) {
                approveLounge(applicationState);
              }
            }}
          >
            {addLoungeLoading | loading ? (
              <Spinner style={{ width: 14, height: 14 }} />
            ) : (
              "Yes"
            )}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Reject Modal */}
      <Modal isOpen={rejectModal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to reject {title} lounge request?</p>
          <FlexDiv direction="column" padding="0px">
            <Label>Reason for rejecting lounge</Label>
            <textarea
              className="form-control"
              id="projectdesc"
              style={{ width: "100%" }}
              rows="3"
              placeholder="Reason for rejecting lounge"
              onChange={({ target: { value } }) => {
                setReason(value);
              }}
            ></textarea>
          </FlexDiv>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setRejectModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (applicationState) {
                rejectLounge(applicationState);
              }
            }}
          >
            {rejectLoading ? (
              <Spinner style={{ width: 14, height: 14 }} />
            ) : (
              "Yes"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: calc(10vh + 3%);
  overflow-y: auto;
`;

export default Applications;
