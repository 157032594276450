/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import ErrorHandler from 'utils/CatchError';
import { UserContext } from 'context/UserContext';
import Pages500 from 'pages/Utility/pages-500';
import { GroupQuery } from 'graphql/query/groups';
import NewTableList from 'components/TableList/NewTable';
import ImageUi from 'components/Common/ImageUi';
import { LoungePortalContext } from 'context/LoungePortalContext';
import NewBreadcrumbs from 'components/Common/NewBreadcrumb';

const limit = 12;

const LoungeOwnerGroups = () => {
	const { getUserDetailsFromLocalStorage, isAdmin } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);

	const history = useHistory();
	const [query, setQuery] = useState('');
	const [, setLoungeQuery] = useState('');
	const [offset, setOffset] = useState(0);

	const {
		data: groupData,
		loading: groupLoadingState,
		error: loungeError,
		refetch,
	} = useQuery(GroupQuery, {
		variables: {
			search: {
				_and: [
					{
						_or: [
							{ name: { _ilike: `%${query}%` } },
							{ description: { _ilike: `%${query}%` } },
						],
					},
					loungeId && !isAdmin
						? { lounges: { lounge: { id: { _eq: loungeId } } } }
						: {},
				],
			},
			limit: limit,
			offset: offset,
		},
	});

	// const {
	//   data: groupData,
	//   loading: groupLoadingState,
	//   error: loungeError,
	//   refetch,
	// } = useQuery(GroupQuery, {
	//   variables: {
	//     search: {
	//       _and: [
	//         {
	//           _or: [
	//             { name: { _ilike: `%${query}%` } },
	//             { description: { _ilike: `%${query}%` } },
	//           ],
	//         },
	//         loungeQuery
	//           ? { lounges: { lounge: { name: { _eq: loungeQuery } } } }
	//           : {},
	//         {
	//           _or: [
	//             {
	//               lounges: {
	//                 lounge: { createdByAdminId: { _eq: user?.adminId } },
	//               },
	//             },
	//             {
	//               lounges: {
	//                 lounge: { updatedByAdminId: { _eq: user?.adminId } },
	//               },
	//             },
	//           ],
	//         },
	//       ],
	//     },
	//     limit: limit,
	//     offset: offset,
	//   },
	// });

	useEffect(() => {
		if (refetch) {
			refetch();
		}
		getUserDetailsFromLocalStorage();
	}, [refetch]);

	const groups = groupData?.plan;
	const groupAggregate = groupData?.plan_aggregate?.aggregate?.count;

	const handleQuery = ({ target: { value } }) => {
		setQuery(value);
	};

	const error = ErrorHandler.getUserFriendlyError(loungeError?.toString());

	if (loungeError?.toString()) {
		return <Pages500 error={error} />;
	}

	const columns = [
		{ id: 1, title: 'Image' },
		{ id: 2, title: 'Group Name' },
		{ id: 4, title: 'Group Owner' },
		{ id: 7, title: 'No. of Participants' },
		{ id: 5, title: 'Group Visibility' },
		{ id: 6, title: 'Last Message Date' },
	];

	const data = _.map(groups, (group) => ({
		id: group?.id,
		image: (
			<ImageUi imageUrl={group?.books[0]?.book?.imageUrl} text={group?.name} />
		),
		name: group?.name,
		owner: group?.user?.username,
		participants: group?.users_aggregate?.aggregate?.count + 1,
		visible: group?.private ? 'Private' : 'Public',
		lastMessageDate: moment(group?.chatMessages[0]?.createdAt).fromNow(),
	}));

	return (
		<Wrapper>
			<NewBreadcrumbs
				title='Group'
				breadcrumbItem={`Groups (${groupAggregate ?? 0})`}
				subBreadcrumbItem='Groups'
			/>
			<NewTableList
				onChange={handleQuery}
				placeholder='Search groups by name or description...'
				btnLabel='Create Group'
				route='/create-group'
				isFilter={false}
				setQuery={setLoungeQuery}
				data={data}
				columns={columns}
				// title="Groups"
				totalCount={groupAggregate}
				limit={limit}
				paginate
				setOffset={setOffset}
				onRow={(record) => history.push(`/groups/${record?.id}`)}
				loading={groupLoadingState}
				trStyle={{
					cursor: 'pointer',
				}}
				search
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 0 5%;
	padding-top: calc(10vh + 3%);
	overflow-y: auto;
`;

export default LoungeOwnerGroups;
