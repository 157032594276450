import React, { useContext, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import styled from 'styled-components';

import { UsersQuery } from 'graphql/users';
import ErrorHandler from 'utils/CatchError';
import Pages500 from 'pages/Utility/pages-500';
import { GetRoles } from 'graphql/query/getRoles';
import { errorNotify, successNotify } from 'utils/toaster';
import { Colors } from 'assets/colors/Colors';
import { AdminAccountQuery } from 'graphql/query/adminAccount';
import { UserContext } from 'context/UserContext';
import { GetUserInviteCount } from 'graphql/query/inviteUserCount';
import { Config } from 'config';
import { isErrorHelperFunc } from '.';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { GetInvitedLoungeOwner } from 'graphql/query/queryLoungeOwnerAdmin';

const LoungeOwnershipInvite = ({ setIsOpen, setIsAdminAcountOpen }) => {
	const { isAdmin, adminAccountName, user, adminAccountId } =
		useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);

	const [loading, setLoading] = useState(false);

	const { refetch } = useQuery(GetInvitedLoungeOwner, {
		variables: {
			ownerId: user?.id,
			adminAccountId,
		},
	});

	const [values, setValues] = useState({
		userId: '',
		adminAccountId: '',
		roleId: '',
		username: '',
	});

	const [queryUsername, setQueryUsername] = useState('');

	const { data, userError } = useQuery(UsersQuery, {
		variables: {
			search: {
				_or: [{ username: { _ilike: `%${queryUsername}%` } }],
			},
			limit: 5,
		},
	});

	const { data: rolesData } = useQuery(GetRoles);

	const { data: adminAccountData } = useQuery(AdminAccountQuery, {
		variables: {
			search: {
				shortName: { _eq: adminAccountName },
			},
		},
	});

	const { data: inviteUserCountData } = useQuery(GetUserInviteCount, {
		variables: {
			loungeOwnerId: user?.id,
		},
	});

	const inviteCount =
		inviteUserCountData?.loungeTeamMembersApplications_aggregate?.aggregate
			?.count;

	const users = data?.user;
	let roles = rolesData?.roles;
	let adminAccounts = adminAccountData?.adminAccount;

	roles = _.map(roles, (role) => ({
		label: role?.name?.toUpperCase(),
		value: role?.id,
	}))?.slice(1);

	const RoleOptions = [{ value: 'LOUNGE-OWNER', label: 'LOUNGE-OWNER' }];

	adminAccounts = _.map(adminAccounts, (admin) => ({
		label: admin?.shortName?.toUpperCase(),
		value: admin?.id,
	}));

	const error = ErrorHandler.getUserFriendlyError(userError?.toString());

	const inviteUser = async () => {
		try {
			setLoading(true);
			const res = await fetch(
				`${Config.restApiUrl}/auth/admin/lounge-ownership-invite`,
				{
					method: 'POST',
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						invitedUserId: values.userId,
						loungeOwnerId: user?.id,
						loungeId,
						adminAccountId,
					}),
				}
			);

			await refetch();

			setLoading(false);

			const response = await res.json();

			const isError = isErrorHelperFunc(response);

			if (isError) {
				return errorNotify(response?.error);
			}
			setLoading(false);
			setValues({
				userId: '',
				roleId: '',
				username: '',
				adminAccountId: '',
			});
			successNotify(
				'Invitation to become a lounge owner sent out successfully'
			);
			setIsOpen(false);
		} catch (error) {
			setLoading(false);
			if (error?.toString()?.split(':')[2]?.includes('Uniqueness')) {
				return errorNotify('This user exist as an admin');
			}
			errorNotify('Something went wrong,try again');
		}
	};

	if (userError?.toString()) {
		return <Pages500 error={error} />;
	}

	return (
		<div>
			<Form>
				<FormGroup className='mb-4 row'>
					<Label className='col-form-label col-md-4'>DYSH Username</Label>
					<Col md={8}>
						<Div>
							<Input
								type='search'
								value={values.username}
								onChange={({ target: { value } }) => {
									setQueryUsername(value);
									setValues({
										...values,
										username: value,
									});
								}}
								placeholder='Select a user...'
							/>
						</Div>
						{queryUsername && (
							<CardStyle>
								{_.map(users, ({ username, id }) => (
									<CardBodyStyle
										style={{
											cursor: 'pointer',
										}}
										onClick={() => {
											setValues({
												...values,
												userId: id,
												username,
											});
											setQueryUsername('');
										}}
									>
										{username}
									</CardBodyStyle>
								))}
							</CardStyle>
						)}
					</Col>
				</FormGroup>

				<FormGroup className='mb-4 row'>
					<Label className='col-form-label col-md-4'>Role</Label>
					<Col md={8}>
						<Select
							options={RoleOptions}
							defaultValue={RoleOptions[0]}
							onChange={(value) =>
								setValues({
									...values,
									roleId: value?.value,
								})
							}
						/>
					</Col>
				</FormGroup>
				<p>
					Note: There can be only one lounge owner. The invited person replaces
					you upon acceptance
				</p>
				<ButtonGroup>
					<Button className='mr-4' onClick={() => setIsOpen(false)}>
						Cancel
					</Button>
					{loading ? (
						<Spinner />
					) : (
						<Button
							color='primary'
							disabled={!values.username || inviteCount >= 3}
							onClick={inviteUser}
						>
							Invite New Owner
						</Button>
					)}
				</ButtonGroup>
			</Form>
		</div>
	);
};

const Div = styled.div`
	position: relative;
`;

const CardStyle = styled(Card)`
	position: absolute;
	top: 40px;
	width: 90%;
	border: 1px solid #eeeeee;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	z-index: 2;
`;

const CardBodyStyle = styled(CardBody)`
	padding: 10px;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: ${Colors.dyshPurple};
		color: white;
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: center;
`;

export default LoungeOwnershipInvite;
