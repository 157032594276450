import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Table,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import profile1 from "../../assets/images/profile-img.png";

// import charts
// import ApexRevenue from "../Contacts/ApexRevenue";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { message } from "antd";
import { UpdateProfileImage } from "../../graphql/update/updateProfileImage";
import { Config } from "config";

const Profile = () => {
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const user = JSON.parse(localStorage.getItem("authUser"))?.user;

  const [uploadImageMutation, { loading: uploadMutationLoading }] =
    useMutation(UpdateProfileImage);

  const [file, setFile] = useState();

  // const [createAdminValues, setCreateAdminValues] = useState({
  //   firstName: "",
  //   lastName: "",
  //   username: "",
  //   email: "",
  // });

  const [changePwdloading, setChangePwdLoading] = useState(false);
  // const [, setAdminLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const userStoreInLocalStorage =
    JSON.parse(localStorage.getItem("authUser")) || null;

  const history = useHistory();

  // const adminRole = userStoreInLocalStorage?.user?.role;
  const token = userStoreInLocalStorage?.token;

  const handleInput = ({ target: { value, name } }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  // const handleInputCreateAdmin = ({ target: { value, name } }) => {
  //   setCreateAdminValues({
  //     ...createAdminValues,
  //     [name]: value,
  //   });
  // };

  const handleFileInput = ({ target: { files } }) => {
    setFile(files[0]);
  };

  const uploadImage = async () => {
    const form = new FormData();
    try {
      form.append("image", file);
      setUploadLoading(true);
      const response = await fetch(`${Config.restApiUrl}/image/upload`, {
        method: "POST",
        "Content-type": "multipart/form-data",
        body: form,
      });
      setUploadLoading(false);

      const imageResponse = await response.json();
      const avatarUrl = imageResponse?.location;

      if (avatarUrl && user?.id) {
        await uploadImageMutation({
          variables: {
            userId: user?.id,
            set: {
              avatarUrl,
            },
          },
        });
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const isDisabled =
    Boolean(values.newPassword) &&
    Boolean(values.oldPassword) &&
    Boolean(values.confirmNewPassword);

  const onSubmit = async () => {
    if (
      values.oldPassword &&
      values.newPassword &&
      values.newPassword === values.confirmNewPassword
    ) {
      setChangePwdLoading(true);
      await fetch(`${Config.restApiUrl}/auth/change-admin-password`, {
        method: "POST",
        headers: {
          "Content-type": "Application/json",
        },
        body: JSON.stringify({
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
          token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const key = Object.keys(data);
          if (key[0] === "error") {
            setChangePwdLoading(false);
            message.error(data?.error);
            return;
          }
          message.success("You successfully changed your password");
          setValues({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          setChangePwdLoading(false);
        })
        .catch((err) => message.error(err.toString()));
      setChangePwdLoading(false);
    } else {
      setChangePwdLoading(false);
      message.error(
        "Please enter old password and new password or check if your confirm password match"
      );
    }
  };

  // const createAdmin = async () => {
  //   try {
  //     if (
  //       createAdminValues.firstName &&
  //       createAdminValues.lastName &&
  //       createAdminValues.username &&
  //       createAdminValues.email
  //     ) {
  //       setAdminLoading(true);
  //       const response = await fetch(`${Config.restApiUrl}/auth/create-admin`, {
  //         method: "POST",
  //         headers: {
  //           "Content-type": "Application/json",
  //         },
  //         body: JSON.stringify(createAdminValues),
  //       });

  //       const res = await response.json();
  //       const key = Object.keys(res);
  //       if (key[0] === "error") {
  //         message.error(res?.error);
  //         setAdminLoading(false);
  //         return;
  //       }
  //       message.success("Successfully created an admin");
  //       setCreateAdminValues({
  //         firstName: "",
  //         lastName: "",
  //         username: "",
  //         email: "",
  //       });
  //       setAdminLoading(false);
  //     } else {
  //       setAdminLoading(false);
  //       message.error("All fields are required");
  //     }
  //   } catch (error) {
  //     setAdminLoading(false);
  //     message.error(error.toString());
  //   }
  // };

  return (
    <Wrapper>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Contacts"
            breadcrumbItem={
              <div>
                <i
                  className="bx bx-left-arrow-alt cursor"
                  onClick={() => history.goBack()}
                ></i>{" "}
                <span>Profile</span>
              </div>
            }
          />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="12">
                      <div className="avatar-md profile-user-wid mb-4">
                        {user?.avatarUrl ? (
                          <img
                            src={user?.avatarUrl}
                            className="rounded-circle avatar-md"
                            alt=""
                          />
                        ) : (
                          <div className="avatar-sm">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                              {user?.username?.slice(0, 1)?.toUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {user?.firstName} {user?.lastName}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        Book Author
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>

                  {/* <p className="text-muted mb-4">
                    Hi I'm Cynthia Price,has been the industry's standard dummy
                    text To an English person, it will seem like simplified
                    English, as a skeptical Cambridge.
                  </p> */}
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>
                            {user?.firstName} {user?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{user?.email}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Change Password</CardTitle>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">Old Password</Label>
                    <Input
                      name="oldPassword"
                      value={values.oldPassword}
                      placeholder="Old Password"
                      type="password"
                      errorMessage="Enter Content Title"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">New Password</Label>
                    <Input
                      name="newPassword"
                      value={values.newPassword}
                      placeholder="New Password"
                      type="password"
                      errorMessage="Enter Content Author"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">
                      Confirm New Password
                    </Label>
                    <Input
                      name="confirmNewPassword"
                      value={values.confirmNewPassword}
                      placeholder="Confirm New Password"
                      type="password"
                      errorMessage="Enter Content Author"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  {changePwdloading ? (
                    <Spinner color="primary" />
                  ) : (
                    <Button
                      onClick={onSubmit}
                      disabled={!isDisabled}
                      color="success"
                    >
                      Change Password
                    </Button>
                  )}
                </CardBody>
              </Card> */}
            </Col>

            <Col xl="8">
              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Revenue</CardTitle>
                  <div id="revenue-chart" className="apex-charts">
                    <ApexRevenue />
                  </div>
                </CardBody>
              </Card> */}

              {/* upload profile image */}
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Upload A Profile Image</CardTitle>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">Profile Image</Label>
                    <input
                      // name="username"
                      // value={createAdminValues.username}
                      placeholder="Profile Image"
                      type="file"
                      errorMessage="Please enter last name"
                      className="form-control"
                      onChange={handleFileInput}
                    />
                  </FormGroup>

                  {uploadMutationLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <Button
                      onClick={uploadImage}
                      // disabled={!isDisabled}
                      color="success"
                    >
                      {uploadLoading ? "Uploading..." : "Upload Image"}
                    </Button>
                  )}
                </CardBody>
              </Card>

              {/* change password */}
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Change Password</CardTitle>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">Old Password</Label>
                    <Input
                      name="oldPassword"
                      value={values.oldPassword}
                      placeholder="Old Password"
                      type="password"
                      errorMessage="Enter Content Title"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">New Password</Label>
                    <Input
                      name="newPassword"
                      value={values.newPassword}
                      placeholder="New Password"
                      type="password"
                      errorMessage="Enter Content Author"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="validationCustom01">
                      Confirm New Password
                    </Label>
                    <Input
                      name="confirmNewPassword"
                      value={values.confirmNewPassword}
                      placeholder="Confirm New Password"
                      type="password"
                      errorMessage="Enter Content Author"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </FormGroup>
                  {changePwdloading ? (
                    <Spinner color="primary" />
                  ) : (
                    <Button
                      onClick={onSubmit}
                      disabled={!isDisabled}
                      color="success"
                    >
                      Change Password
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 10vh);
  overflow-y: auto;
`;

export default Profile;
