import gql from "graphql-tag";

export const MostPurchaseBookQuery = gql`
  query mostPurchaseBook {
    userPurchase(order_by: { book: { title: desc } }) {
      id
      book {
        id
        title
      }
    }
  }
`;

export const LoungeOwnerMostPurchaseBookQuery = gql`
  query mostPurchaseBook($adminId: uuid, $adminAccountId: uuid) {
    userPurchase(
      where: {
        book: {
          lounges: {
            lounge: {
              _and: [
                {
                  _or: [
                    { createdByAdminId: { _eq: $adminId } }
                    { updatedByAdminId: { _eq: $adminId } }
                  ]
                }
                { ownedByAccountId: { _eq: $adminAccountId } }
              ]
            }
          }
        }
      }
      order_by: { book: { title: desc } }
    ) {
      id
      book {
        id
        title
      }
    }
  }
`;
