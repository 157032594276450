import React, { memo, useState, useEffect } from 'react';
import { Tabs, List, Typography, Button, Pagination } from 'antd';
import { useMutation, useQuery } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';
import { GET_PORTAL_NOTIFICATION, PORTAL_NOTIFICATION_AGGREGATE } from 'graphql/query/portalNotification';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { MARK_ALL_AS_READ, MARK_AS_READ } from 'graphql/update/update_portal_notification';
import { DELETE_ALL_PORTAL_NOTIFICATION } from 'graphql/delete/delete_portal_notification';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { TabPane } = Tabs;

function callback(key) {}

const Header = ({ hasData }) => {
  const [markAllModal, setMarkAllModal] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [markAllAsRead, { loading: updating }] = useMutation(MARK_ALL_AS_READ);
  const [deletePortalNotification, { loading: deleting }] = useMutation(DELETE_ALL_PORTAL_NOTIFICATION);

  async function handleMarkAll() {
    try {
      setMarkAllModal(false);
      await markAllAsRead({
        refetchQueries: ["GET_PORTAL_NOTIFICATION"]
      });
    } catch(e) {
      console.log(e.message)
    }
  }

  async function handleDeleteAll() {
    try {      
      setDeleteAllModal(false);
      await deletePortalNotification({
        refetchQueries: ["GET_PORTAL_NOTIFICATION"]
      });
    } catch(e) {
      console.log(e.message)
    }
  }

  return (
    hasData && <div>
    <Button 
      onClick={() => setMarkAllModal(true)}
      type="primary" 
      style={{ marginRight: 10, background: '#5039C8', border: 'none' }} 
      shape="round" 
      icon={
       updating ? (<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>)
       : (<i class="fa fa-check-circle" aria-hidden="true"></i>)
      } size={30}>
        &nbsp;&nbsp;Mark all as Read
    </Button>
    <Button 
      onClick={() => setDeleteAllModal(true)}
      type="primary" 
      style={{ background: '#5039C8', border: 'none' }} 
      shape="round" 
      icon={
        deleting ? (<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>)
        : (<i class="fa fa-trash" aria-hidden="true"></i>)
        } size={30}>
        &nbsp;&nbsp;Delete All
    </Button>

    <Modal isOpen={markAllModal} centered={false}>
      <ModalHeader>
        Do you really want to Mark All as Read?
      </ModalHeader>
      <ModalFooter>
        <Button
          color="warning"
          onClick={handleMarkAll}
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={() => setMarkAllModal(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={deleteAllModal} centered={false}>
      <ModalHeader>
        Do you really want to Delete All notification?
      </ModalHeader>
      <ModalFooter>
        <Button
          color="warning"
          onClick={handleDeleteAll}
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={() => setDeleteAllModal(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>

  </div>
)}

const NotificationTab = memo(() => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const offset = (currentPage - 1) * pageSize;
  const { data, refetch } = useQuery(GET_PORTAL_NOTIFICATION, {
    variables: { limit: pageSize, offset },
  });
  const { data: portalNotificationAgg } = useQuery(PORTAL_NOTIFICATION_AGGREGATE);
  const history = useHistory();
  const [markAsRead] = useMutation(MARK_AS_READ);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    refetch({ limit: pageSize, offset });
  }, [currentPage, refetch]);

  return (
    <StyledTabs defaultActiveKey="1" onChange={callback}>
    <StyledTabPane tab={<StyledTab>All Notifications</StyledTab>} key="1">
      <List
        size="large"
        header={<Header hasData={!!data?.portalNotification[0]}/>}
        footer={!!data?.portalNotification[0] && <StyledPagination 
          current={currentPage}
          pageSize={pageSize}
          total={portalNotificationAgg?.portalNotification_aggregate?.aggregate?.count}
          onChange={handlePageChange}
          showLessItems={true}
        />}
        bordered
        style={{
          background: 'white',
        }}
        dataSource={data?.portalNotification}
        renderItem={(item, index) => (
          <StyledListItem key={index} onClick={() => {
            markAsRead({
              variables: {
                id: item?.id
              },
                optimisticResponse: {
                  __typename: "Mutation",
                  update_portalNotification: {
                    __typename: "portalNotification_mutation_response",
                    affected_rows: 1,
                  },
                },
                update: (cache, { data: { update_portalNotification } }) => {
                  const existingNotifications = cache.readQuery({ query: GET_PORTAL_NOTIFICATION });
                  const newNotifications = existingNotifications.portalNotification.map(notification =>
                    notification.id === item?.id ? { ...notification, read: true } : notification
                  );
                  cache.writeQuery({
                    query: GET_PORTAL_NOTIFICATION,
                    data: { portalNotification: newNotifications },
                  });
                },
              }
            );
            history.push(`/reviews`);
          }}>
            <ListItemOuterContainer>
              <ListItemInnerContainer>
                <i className="fa fa-circle" 
                style={{ color: item.read ? '#393939' : '#5039C8', marginRight: 10}} 
                aria-hidden="true"></i>
                <Typography>{item?.message}</Typography>
              </ListItemInnerContainer>
              <Typography style={{ alignSelf: 'flex-start'}}>{moment(item?.createdAt).fromNow()}</Typography>
            </ListItemOuterContainer>
          </StyledListItem>
        )}
      />
    </StyledTabPane>
  </StyledTabs>
  )
});

const Notification = () => {
  return (
    <NotificationWrapper>
      <Breadcrumbs title="Notifications" breadcrumbItem="Notifications" />
      <NotificationTab />
    </NotificationWrapper>
  )
}

const NotificationWrapper = styled.div`
  padding: 8% 5% 5% 2%;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: black;
  }
`;

const StyledTabPane = styled(TabPane)`
  color: black;
`;

const StyledTab = styled.span`
  color: black;
`;

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;

const ListItemOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ListItemInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    border-color: #5039C8;
  }
  .ant-pagination-item a {
    color: #5039C8;
  }
  .ant-pagination-item-active {
    border-color: #5039C8;
    font-weight: bold;
  }
  .ant-pagination-item-active a {
    color: #5039C8;
  }
`;

export default Notification
