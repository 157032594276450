import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { truncateText } from 'helpers/truncateText';

function VideoCardItem({ description, id, imageUrl, title, videos_aggregate: { aggregate: { count }} }) {
  const history = useHistory();
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const handleDetailsClick = () => {
    history.push(`/playlist-details/${id}`);
  };

  const handleManageClick = () => {
    history.push(`/playlists/${id}`);
  };

  const handleMouseEnter = () => {
    setOverlayVisible(true);
  };

  const handleMouseLeave = () => {
    setOverlayVisible(false); 
  };

  return (
    <Col sm="12" md="6" lg="3">
      <CardWrapper onClick={handleMouseEnter} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <VideoThumbnail>
          <ThumbnailImage src={imageUrl} alt="Video thumbnail" />
          {isOverlayVisible && ( 
            <HoverOverlay>
              <ButtonGroup>
                <OverlayButton onClick={handleDetailsClick}>Playlist Info</OverlayButton>
                <OverlayButton onClick={handleManageClick}>Manage Videos</OverlayButton>
              </ButtonGroup>
            </HoverOverlay>
          )}
         <VideoOverlay>{`${count} ${count <= 1 ? 'video' : 'videos'}`}</VideoOverlay>
        </VideoThumbnail>
        <VideoInfo>
          <VideoTitle>{title}</VideoTitle>
          <VideoDescription>{truncateText(description, 100)}</VideoDescription>
          <VideoPrivacy>Public · Playlist</VideoPrivacy>
        </VideoInfo>
      </CardWrapper>
    </Col>
  );
}

const CardWrapper = styled.div`
  height: 284px;
  border: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 30px;

  &:hover {
    transform: scale(1.03);
  }
`;

const VideoThumbnail = styled.div`
  position: relative;
  height: 65%;
  overflow: hidden;

  &:hover img {
    filter: brightness(0.8);
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid #ccc;
  transition: filter 0.3s ease-in-out;
`;

const VideoOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const OverlayButton = styled.button`
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const VideoInfo = styled.div`
  padding: 10px;
`;

const VideoTitle = styled.h3`
  font-size: 14px;
  margin: 0 0 5px;
`;

const VideoDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0 0 5px;
`;

const VideoPrivacy = styled.p`
  font-size: 12px;
  color: gray;
  margin-bottom: 10px;
`;

export default VideoCardItem;
