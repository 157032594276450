import gql from "graphql-tag";

export const InsertPlanMutation = gql`
  mutation InsertPlan($objects: [planLounge_insert_input!]!) {
    insert_planLounge(objects: $objects) {
      returning {
        id
        plan {
          id
          name
          createdAt
        }
      }
    }
  }
`;
