import React, { useContext, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import dyshLogoImage from 'assets/images/dysh/dysh-app-icon.png';

//i18n
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
// import AddDropdown from "components/CommonForBoth/TopbarDropdown/AddDropdown";
// import LoungesDropdown from "components/CommonForBoth/TopbarDropdown/LoungeDropdown";
import { UserContext } from 'context/UserContext';
import { Colors } from 'assets/colors/Colors';
import InviteButton from 'components/Common/InviteButton';
import InviteNewLoungeOwnerButton from 'components/Common/InviteNewLoungeOwnerButton';
import { useQuery } from 'react-apollo';
import { PORTAL_NOTIFICATION_COUNT } from 'graphql/query/portalNotification';

const Header = (props) => {
	const [isSearch, setIsSearch] = useState(false);
	const { data } = useQuery(PORTAL_NOTIFICATION_COUNT, { pollInterval: 1000 });
	const history = useHistory();
	const leftSideBarType = useSelector(
		({ Layout: { leftSideBarType } }) => leftSideBarType
	);

	const isCollapse = leftSideBarType === 'condensed';

	const { isAdmin, admin } = useContext(UserContext);

	/**
	 * Toggle sidebar
	 */
	const toggleMenu = () => {
		props.toggleMenuCallback();
	};

	const Log = () => (
		<Logo>
			<img
				src={dyshLogoImage}
				alt='logo'
				height={35}
				width={35}
				style={{ borderRadius: 5 }}
			/>
			<span className='text-white font-12 ml-2'>
				{isAdmin ? 'DYSH Admin' : 'Lounge Owner'}
			</span>
		</Logo>
	);

	const backgroundColor = isAdmin ? Colors.dyshYellow : Colors.dyshPurple;

	return (
		<>
			<header id='page-topbar' style={{ backgroundColor }}>
				<div className='navbar-header'>
					<div className='d-flex'>
						<div
							className='navbar-brand-box'
							style={{ display: 'flex', alignItems: 'center', backgroundColor }}
						>
							<Link to='/' className='logo logo-dark'>
								<span className='logo-sm'>
									<img src={dyshLogoImage} alt='' height='22' />
								</span>
								{!isCollapse && (
									<span
										className='logo-lg'
										style={{ color: '#111111', fontSize: 25 }}
									>
										DYSH
									</span>
								)}
							</Link>

							<Link to='/' className='logo logo-light'>
								<span className='logo-sm'>
									<img src={dyshLogoImage} alt='' height='22' />
								</span>
								{!isCollapse && (
									<div
										className='logo-lg d-flex align-items-center'
										style={{ color: '#222222', lineHeight: 70 }}
									>
										<Log />
									</div>
								)}
							</Link>
						</div>

						<button
							type='button'
							onClick={toggleMenu}
							className='btn btn-sm px-3 font-size-16 header-item waves-effect'
							id='vertical-menu-btn'
						>
							<i className='fa fa-fw fa-bars text-white'></i>
						</button>

						{/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form> */}
					</div>
					<div className='d-flex'>
						<div className='dropdown d-inline-block d-lg-none ml-2'>
							<button
								onClick={() => {
									setIsSearch(!isSearch);
								}}
								type='button'
								className='btn header-item noti-icon waves-effect'
								id='page-header-search-dropdown'
							>
								<i className='mdi mdi-magnify'></i>
							</button>
							<div
								className={
									isSearch
										? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
										: 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
								}
								aria-labelledby='page-header-search-dropdown'
							>
								<form className='p-3'>
									<div className='form-group m-0'>
										<div className='input-group'>
											<input
												type='text'
												className='form-control'
												placeholder='Search ...'
												aria-label="Recipient's username"
											/>
											<div className='input-group-append'>
												<button className='btn btn-primary' type='submit'>
													<i className='mdi mdi-magnify'></i>
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<div
							className='align-items-center text-white row d-none d-md-flex'
							style={{ fontSize: 13 }}
						>
							{/* <div className="col-sm-24">
                <Link to="/create-lounge" className="mr-3 text-white d-flex align-items-center">
                  <i className="bx bx-file font-size-16 align-middle mr-1"></i>
                  {props.t("Create Lounge")}
                </Link>
              </div>
              <div className="col-sm-24">
                <Link to="/create-content" className="mr-3 text-white d-flex align-items-center">
                  <i className="bx bx-book font-size-16 align-middle mr-1"></i>
                  {props.t("Create Content")}
                </Link>
              </div> */}
							{/* <div className="col-sm-24">
                <Link
                  to="/create-lounge"
                  className="mr-3 text-white d-flex align-items-center"
                >
                  <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                  {props.t("Invite Admin")}
                </Link>
              </div> */}
						</div>

						{isAdmin && <>
							<NotificationContainer 
								onClick={() => {history.push('/notifications')}}
							>
							  <i class="fa fa-bell fa-2x" style={{ color: 'white' }} aria-hidden="true"></i>
							  {
									!!data?.portalNotification_aggregate?.aggregate?.count &&
									<NotificationBadge>
										<NotificationText>
											{data?.portalNotification_aggregate?.aggregate?.count}
										</NotificationText>
									</NotificationBadge>
								}
							</NotificationContainer>
							<InviteButton label='Invite Admin' />				
						</>}
						{!isAdmin && admin?.role?.name === 'ADMIN' && (
							<>
								<InviteNewLoungeOwnerButton label='Lounge Ownership Transfer' />
								<InviteButton label='Invite Team Member' />
							</>
						)}
						{/* <div className="">
            </div> */}
						{/* <div className="d-md-none">
              <AddDropdown />
            </div> */}

						{/* <LoungesDropdown /> */}

						{/* <LanguageDropdown />

            <NotificationDropdown /> */}
						<ProfileMenu />

						{/* <div onClick={toggleRightbar} className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i className="bx bx-cog bx-spin"></i>
              </button>
            </div> */}
					</div>
				</div>
			</header>
		</>
	);
};

const NotificationContainer = styled.div`
	margin-top: 20px;
	margin-right: 30px;
	cursor: pointer;
`;

const Logo = styled.div`
	height: 35px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin-right: 10px;
`;

const NotificationBadge = styled.div`
  background: red;
  color: white;
  height: 20px;
  width: 20px;
  margin-left: 2px;
  border-radius: 50px;
  margin-top: -15px;
  position: absolute;
`;

const NotificationText = styled.div`
  text-align: center;
  font-size: 12px;
  margin-top: 1px;
`;

export default withNamespaces()(Header);
