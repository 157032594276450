import gql from "graphql-tag";

export const LoungeWithMostUsersQuery = gql`
  query mostLoungeWithUsers($limit: Int) {
    lounge(
      order_by: { loungeUsers_aggregate: { count: desc } }
      limit: $limit
    ) {
      id
      name
      imageUrl
      updatedAt
      loungeUsers {
        user {
          id
          username
          firstName
          lastName
          avatarUrl
          email
        }
      }
      loungeUsers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

// export const LoungeOwnerLoungeWithMostUsersQuery = gql`
//   query mostLoungeWithUsers($adminId: uuid, $adminAccountId: uuid) {
//     lounge(order_by: { loungeUsers_aggregate: { count: desc } }) {
//       id
//       name
//       imageUrl
//       updatedAt
//       loungeUsers_aggregate(
//         where: {
//           _and: [
//             {
//               _or: [
//                 { createdByAdminId: { _eq: $adminId } }
//                 { updatedByAdminId: { _eq: $adminId } }
//               ]
//             }
//             { ownedByAccountId: { _eq: $adminAccountId } }
//           ]
//         }
//       ) {
//         aggregate {
//           count
//         }
//       }
//     }
//   }
// `;

export const LoungeOwnerLoungeWithMostUsersQuery = gql`
  query mostLoungeWithUsers($adminId: uuid) {
    lounge(
      where: {
        _or: [
          { createdByAdminId: { _eq: $adminId } }
          { updatedByAdminId: { _eq: $adminId } }
        ]
      }
      order_by: { loungeUsers_aggregate: { count: desc } }
    ) {
      id
      name
      imageUrl
      updatedAt
      loungeUsers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

// "adminAccountId": "d4364fcc-ac9d-4d84-b627-dfb099a6bc28"
