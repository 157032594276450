import React from "react";

const ProfileTableImage = ({user}) => {
  return (
    <div>
      {!user?.image ? (
        <div className='avatar-sm'>
          <span
            className={
              "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
            }
          >
            {user?.name ? user?.name?.slice(0, 1).toUpperCase() : user?.username ? user?.username?.slice(0, 1).toUpperCase() : null}
          </span>
        </div>
      ) : (
        <div className=''>
          <img
            className='rounded-circle avatar-sm'
            src={user?.image}
            alt=''
          />
        </div>
      )}
    </div>
  );
};

export default ProfileTableImage;
