import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { AdminUsers } from "graphql/query/adminUsers";
import Breadcrumbs from "components/Common/Breadcrumb";
import ImageUi from "components/Common/ImageUi";
import NewTableList from "components/TableList/NewTable";

const AllAdmins = () => {
  const history = useHistory();

  const [query, setQuery] = useState("dysh");
  const {
    data: adminAccountData,
    loading,
    refetch
  } = useQuery(AdminUsers, {
    variables: {
      query: `%${query}%`,
    },
  });

  const { data: adminAccountsList } = useQuery(AdminUsers, {
    variables: {
      query: "%%",
    },
  });

  useEffect(() => {
    refetch();
  }, [])

  const adminAcounts = adminAccountData?.adminAccount;

  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "First Name" },
    { id: 3, title: "Last Name" },
    { id: 4, title: "Username" },
    { id: 7, title: "Email" },
    { id: 5, title: "Admin Type" },
    { id: 6, title: "Suspended Status" },
    { id: 7, title: "Date Created" },
  ];

  const data = (admins) => {
    return _.map(admins, ({ id, suspended, user, role, createdAt }) => ({
      id,
      image: <ImageUi imageUrl={user?.avatarUrl} text={user?.username} />,
      firstName: user?.firstName,
      lastName: user?.lastName,
      username: user?.username,
      email: user?.email,
      adminType: role?.name,
      status: (
        <div className="text-center">
          {!suspended ? (
            <i className="bx bx-check-double bx-sm text-success"></i>
          ) : (
            <i className="bx bx-x bx-sm text-danger"></i>
          )}
        </div>
      ),
      date: moment(createdAt).fromNow(),
    }));
  };

  const filterList = _.map(adminAccountsList?.adminAccount, (adminAccount) => ({
    label: adminAccount?.shortName,
    value: adminAccount?.shortName,
  }));

  return (
    <Wrapper>
      <Breadcrumbs title="Admins" breadcrumbItem="Admins" />
      {adminAcounts?.map((adminAccount) => (
        <div>
          <NewTableList
            onChange={() => {}}
            placeholder="Search groups by name or description..."
            btnLabel="Create Group"
            route="/create-group"
            isFilter={true}
            setQuery={setQuery}
            data={data(adminAccount?.admins)}
            columns={columns}
            title={adminAccount?.shortName?.toUpperCase()}
            onRow={(record) => history.push(`/admins/${record?.id}`)}
            totalCount={0}
            limit={0}
            filterList={filterList}
            setOffset={() => {}}
            loading={loading}
            trStyle={{
              cursor: "pointer",
            }}
            search
          />
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: calc(10vh + 3%);
  overflow-y: auto;
`;

export default AllAdmins;
