import * as React from "react";
import { Table } from "antd";
import { DragOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import dragula from "dragula";
import styled from 'styled-components';
import Breadcrumbs from "components/Common/Breadcrumb";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { Spinner, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useParams, useHistory } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button as AntBtn, Tooltip } from 'antd';
import { DeleteVideoMutation, PrivateVideoPlaylistQuery } from 'graphql/query/video';
import { UpdateVideoPositionMutation, GetVideoQuery } from 'graphql/query/video';
import { Config } from '../../config';
import { errorNotify, successNotify } from 'utils/toaster';
import formatVideoDuration from "utils/formatVideoDuration";
import "dragula/dist/dragula.css";
import "./DraggableTable.css"
import { UserContext } from "context/UserContext";
 
export default function DraggableTable() {
  const [currentPage, setCurrentPage] = React.useState(1); 
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [deletingVideo, setDeletingVideo] = React.useState(false);
  const { token } = React.useContext(UserContext);
  
  const history = useHistory();
  const client = useApolloClient();
  const { id } = useParams();
  
  const pageSize = 50; 
  const offset = (currentPage - 1) * pageSize; 

  const { data: playlistData, loading, error, refetch } = useQuery(PrivateVideoPlaylistQuery, {
    variables: {
      id,
      limit: pageSize,
      offset: offset,
    },
  });

  const handleDeleteVideo = async (videoId) => {
    setDeletingVideo(true);
    try {
      const response = await client.query({
        query: GetVideoQuery,
        variables: { videoId },
        fetchPolicy: 'network-only'
      });
  
      const publicId = response.data?.video[0]?.publicId;
      const res = await fetch(`${Config.restApiUrl}/uploads/video/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({ publicId }),
      });
  
      if (res.ok) {
        await client.mutate({
          mutation: DeleteVideoMutation,
          variables: { videoId },
          refetchQueries: ['PrivateVideoPlaylistQuery'],
        });
  
        const updatedVideos = playlistVideos
          .filter(video => video.id !== videoId)
          .map((video, index) => ({ ...video, position: index + 1 })); 
  
        setTableData(updatedVideos);
  
        updatedVideos.forEach(video => {
          updateVideoPosition({
            variables: {
              videoId: video.id,
              position: video.position,
            },
            optimisticResponse: {
              __typename: "Mutation",
              update_video_by_pk: {
                __typename: "videos",
                id: video.id,
                position: video.position,
              },
            },
          });
        });
  
        successNotify("Video Deleted and positions updated successfully");
      } else {
        errorNotify("Video Not Deleted");
      }
  
    } catch (e) {
      errorNotify("Video Not Deleted");
      console.log(e.message);
    } finally {
      setDeleteModal(false);
      setDeleteRecord({});
      setDeletingVideo(false);
    }
  };
  
  const columns = [
    {
      title: "",
      dataIndex: "dragula",
      key: "dragula",
      render: () => <DragOutlined className="draggable" type="swap" />,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Video Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (text, record) => (
        <ThumbnailWrapper>
          <img src={record.thumbnail} alt={record.description} className="thumbnail" />
          <DurationOverlay>{record.duration}</DurationOverlay>
        </ThumbnailWrapper>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <StatusWrapper status={record.status}>
          {record.status}
        </StatusWrapper>
      ),
    },
    {
      title: "Update",
      key: "update",
      render: (text, record) => (
        <StyledButton color="primary" onClick={() => {history.push(`/update-video/${record.key}`)}}>
          <EditOutlined />
        </StyledButton>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <StyledButton color="danger" onClick={() => {
          setDeleteRecord(record);
          setDeleteModal(true);
        }}>
          <DeleteOutlined />
        </StyledButton>
      ),
    },
  ];
  
  const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

  const playlist = playlistData?.privateVideoPlaylist[0];
  const playlistVideoCount = playlist?.videos_aggregate?.aggregate.count;
  const playlistVideos = playlist?.videos || [];

  const [updateVideoPosition] = useMutation(UpdateVideoPositionMutation);
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    if (playlistVideos.length > 0) {
      const transformedData = playlistVideos
        .sort((a, b) => a.position - b.position)
        .map((video) => ({
          key: video.id, 
          description: video.description,
          position: video.position,
          thumbnail: video.thumbnail,
          duration: formatVideoDuration(video.duration),
          status: video.isPublished  ? 'Published' : 'Draft', 
        }));
      setTableData(transformedData);
    }
  }, [playlistVideos]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    refetch({ limit: pageSize, offset: (currentPage - 1) * pageSize }); 
  }, [currentPage, refetch]);

  function handleReorder(dragIndex, draggedIndex) {
    const reorderedData = [...tableData];
    const [item] = reorderedData.splice(dragIndex, 1);
    reorderedData.splice(draggedIndex, 0, item);

    const updatedData = reorderedData.map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    setTableData(updatedData);

    updatedData.forEach((video) => {
      updateVideoPosition({
        variables: {
          videoId: video.key,
          position: video.position,
        },
        optimisticResponse: {
          __typename: "Mutation",
          update_video_by_pk: {
            __typename: "videos",
            id: video.key,
            position: video.position,
          },
        },
      });
    });
  };

  React.useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReorder(start, end);
    });

    return () => drake.destroy();
  }, [tableData]);

  if (loading) {
    return (
      <Wrapper>
        <div style={{
          height: '50vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Spinner />
        </div>
      </Wrapper>
    );
  }

  if (error) {
    return <div>Error loading playlist</div>;
  }

  return (
    <Wrapper>
        <Breadcrumbs 
          title={'Playlists'} 
          breadcrumbItem={playlist?.title || "Playlist"} 
          breadcrumbTitle='Playlists'
          breadcrumbRoute={'/playlists'}
        />
      {
        playlistVideoCount === 0 ? (
        <div
          className='col-sm-12 col-md-6 col-lg-3'
          onClick={() => history.push('/upload-video', { 
            playlistId: playlist?.id, 
            playlistTitle: playlist?.title 
          })}
        >
          <div className='card' style={{ height: 284 }}>
            <div className='card-body p-0 m-0'>
              <div className='border-dash' style={{ height: 284 }}>
                <i className='bx bx-plus-medical plus mb-3'></i>
                <p className='create'>Add Video to playlist</p>
              </div>
            </div>
          </div>
        </div>
        ) : (
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          // pagination={{
          //   pageSize: pageSize,
          //   current: currentPage,
          //   total: playlist?.videos_aggregate?.aggregate?.count || 0,
          //   onChange: handlePageChange,
          //   position: ['bottomCenter'],
          // }}
        />
        )
      }

      <FloatingBtn onClick={() => history.push('/upload-video', { 
        playlistId: playlist?.id, 
        playlistTitle: playlist?.title 
      })}>
        <Tooltip title="Add Video To Playlist" >
            <AntBtn 
              type="primary" 
              style={{ background: '#5039C8', border: 0}} 
              shape="circle" 
              icon={<PlusOutlined />} 
              size="large" 
            />
        </Tooltip>
      </FloatingBtn>

      {/* Delete Video Modal */}
			<Modal isOpen={deleteModal} centered={true}>
        <ModalBody>
          <p>
            Are you sure you want to delete {deleteRecord?.description?.toUpperCase()} video?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button 
            color='secondary'  
            disabled={deletingVideo} 
            onClick={() => {
            setDeleteModal(false);
            setDeleteRecord({});
          }}>
            No
          </Button>{' '}
          <Button
            disabled={deletingVideo}
            color='primary'
            onClick={() => {
              handleDeleteVideo(deleteRecord?.key);
            }}
          >
            {
              deletingVideo ? 'Deleting Video...' : 'Yes'
            }
          </Button>
        </ModalFooter>
			</Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
  padding: 8% 5%;
  background: white;
  min-height: 100vh;
  position: relative;

  .hover-row {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .border-dash {
		display: flex;
		width: 100%;
		height: 250px;
		border-radius: 8px;
		border: 5px dashed #aaaaaa;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.plus {
			font-size: 35px;
			color: #aaaaaa;
		}

		.create {
			font-size: 30;
			color: #aaaaaa;
			font-weight: bolder;
		}
	}
`;

const FloatingBtn = styled.div`
  position: fixed;
  bottom: 5%;
  right: 3%;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 160px;
  height: 90px;
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DurationOverlay = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px;
`;

const StatusWrapper = styled.div`
  background-color: ${({ status }) => (status === 'Published' ? '#5039C8' : 'orange')};
  color: white;
  padding: 5px;
  text-align: center;
  height: 40px;
  max-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;
`;

const StyledButton = styled(Button)`
  border-radius: 5px;
  padding: 0;
  height: 40px;
  width: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
