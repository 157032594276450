import React, { useState } from 'react';
import _, { union } from 'lodash';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Spinner } from 'reactstrap';

import { Config } from '../../config';
import profile from '../../assets/images/profile-img.png';
import { errorNotify } from 'utils/toaster';

import dyshLogoImage from 'assets/images/dysh/dysh-app-icon.png';
import { Colors } from 'assets/colors/Colors';
import { SwitchAccountStyles } from '../../styles/Login/SwitchAccountStyles';

const Login = () => {
	const [accounts, setAccounts] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleValidSubmit = async (_event, values) => {
		setLoading(true);
		try {
			const res = await fetch(`${Config.restApiUrl}/auth/admin/sign-in`, {
				method: 'POST',
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(values),
			});

			const response = await res.json();
			setLoading(false);

			if (_.isEmpty(response?.token)) {
				setLoading(false);
				return errorNotify(response.error);
			}

			if (response?.accountsData?.length === 0) {
				localStorage.setItem('authUser', JSON.stringify(response));
				window.location.href = '/dashboard';
			} else {
				setAccounts(response?.accountsData);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const switchAccount = (acct) => {
		const response = accounts.filter(
			(account) => account.adminAccountId === acct.adminAccountId
		);

		if (response[0].suspended) {
			return errorNotify('Account Suspended');
		}

		if (!response[0].emailConfirmed) {
			return errorNotify('Please confirm your email');
		}

		const data = {
			user: response[0],
			token: {
				id: response[0].id,
				role: response[0].role,
				adminAccountType: response[0].adminAccountType,
			},
		};

		localStorage.setItem('authUser', JSON.stringify(data));
		window.location.href = '/dashboard';
	};

	return (
		<React.Fragment>
			<div className='home-btn d-none d-sm-block'>
				<Link to='/' className='text-dark'>
					<i className='bx bx-home h2'></i>
				</Link>
			</div>
			<div className='account-pages my-5 pt-sm-5'>
				<Container>
					<Row className='justify-content-center'>
						<Col md={8} lg={6} xl={5}>
							<Card className='overflow-hidden'>
								<div style={{ backgroundColor: Colors.dyshPurple }}>
									<Row>
										<Col className='col-7'>
											<div className='text-white p-4'>
												<h5 className='text-white'>Welcome Back !</h5>
												<p>Sign in to continue to DYSH.</p>
											</div>
										</Col>
										<Col className='col-5 align-self-end'>
											<img src={profile} alt='' className='img-fluid' />
										</Col>
									</Row>
								</div>
								<CardBody className='pt-0'>
									<div>
										<Link to='/'>
											<div className='avatar-md profile-user-wid mb-4'>
												<span className='avatar-title rounded-circle bg-light'>
													<img
														src={dyshLogoImage}
														alt=''
														className='rounded-circle'
														height='44'
													/>
												</span>
											</div>
										</Link>
									</div>
									<div className='p-2'>
										<AvForm
											className='form-horizontal'
											onValidSubmit={handleValidSubmit}
										>
											{accounts[0] === undefined && (
												<>
													<div className='form-group'>
														<AvField
															name='email'
															label='Email'
															value=''
															className='form-control'
															placeholder='Enter email'
															type='email'
															required
														/>
													</div>

													<div className='form-group'>
														<AvField
															name='password'
															label='Password'
															type='password'
															required
															placeholder='Enter Password'
														/>
													</div>

													<div className='mt-3'>
														<button
															className='btn btn-block text-white'
															style={{ backgroundColor: Colors.dyshPurple }}
															type='submit'
														>
															{loading ? <Spinner /> : 'Log In'}
														</button>
													</div>
												</>
											)}

											{accounts[0] !== undefined && (
												<SwitchAccountStyles>
													<h5>
														Which of your account would you like to sign-in?
													</h5>
													<div className='content-container'>
														{accounts?.map((account, index) => (
															<div
																className='content'
																key={index}
																onClick={() => switchAccount(account)}
															>
																<img src={account?.avatarUrl} />
																<div className='right-content'>
																	<span>
																		{account?.loungeName}{' '}
																		{account?.role === 'SUPER-ADMIN'
																			? 'Admin Portal'
																			: 'Lounge'}
																	</span>
																	<span>Role: {account?.role}</span>
																</div>
															</div>
														))}
													</div>
												</SwitchAccountStyles>
											)}

											{accounts[0] === undefined && (
												<div className='mt-4 text-center'>
													<Link to='/forgot-password' className='text-muted'>
														<i className='mdi mdi-lock mr-1'></i>
														Forgot your password? | Don't have a password?
													</Link>{' '}
												</div>
											)}
										</AvForm>
									</div>
								</CardBody>
							</Card>
							<div className='mt-5 text-center'>
								{/* <p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p> */}
								<p>
									© {new Date().getFullYear()} DYSH. Crafted with{' '}
									<i className='mdi mdi-heart text-danger'></i> by Bearingspoint
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Login;
