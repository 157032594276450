import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { DetailLayoutView } from 'components/DetailView';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { GetObjectionableContentDetails } from 'graphql/query/reviewAQuery';
import {
	BLOCK_USER,
	UNBLOCK_USER,
	DELETE_FRIEND_MUTATION,
	BLOCK_INFO,
	USER_SUSPENSION_INFO,
} from 'graphql/delete/block_user';
import CustomSpinner from 'ui/CustomSpinner';
import { pluralizeText } from 'utils/pluralizeText';
import { errorNotify, successNotify } from 'utils/toaster';
import {
	UpdateObjectionableContentMutation,
	UpdateReviewReplyObjectionableContentMutation,
	UpdateChatObjectionableContentMutation,
} from 'graphql/update/updateObjectionableContent';
import { UserContext } from 'context/UserContext';

const ReviewDetail = () => {
	const { reviewId } = useParams();
	const { state } = useLocation();

	const { user } = useContext(UserContext);
	const [modal, setModal] = useState(false);

	const [updateObjectionableContent] = useMutation(
		UpdateObjectionableContentMutation
	);
	const [updateReviewReplyObjectionableContent] = useMutation(
		UpdateReviewReplyObjectionableContentMutation
	);
	const [updateChatObjectionableContentMutation] = useMutation(
		UpdateChatObjectionableContentMutation
	);
	const [blockUser] = useMutation(BLOCK_USER);
	const [unBlockUser] = useMutation(UNBLOCK_USER);
	const [deleteFriend] = useMutation(DELETE_FRIEND_MUTATION);

	const reviewReplyObject = {
		reviewReplyId: {
			_eq: reviewId,
		},
	};
	const reviewObject = {
		reviewId: {
			_eq: reviewId,
		},
	};
	const chatObject = {
		chatId: {
			_eq: reviewId,
		},
	};

	const userObject = {
		reportedUserId: {
			_eq: reviewId,
		},
	};

	const {
		data: getObjectionableContentDetailsData,
		loading: getObjectionableContentDetailsLoading,
	} = useQuery(GetObjectionableContentDetails, {
		variables: {
			where: state.review
				? reviewObject
				: state.reviewReply
				? reviewReplyObject
				: state.chat
				? chatObject
				: userObject,
		},
	});

	const objection = getObjectionableContentDetailsData?.objectionableContent[0];

	console.log(objection, '...objection')
	

	const { data } = useQuery(BLOCK_INFO, {
		variables: {
			userId: objection?.reportedUser?.reportedUserObjectionCount[0]?.user?.id,
			blockedUserId: objection?.reportedUser?.id,
		},
	});

	const { data: suspendData } = useQuery(USER_SUSPENSION_INFO, {
		variables: {
			userId: reviewId,
		},
	});

	const isSuspended = suspendData?.user[0]?.isSuspended;

	const isBlocked = data?.block_aggregate?.aggregate?.count ? true : false;
	//reporter
	const userId1 =
		objection?.reportedUser?.reportedUserObjectionCount[0]?.user?.id;
	//reported
	const userId2 = objection?.reportedUser?.id;

	const image = state.review
		? objection?.review?.book?.imageUrl
		: state.reviewReply
		? objection?.reviewReply?.review?.book?.imageUrl
		: '';

	const reviewAsSafe = async () => {
		if (state?.review) {
			try {
				await updateObjectionableContent({
					variables: {
						reviewId,
						set: {
							reviewedAsObjectionable: false,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});
				successNotify('Reviewed as safe content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {}
		}

		if (state?.reviewReply) {
			try {
				await updateReviewReplyObjectionableContent({
					variables: {
						reviewReplyId: reviewId,
						set: {
							reviewedAsObjectionable: false,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});
				successNotify('Reviewed as safe content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {}
		}

		if (state?.chat) {
			try {
				await updateChatObjectionableContentMutation({
					variables: {
						planChatId: reviewId,
						set: {
							reviewedAsObjectionable: false,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});
				successNotify('Reviewed as safe content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {}
		}
	};

	async function handleBlockUser(userId1, userId2) {
		try {
			// unfriend user
			await deleteFriend({
				variables: {
					userId1,
					userId2,
				},
			});

			// block user
			await blockUser({
				variables: {
					objects: [
						{
							userId: userId1,
							blockedUserId: userId2,
						},
					],
				},
				refetchQueries: [
					{
						query: BLOCK_INFO,
						variables: {
							userId: userId1,
							blockedUserId: userId2,
						},
					},
				],
			});
			successNotify('User blocked successfully');
		} catch (e) {
			errorNotify('Error, Unable to block user');
			console.log(e);
		}
	}

	async function handleUnBlockUser(userId1, userId2) {
		try {
			await unBlockUser({
				variables: {
					userId: userId1,
					blockedUserId: userId2,
				},
				refetchQueries: [
					{
						query: BLOCK_INFO,
						variables: {
							userId: userId1,
							blockedUserId: userId2,
						},
					},
				],
			});
			successNotify('User unblocked successfully');
		} catch (e) {
			errorNotify('Error, Unable to unblock user');
			console.log(e);
		}
	}

	return (
		<Wrapper>
			{getObjectionableContentDetailsLoading ? (
				<CustomSpinner />
			) : (
				<DetailLayoutView
					image={image}
					group
					breadcrumbTitle='Review'
					breadcrumbItem={
						state?.chat
							? 'Chat Detail'
							: state?.user
							? 'User Detail'
							: 'Review Detail'
					}
					breadcrumbRoute={'lounge-owner'}
				>
					<NewDetailView
						key0={
							state?.chat
								? {
										label: 'Group Name',
										status: 'edit',
										type: 'text',
										value: objection?.chat?.plan?.name,
										style: {
											fontSize: 15,
										},
								  }
								: state?.user
								? {
										label: 'Full Name',
										status: 'edit',
										type: 'text',
										value: `${objection?.reportedUser?.firstName} ${objection?.reportedUser?.lastName}`,
										style: {
											fontSize: 15,
										},
								  }
								: {
										label: 'Book Title',
										status: 'edit',
										type: 'text',
										value: state.review
											? objection?.review?.book?.title
											: objection?.reviewReply?.review?.book?.title,
										style: {
											fontSize: 16,
										},
								  }
						}
						key1={
							state?.chat
								? {
										label: 'Message By',
										status: 'edit',
										type: 'text',
										value: `@${objection?.chat?.user?.username}`,
								  }
								: state?.user
								? {
										label: 'Username',
										status: 'edit',
										type: 'text',
										value: `@${objection?.reportedUser?.username}`,
								  }
								: {
										label: 'Review By',
										status: 'edit',
										type: 'text',
										value: state.review
											? `@${objection?.review?.user?.username}`
											: `@${objection?.reviewReply?.user?.username}`,
								  }
						}
						key2={
							state?.chat
								? {
										label: 'Objection Raised By',
										status: 'edit',
										type: 'text',
										value: `@${objection?.chat?.objectionableContentsByChat[0]?.user?.username}`,
								  }
								: state?.user
								? {
										label: 'Reported By',
										status: 'edit',
										type: 'text',
										value: `@${objection?.reportedUser?.reportedUserObjectionCount[0]?.user?.username}`,
								  }
								: {
										label: 'Objection Raised By',
										status: 'edit',
										type: 'text',
										value: state.review
											? `@${objection?.review?.objectionableContents[0]?.user?.username}`
											: `@${objection?.reviewReply?.objectionableContents[0]?.user?.username}`,
								  }
						}
						key3={
							state.chat
								? {
										label: 'Message:',
										status: 'edit',
										type: 'description',
										value: objection?.chat?.message,
								  }
								: state?.user
								? {
										label: 'Reported User Email:',
										status: 'edit',
										type: 'description',
										value: objection?.reportedUser?.email,
								  }
								: {
										label: 'Review:',
										status: 'edit',
										type: 'description',
										value: state.review
											? objection?.review?.review
											: state.reviewReply === true
											? objection?.reviewReply?.reply
											: 'N/A',
								  }
						}
						key4={
							state?.chat
								? {
										label: 'Reason:',
										status: 'edit',
										type: 'description',
										value:
											objection?.chat?.objectionableContentsByChat[0]?.reason,
								  }
								: state?.user
								? {
										label: 'Reason:',
										status: 'edit',
										type: 'description',
										value:
											objection?.reportedUser?.reportedUserObjectionCount[0]
												?.reason,
								  }
								: {
										label: 'Reason:',
										status: 'edit',
										type: 'description',
										value: state.review
											? objection?.review?.objectionableContents[0]?.reason
											: objection?.reviewReply?.objectionableContents[0]
													?.reason,
								  }
						}
						key5={{
							label: 'Reported',
							type: 'text',
							value: moment(objection?.createdAt).fromNow(),
						}}
						key6={
							state?.chat
								? {
										label: 'Objection Count',
										type: 'text',
										value:
											objection?.chat?.objectionableContentsByChat_aggregate
												?.aggregate?.count,
								  }
								: state?.user
								? {
										label: 'Objection Count',
										type: 'text',
										value:
											objection?.reportedUser
												?.reportedUserObjectionCount_aggregate?.aggregate
												?.count,
								  }
								: {
										label: 'Objection Count',
										type: 'text',
										value: state.review
											? objection?.review?.objectionableContents_aggregate
													?.aggregate?.count
												? pluralizeText(
														objection?.review?.objectionableContents_aggregate
															?.aggregate?.count,
														'User'
												  )
												: 'N/A'
											: objection?.reviewReply?.objectionableContents_aggregate
													?.aggregate?.count
											? pluralizeText(
													objection?.reviewReply
														?.objectionableContents_aggregate?.aggregate?.count,
													'User'
											  )
											: 'N/A',
								  }
						}
						key7={{
							label: 'Status',
							type: 'text',
							value: state.review
								? objection?.review?.objectionableContents[0]
										?.reviewedAsObjectionable &&
								  !objection?.review?.objectionableContents[0]?.reviewDate
									? 'Reported'
									: objection?.review?.objectionableContents[0]?.reviewDate &&
									  !objection?.review?.objectionableContents[0]
											?.reviewedAsObjectionable
									? 'Resolved'
									: 'Archived'
								: objection?.reviewReply?.objectionableContents[0]
										?.reviewedAsObjectionable &&
								  !objection?.reviewReply?.objectionableContents[0]?.reviewDate
								? 'Reported'
								: objection?.reviewReply?.objectionableContents[0]
										?.reviewDate &&
								  !objection?.reviewReply?.objectionableContents[0]
										?.reviewedAsObjectionable
								? 'Resolved'
								: 'Archived',
						}}
						action={{
							type: 'action',
							actionText: {
								btn2Text: state?.user
									? `${isSuspended ? 'Unsuspend User' : 'Suspend User'}`
									: 'Review As Objectionable',
								btn3Text: state?.user
									? `${isBlocked ? 'Unblock User' : 'Block User'}`
									: 'Review As Safe',
							},
							action: {
								btn2: () => {},
								btn3: () => {
									setModal(true);
								},
							},
							actionStyle: {
								btn2Style: {
									background: 'danger',
								},
								btn3Style: {},
							},
						}}
					/>
				</DetailLayoutView>
			)}

			<Modal isOpen={modal} centered={true}>
				<ModalBody>
					<p>
						{state?.user
							? `Are you sure you want to ${
									isBlocked ? 'unblock' : 'block'
							  } reported user from the reporter?`
							: 'Are you sure you want to hide this review?'}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setModal(false)}>
						No
					</Button>{' '}
					<Button
						color='info'
						onClick={async () => {
							if (state?.user) {
								if (isBlocked) {
									handleUnBlockUser(userId1, userId2);
								} else {
									handleBlockUser(userId1, userId2);
								}
								setModal(false);
							} else {
								await reviewAsSafe();
								setModal(false);
							}
						}}
					>
						Yes
					</Button>
				</ModalFooter>
			</Modal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: calc(100vh - 10vh);
	margin-top: 10vh;
	overflow-y: auto;
`;

export default ReviewDetail;
