import React from 'react'
import Profile from './Profile'

const Setting = () => {
  return (
    <div>
      <Profile />
    </div>
  )
}

export default Setting
