import gql from "graphql-tag";

export const GetUserInviteCount = gql`
  query getUserInviteCount($loungeOwnerId: uuid!, $loungeId: uuid!) {
    loungeTeamMembersApplications_aggregate(
      where: {
      loungeOwnerId: {_eq: $loungeOwnerId}, 
      loungeId: {_eq: $loungeId}, 
      approvedAt: {_is_null: true}, 
      declinedAt: {_is_null: true}
    }) {
    aggregate {
      count
    }
  }}
`;
