import gql from 'graphql-tag';

export const PrivateVideoPlaylistsQuery = gql`
  query PrivateVideoPlaylistsQuery($loungeId: uuid!) {
    privateVideoPlaylist(where: {loungeId: {_eq: $loungeId}}) {
      id
      description
      imageUrl
      loungeId
      title
      updatedAt
      videos_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const PrivateVideoPlaylistQuery = gql`
  query PrivateVideoPlaylistQuery($id: uuid!, $limit: Int!, $offset: Int!) {
    privateVideoPlaylist(where: {id: {_eq: $id}}) {
      id
      description
      title
      videos_aggregate {
        aggregate {
          count
        }
      }
      videos(order_by: {position: asc}, limit: $limit, offset: $offset) {
        id
        duration
        description
        isPublished
        loungeId
        playlistId
        position
        publishedBy
        thumbnail
        videoUrl
        deleted
      }
    }
  }
`;

export const PrivateVideoPlaylistMutation = gql`
  mutation PrivateVideoPlaylistMutation($object: privateVideoPlaylist_insert_input!) {
    insert_privateVideoPlaylist_one(object: $object) {
      id
      title
      imageUrl
      description
    }
  }
`;

export const PrivateVideoPlaylist = gql`
  query PrivateVideoPlaylist($id: uuid!) {
    privateVideoPlaylist(where: {id: {_eq: $id}}) {
      id
      description
      title
      imageUrl
      createdAt
    }
  }
`;
 
export const UpdatePrivateVideoPlaylist = gql`
  mutation UpdatePrivateVideoPlaylist($id: uuid!, $set: privateVideoPlaylist_set_input!) {
    update_privateVideoPlaylist_by_pk(pk_columns: {id: $id}, _set: $set) {
      id
      description
      title
      imageUrl
      createdAt
    }
  }
`;

export const UpdatePrivateVideoPlaylistMutation = gql`
  mutation UpdatePrivateVideoPlaylistMutation($id: uuid!, $videos: [videos_insert_input!]!) {
    update_privateVideoPlaylist(where: {id: {_eq: $id}}, _set: {videos: $videos}) {
      returning {
        id
        description
        title
        videos {
          id
          duration
          description
          position
          thumbnail
        }
      }
    }
  }
`;

export const UpdateVideoPositionMutation = gql`
  mutation UpdateVideoPosition($videoId: uuid!, $position: Int!) {
    update_video_by_pk(pk_columns: { id: $videoId }, _set: { position: $position }) {
      id
      position
    }
  }
`;

export const InsertVideoMutation = gql`
  mutation InsertVideoMutation($object: video_insert_input!) {
    insert_video_one(object: $object) {
      id
      isPublished
      loungeId
      playlistId
      position
      duration
      description
      deleted
      createdAt
      publicId
      publishedBy
      thumbnail
      videoUrl
    }
  }
`;

export const VideoAggregateQuery = gql`
  query VideoAggregateQuery($playlistId: uuid!) {
    video_aggregate(where: {playlistId: {_eq: $playlistId}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GetVideoQuery = gql`
  query GetVideoQuery($videoId: uuid!) {
    video(where: {id: {_eq: $videoId}}) {
      id
      description
      duration
      isPublished
      position
      publicId
      videoUrl
      thumbnail
      playlist: privateVideoPlaylist {
        id
        title
      }
    }
  }
`;

export const DeleteVideoMutation = gql`
  mutation DeleteVideoMutation($videoId: uuid!) {
    delete_video_by_pk(id: $videoId) {
      id
    }
  }
`;

export const UpdateVideoMutation = gql`
  mutation UpdateVideoMutation($videoId: uuid!, $set: video_set_input) {
    update_video_by_pk(pk_columns: {id: $videoId}, _set: $set) {
      id
      description
    }
  }
`;

export const PrivateVideoPlaylistAggregate = gql`
  query PrivateVideoPlaylistAggregate($loungeId: uuid) {
    privateVideoPlaylist_aggregate(where: {loungeId: {_eq: $loungeId}}) {
      aggregate {
        count
      }
    }
  }
`;
