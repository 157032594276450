import gql from "graphql-tag";

export const DeactivateAdminMutation = gql`
  mutation deactivateAdmin($adminId: uuid!, $set: admin_set_input) {
    update_admin(where: { id: { _eq: $adminId } }, _set: $set) {
      returning {
        id
        suspended
      }
    }
  }
`;

export const DeleteAdminMutation = gql`
  mutation DELETE_ADMIN($adminId: uuid!) {
    delete_admin(where: {id: {_eq: $adminId}}) {
      affected_rows
    }
  }
`;
