import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { textTruncate } from "utils/textTruncate";

const UITooltip = ({ icon, message, target }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      {!icon && <span id={target}>{textTruncate(message)}</span>}
      {icon && icon}
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={target}
        toggle={toggle}
      >
        {message}
      </Tooltip>
    </div>
  );
};

export default UITooltip;
