import gql from 'graphql-tag'

export const ConfirmUserMutation = gql`
  mutation confirmUser($where: user_bool_exp!, $set: user_set_input) {
    update_user(_set: $set, where: $where) {
      returning {
        id
        username
        firstName
        lastName
        email
        isEmailConfirmed
      }
    }
  }
`