import gql from "graphql-tag";

export const AddBookGenreMutation = gql`
  mutation addBookGenre($objects: [bookGenre_insert_input!]!) {
    insert_bookGenre(objects: $objects) {
      returning {
        id
        book {
          id
          title
        }
        genre {
          id
          title
        }
      }
    }
  }
`;
