import gql from "graphql-tag";

export const UpdateLoungeOwnerApplicationMutation = gql`
  mutation updateLoungeOwnerApplication(
    $where: loungeOwnerApplications_bool_exp!
    $set: loungeOwnerApplications_set_input
  ) {
    update_loungeOwnerApplications(where: $where, _set: $set) {
      returning {
        id
        requestingUserId
        offeredLoungeName
        user {
          firstName
          lastName
          avatarUrl
          email
          username
          id
        }
      }
    }
  }
`;
