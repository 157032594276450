import { Colors } from "assets/colors/Colors";
import { UsersQuery } from "graphql/users";
import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Card, CardBody, Col, FormGroup, Input } from "reactstrap";
import styled from "styled-components";
import { FlexDiv } from "../style";

const NewSearch = ({ values, setValues, value }) => {
  const [state, setState] = useState("");

  const { data } = useQuery(UsersQuery, {
    variables: {
      search: {
        _or: [state ? { username: { _ilike: `%${state}%` } } : {}],
      },
      limit: 5,
    },
  });

  const users = data?.user;

  const userOptions = _.map(users, (user) => ({
    id: user?.id,
    username: user?.username,
    value: user?.id,
  }));

  return (
    <FormGroup className="mb-4 row">
      <FlexDiv borderBottom width="100%">
        <Col md={8}>
          <Div>
            <Input
              type="search"
              value={values.username}
              onChange={({ target: { value } }) => {
                setState(value);
                setValues({
                  ...values,
                  username: value,
                });
              }}
              placeholder="Select an owner..."
            />
          </Div>
          {state && (
            <CardStyle>
              {_.map(userOptions, ({ username, id }) => (
                <CardBodyStyle
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setValues({
                      ...values,
                      userId: id,
                      username,
                    });
                    setState("");
                  }}
                >
                  {username}
                </CardBodyStyle>
              ))}
            </CardStyle>
          )}
        </Col>
      </FlexDiv>
    </FormGroup>
  );
};

const Div = styled.div`
  position: relative;
`;

const CardStyle = styled(Card)`
  position: absolute;
  top: 40px;
  width: 90%;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  z-index: 2;
`;

const CardBodyStyle = styled(CardBody)`
  padding: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${Colors.dyshPurple};
    color: white;
  }
`;

export default NewSearch;
