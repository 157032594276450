import { useMutation } from "@apollo/react-hooks";
import { DetailLayoutView, DetailView } from "components/DetailView";
import { UserContext } from "context/UserContext";
import {
  InsertPlanMutation,
} from "graphql/insert/inser_plan";
import React, { useContext, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";

const CreateGroup = () => {
  const { user } = useContext(UserContext);

  const [preview, setPreview] = useState();
  const [loungeId, setLoungeId] = useState();

  const [values, setValues] = useState({
    name: "",
    description: "",
    timeAllocated: 60,
    duration: 1,
    isPrivate: false,
    isActive: false,
  });

  const [insertPlan, {loading: insertPlanLoading}] = useMutation(InsertPlanMutation);

  const loading = insertPlanLoading;

  const handleFile = ({ target: { files } }) => {
    // const url = URL.createObjectURL(files[0])
    // setPreview(url)
    const reader = new FileReader();

    reader.onloadend = function () {
      setPreview(reader.result);
    };
  };

  const handleCreate = async () => {
    const { name, description, isPrivate, timeAllocated, duration } = values;
    if (name && user?.id) {

      await insertPlan({
        fetchPolicy: "no-cache",
        variables: {
          objects: {
            loungeId,

            plan: {
              data: {
                description: description ?? "",
                duration: parseInt(duration, 10),
                name: name,
                private: isPrivate,
                timeAllocated: parseInt(timeAllocated, 10),
                type: "group",
                userId: "e05358a3-f5c9-44c8-acdd-7ea084cf6600"
              },
            },
          },
        },
      });


      
    }
  };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col>
            <DetailLayoutView image={preview} onChange={handleFile} group>
              <DetailView
                title={""}
                owner={{
                  owner: "",
                }}
                description={""}
                status={{ status: true }}
                loungeUsersCount={0}
                values={values}
                setValues={setValues}
                setSelected={setLoungeId}
                handleCreate={handleCreate}
                loading={loading}
                create
                isGroup={true}
              />
            </DetailLayoutView>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  margin-top: 10vh;
  padding: 0 5%;
`;

export default CreateGroup;
