import React from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = ({ height = "50vh", width = "100%" }) => {
  return (
    <div
      style={{
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
};

export default CustomSpinner;
