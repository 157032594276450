import React from "react";
import _ from "lodash";
import { formatPrice } from "utils/formatPrice";
import moment from "moment";
import ImageUi from "components/Common/ImageUi";

export const latestTransactionFile = (transactions) => {
  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "Content Title" },
    { id: 3, title: "Author" },
    { id: 4, title: "Bought By" },
    { id: 5, title: "Status" },
    { id: 6, title: "Price" },
    { id: 7, title: "Date" },
    { id: 8, title: "Receipt" },
  ];

  const data = _.map(transactions, (transaction) => ({
    id: transaction?.id,
    image: (
      <ImageUi
        imageUrl={transaction?.book?.imageUrl}
        text={transaction?.book?.title}
      />
    ),
    title: transaction?.book?.title,
    author: transaction?.book?.author,
    boughtBy: transaction?.user?.username,
    badge: (
      <span>
        {transaction?.success ? (
          <i className="bx bx-check-double bx-sm text-success"></i>
        ) : (
          <i className="bx bx-x bx-sm text-danger"></i>
        )}
      </span>
    ),
    price: formatPrice(transaction?.book?.priceValue),
    date: moment(transaction?.createdAt).fromNow(),
    receipt: (
      <a
        href={transaction?.receiptUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Receipt
      </a>
    ),
  }));

  return {
    columns,
    data,
  };
};
