/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import moment from 'moment';

import { LoungeQuery } from 'graphql/lounges';
import ErrorHandler from 'utils/CatchError';
import { UserContext } from 'context/UserContext';
import Pages500 from 'pages/Utility/pages-500';
import NewTableList from 'components/TableList/NewTable';
import ImageUi from 'components/Common/ImageUi';
import Breadcrumbs from 'components/Common/Breadcrumb';
import UITooltip from 'pages/Ui/UITooltip';

const limit = 12;

const Lounges = () => {
	const { getUserDetailsFromLocalStorage } = useContext(UserContext);

	const [query, setQuery] = useState('');
	const [offset, setOffset] = useState(0);

	const {
		data: loungeData,
		loading: loungeLoadingState,
		error: loungeError,
		refetch,
	} = useQuery(LoungeQuery, {
		variables: {
			search: {
				_or: [
					{ name: { _ilike: `%${query}%` } },
					{ description: { _ilike: `%${query}%` } },
				],
			},
			limit: limit,
			offset: offset,
		},
	});

	useEffect(() => {
		if (refetch) {
			refetch();
		}
		getUserDetailsFromLocalStorage();
	}, [refetch]);

	const lounges = loungeData?.lounge;
	const loungeAggregate = loungeData?.lounge_aggregate?.aggregate?.count;

	const handleQuery = ({ target: { value } }) => {
		setQuery(value);
	};

	const error = ErrorHandler.getUserFriendlyError(loungeError?.toString());

	if (loungeError?.toString()) {
		return <Pages500 error={error} />;
	}

	const columns = [
		{ id: 1, title: 'Image' },
		{ id: 2, title: 'Lounge Name' },
		{ id: 3, title: 'Lounge Description' },
		{ id: 4, title: 'Lounge Owner' },
		{ id: 5, title: 'Lounge Status' },
		{ id: 6, title: 'Date Created' },
	];

	const data = _.map(lounges, (lounge) => ({
		id: lounge?.id,
		image: <ImageUi imageUrl={lounge?.imageUrl} text={lounge?.name} />,
		name: lounge?.name,
		description: Boolean(lounge?.description) ? (
			<UITooltip
				message={lounge?.description}
				target={`lounge-description-${lounge?.id}`}
			/>
		) : (
			'N/A'
		),
		owner: lounge?.user?.username ? lounge?.user?.username : 'N/A',
		badge: (
			<span>
				{lounge?.isActive ? (
					<i className='bx bx-check-double bx-sm text-success'></i>
				) : (
					<i className='bx bx-x bx-sm text-danger'></i>
				)}
			</span>
		),
		date: moment(lounge?.createdAt).fromNow(),
	}));

	return (
		<Wrapper>
			<Breadcrumbs title='Lounge' breadcrumbItem='Lounge' />
			<div>
				<NewTableList
					onChange={handleQuery}
					placeholder='Search lounges by name or description...'
					btnLabel='Create Lounge'
					route='/create-lounge'
					data={data}
					columns={columns}
					// title="Lounges"
					totalCount={loungeAggregate}
					limit={limit}
					paginate
					setOffset={setOffset}
					onRow={(record) => (window.location.href = `/lounge/${record?.id}`)}
					loading={loungeLoadingState}
					trStyle={{
						cursor: 'pointer',
					}}
					search
				/>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 0 5%;
	padding-top: calc(10vh + 3%);
	overflow-y: auto;
`;

export default Lounges;
