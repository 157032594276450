import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { formatPrice } from 'utils/formatPrice';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { UpdateBookWithIncMutation } from 'graphql/update/updateBook';
import { errorNotify, successNotify } from "utils/toaster";
import { Button } from 'antd';
import moment from 'moment';
import { useMutation } from "react-apollo";
import dyshLogoImage from 'assets/images/dysh/dysh-app-icon.png';
import { Colors } from 'assets/colors/Colors';

const truncateText = (text) => {
	return text?.length > 20 ? `${text.slice(0, 20)}...` : text;
};

 
const ContentItem = ({ routeName, item }) => {
	const [updateBook, { loading }] = useMutation(UpdateBookWithIncMutation);
	const [modal, setModal] = useState(false);
	const history = useHistory();

	const price = formatPrice(
		item?.priceValue,
		item?.priceCurrency?.toUpperCase()
	);

	return (
		<Container
			className='col-sm-12 col-md-6 col-lg-3'
		>
			<div className='card' style={{ height: 284 }}>
			  
				<div className='card-body p-0 py-3 m-0 content d-flex flex-column justify-content-between'>
					<div className='px-4 d-flex justify-content-center'>
						<div style={{ height: 122, overflow: 'hidden' }}>
							<img
								src={item?.imageUrl ? item?.imageUrl : dyshLogoImage}
								alt='content'
								width='100%'
								 className="image"
								style={{ objectFit: 'fill', objectPosition: 'center' }}
							/>
						</div>
					</div>
					<div className='d-flex flex-column justify-content-between align-items-center px-3 pb-2'>
						<p className='content-title'>
							{item?.title ? truncateText(item?.title) : 'N/A'}
						</p>
						<p className='content-author'>
							{item?.author ? truncateText(item?.author) : 'N/A'}
						</p>
						<p className='content-price'>{price ? price : 'N/A'}</p>
						<p>No. of reads: {item?.readStats ?? 'N/A'}</p>
					</div>
					<div
						className='text-center text-white py-2'
						style={{
							backgroundColor: item?.isPublished
								? Colors.dyshPurple
								: Colors.dyshYellow,
						}}
					>
						{item?.isPublished ? 'Published' : 'Draft'}
					</div>
				</div>
				{
					item?.isPublished ? (
						<div className='card-action-btn'>
							<Button style={{ width: 120 }} onClick={() => { setModal(true)}}>Reshare</Button>
							<Button style={{ width: 120 }} onClick={() => history.push(`${routeName}/${item?.id}`)}>Edit Content</Button>
						</div>
					) : (
						<div className='card-action-btn'>
							<Button style={{ width: 240 }} onClick={() => history.push(`${routeName}/${item?.id}`)}>Edit Content</Button>
						</div>
					)
				} 
			</div>
			<Modal isOpen={modal} centered={false}>
        <ModalHeader>
          Please Review Below Information Before You Reshare
        </ModalHeader>
        <ModalBody>
					<p>Content: {item?.title}</p>
          <p>Number of times reshared: {item?.numberOfTimesReshared}</p>
          <p>Last time reshared: {!item?.resharedDate ? 'N/A' : moment(item?.resharedDate).fromNow()}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={async () => {
              try {
                await updateBook({
                  variables: {
                    where: {
                      id: {
                        _eq: item?.id
                      }
                    }, 
                    inc: {
                      numberOfTimesReshared: 1
                    }, 
                    set: {
                      createdAt: new Date().toISOString(),
                      resharedDate: new Date().toISOString()
                    }
                  },
                  refetchQueries: ["booksQuery", "contentDraftsQuery"]
                });
                setModal(false);
                successNotify(`Article Reshared Successfully`);
              } catch(e) {
                errorNotify(`Unable To Reshare Article`);
                console.log(e.message)
              }
            }}
          >
           { loading ? "Please Wait" : "Reshare"}
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
		</Container>
	);
};


const Container = styled.div`
	.card-action-btn {
		display: none;
		position: absolute;
		justify-content: center;
		align-self: center;
		height: 38px;
    width: 100%;
		bottom: 0px;
	}

  &:hover {
    .card-action-btn {
      display: flex;
    }
  }

	@media (max-width: 768px) {
	  .card-action-btn {
      display: flex;
    }
	}
`;

export default ContentItem;
