import React, { useEffect, useRef, useState } from "react";
import { Badge, Progress } from "reactstrap";
import styled from "styled-components";

import dyshLogoImage from "assets/images/dysh/dysh-app-icon.png";
import expandYourTeam from "assets/videos/expandYourTeam.mp4";
import manageLounge from "assets/videos/manageLounge.mp4";
import addContent from "assets/videos/addContent.mp4";
import { Colors } from "assets/colors/Colors";

const Onboarding = ({ toggle }) => {
  const videoRef = useRef(null);
  const [videos, setVideos] = useState([{ id: 1, video: expandYourTeam }]);

  const [currentTime, setCurrentTime] = useState(0);
  const [step, setStep] = useState(0);

  const percent = videoRef.current?.currentTime
    ? (videoRef.current?.currentTime / videoRef.current?.duration) * 100
    : 0;

  useEffect(() => {
    let timer;
    if (percent <= 100) {
      timer = setInterval(() => {
        setCurrentTime(
          (videoRef.current?.currentTime / videoRef.current?.duration) * 100
        );
      }, (videoRef.current?.currentTime / videoRef.current?.duration) * 100);
    }

    return () => clearInterval(timer);

    // eslint-disable-next-line
  }, [currentTime, setCurrentTime, step, percent]);

  useEffect(() => {
    setVideos([
      { id: 1, video: expandYourTeam },
      { id: 2, video: manageLounge },
      { id: 3, video: addContent },
    ]);

    // eslint-disable-next-line
  }, [setVideos]);

  const next = () => {
    setStep((prev) => (prev += 1));
    if (step >= videos.length - 1) {
      setStep(0);
    }
  };
  const prev = () => {
    setStep((prev) => (prev -= 1));
    if (step <= 0) {
      setStep(videos.length - 1);
    }
  };

  return (
    <Wrapper>
      <div className="bg-white h-100 w-100 board">
        <header>
          <div className="pl-4 d-flex align-items-center header">
            <span className="logo-sm mr-3">
              <img src={dyshLogoImage} alt="" height="22" />
            </span>
            <h4 className="mb-0">Using The Portal</h4>
          </div>
          <p
            className="d-flex justify-content-end pr-4 font-22 mb-0 cursor"
            onClick={() => toggle()}
          >
            &times;
          </p>
        </header>
        <main>
          <aside>
            <h3>Tutorials</h3>
            <div className="aside-menu" onClick={() => setStep(0)}>
              <p className="mb-0">Expanding Your Team</p>
              <Progress
                color={Colors.dyshPurple}
                value={step === 0 ? currentTime : 0}
              ></Progress>
            </div>
            <div className="aside-menu" onClick={() => setStep(1)}>
              <p className="mb-0">Manage Your Lounge</p>
              <Progress
                color={Colors.dyshPurple}
                value={step === 1 ? currentTime : 0}
              ></Progress>
            </div>
            <div className="aside-menu" onClick={() => setStep(2)}>
              <p className="mb-0">Add Content</p>
              <Progress
                color={Colors.dyshPurple}
                value={step === 2 ? currentTime : 0}
              ></Progress>
            </div>
          </aside>
          <article>
            <div className="article-header">
              <h3>Expanding Your Team</h3>
              <div>
                <Badge style={{ backgroundColor: Colors.dyshPurple }}>
                  {step + 1} of {videos.length}
                </Badge>
                <i
                  className="fas fa-chevron-left mx-4 arrow"
                  onClick={prev}
                ></i>
                <i className="fas fa-chevron-right arrow" onClick={next}></i>
              </div>
            </div>
            <div className="article-video">
              <video
                src={videos[step]?.video}
                autoPlay
                ref={videoRef}
                controls
              ></video>
            </div>
          </article>
        </main>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 650px;
  /* padding: 50px; */
  overflow-y: auto;
  background-color: ${Colors.dyshPurple};

  .board {
    display: grid;
    grid-template-rows: 10% 90%;
    border-radius: 8px;
    overflow: hidden;

    header {
      border-bottom: 3px solid ${Colors.gray200};
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cursor {
        cursor: pointer;
      }
    }

    main {
      display: grid;
      grid-template-columns: 25% auto;
      height: 100%;

      aside {
        background-color: #f1f1f7;
        padding: 30px;

        h3 {
          color: ${Colors.gray500};
          margin-bottom: 40px;
        }

        .aside-menu {
          margin-bottom: 30px;
          cursor: pointer;
        }
      }

      article {
        .article-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;

          h3 {
            color: ${Colors.gray700};
          }

          .arrow {
            cursor: pointer;
          }
        }

        .article-video {
          height: 250px;
          padding: 20px;

          video {
            width: 100%;
            height: 350px;
          }
        }
      }
    }
  }
`;

export default Onboarding;
