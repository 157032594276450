import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { Flex } from "../style";

const DetailPriceCurrency = ({
  currency,
  setValues,
  values,
  create,
  state,
  setState,
  editIcon,
  closeIcon,
  editValues,
  setEditValues,
  edit,
}) => {
  const currencies = [
    { label: "GBP", value: "gbp" },
    { label: "USD", value: "usd" },
    { label: "NGN", value: "ngn" },
  ];

  return (
    <Flex
      width="100%"
      borderBottom
      padding={`20px 5%`}
      style={{ position: "relative" }}
    >
      {/* {!create && <span>Price Currency: {currency ? currency : "N/A"}</span>} */}
      {!create && !state?.priceCurrencyState && (
        <Flex width="100%">
          {
            <span style={{ whiteSpace: "no-wrap" }}>
              Price Currency: {currency ? currency : "N/A"}
            </span>
          }
          {!state?.loungeState &&
            editIcon({ ...state, priceCurrencyState: true })}
        </Flex>
      )}
      {create && !edit && (
        <Flex width="100%">
          <FormGroup className="select2-container w-100 row">
            <Label className="col-4">Price Currency (optional): </Label>
            <Col>
              <Select
                options={currencies}
                style={{ width: "100% !important" }}
                onChange={({ value }) =>
                  setValues({
                    ...values,
                    priceCurrency: value,
                  })
                }
              />
            </Col>
          </FormGroup>
        </Flex>
      )}

      {!create && edit && state?.priceCurrencyState && (
        <Flex width="100%">
          <FormGroup className="select2-container w-100 row">
            <Label className="col-4">Price Currency: </Label>
            <Col>
              <Select
                options={currencies}
                style={{ width: "100% !important" }}
                onChange={({ value }) =>
                  setEditValues({
                    ...editValues,
                    priceCurrency: value,
                  })
                }
              />
            </Col>
          </FormGroup>
          {state?.priceCurrencyState &&
            closeIcon({ ...state, priceCurrencyState: false })}
        </Flex>
      )}
    </Flex>
  );
};

export default DetailPriceCurrency;
