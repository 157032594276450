import React from "react";
import { Collapse, Form, FormGroup } from "reactstrap";
import { Flex } from "../style";

const DetailDescription = ({
  open,
  editIcon,
  state,
  create,
  collapse,
  editValues,
  setEditValues,
  description,
  setValues,
  values,
  closeIcon,
  placeholder,
}) => {
  const titlePlaceholder = placeholder && placeholder["description"];

  return (
    <>
      <Flex
        borderBottom
        padding={`20px 5%`}
        direction={open ? "column" : "row"}
        width="100%"
      >
        <Flex direction={open ? "column" : "row"} width="100%">
          <Flex
            justify="space-between"
            width="100%"
            padding={`20px 0`}
          >
            <Flex style={{ width: "100%" }}>
              <p>
                Description {editIcon({ ...state, descriptionState: true })}
              </p>
            </Flex>
            {!create && (
              <div>
                <i
                  className="bx bx-chevron-down bx-5"
                  onClick={() => collapse()}
                ></i>
              </div>
            )}
          </Flex>

          {!state.descriptionState && !create && (
            <Collapse isOpen={open}>
              <p>{description}</p>
            </Collapse>
          )}

          {(state.descriptionState || create) && (
            <Form>
              <FormGroup style={{ alignSelf: "flex-start", width: "100%" }}>
                <Flex>
                  {!create ? (
                    <textarea
                      className="form-control"
                      id="projectdesc"
                      style={{ width: "100%" }}
                      rows="3"
                      value={editValues?.description}
                      placeholder={
                        titlePlaceholder
                          ? titlePlaceholder
                          : "Enter Project Description..."
                      }
                      onChange={({ target: { value } }) => {
                        setEditValues({
                          ...editValues,
                          description: value,
                        });
                      }}
                    ></textarea>
                  ) : (
                    <textarea
                      className="form-control"
                      id="projectdesc"
                      style={{ width: "100%" }}
                      rows="3"
                      placeholder={
                        titlePlaceholder
                          ? titlePlaceholder
                          : "Enter Project Description..."
                      }
                      onChange={({ target: { value } }) =>
                        setValues({
                          ...values,
                          description: value,
                        })
                      }
                    ></textarea>
                  )}
                  {closeIcon({ ...state, descriptionState: false })}
                </Flex>
              </FormGroup>
            </Form>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default DetailDescription;
