/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import { Button } from 'reactstrap';

import { ContainerDiv } from "./style";
import NewInput from "./detailComponents/NewInput";
import NewTextarea from "./detailComponents/NewTextarea";
import NewUpload from "./detailComponents/NewUpload";
import NewSelect from "./detailComponents/NewSelect";
import NewText from "./detailComponents/NewText";
import NewActionButton from "./detailComponents/NewActionButton";
import NewSearch from "./detailComponents/NewSearch";

const NewDetailView = (props) => {
  const keys = Object.keys(props);
  const values = Object.values(props);

  return (
    <ContainerDiv style={props?.style}>
      {values?.map((value) => {
        switch (value?.type) {
          case "text":
            return <NewText text={value} />;
          case "description":
            return (
              <NewTextarea
                description={value}
                values={props?.values}
                setValues={props?.setValues}
              />
            );
          case "input":
            return (
              <NewInput
                label={value}
                values={props?.values}
                setValues={props?.setValues}
              />
            );
          case "select":
            return (
              <NewSelect
                select={value}
                values={props?.values}
                setValues={props?.setValues}
                deactivateSelection={props?.deactivateSelection}
                onSelectChange={props?.onSelectChange}
              />
            );
          case "search":
            return (
              <NewSearch
                value={value}
                select={value}
                values={props?.values}
                setValues={props?.setValues}
                query={props?.queryUsername}
                setQuery={props?.setQueryUsername}
              />
            );
          case "upload":
            return <NewUpload upload={value}  enctype={'multipart/form-data'} />;
          case "action":
            return (
              <NewActionButton
                actionText={value?.actionText}
                actionLoading={value?.actionLoading}
                action={value?.action}
                disabled={value?.disabled}
                actionStyle={value?.actionStyle}
                previewUrl={props?.previewUrl}
                setDraftModal={props?.setDraftModal}
              />
            );
          case "button": 
           return (
            <Button 
              color="primary"
              style={{ margin: '30px' }}
              outline
              disabled={value?.disabled}
              onClick={props?.onActionButtonClick}
              className='waves-effect waves-light'
            >
              {value?.label}
            </Button>
          )
              
          default:
            return null;
        }
      })}
    </ContainerDiv>
  );
};

export default NewDetailView;
