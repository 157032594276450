import gql from "graphql-tag";

export const AdminUsers = gql`
  query adminUsers($query: String) {
    adminAccount(
      where: {
        _and: [
          { shortName: { _ilike: $query } }
          # { admins: { suspended: { _eq: false } } }
        ]
      }
    ) {
      id
      shortName
      admins {
        id
        suspended
        createdAt
        role {
          id
          name
        }
        user {
          id
          username
          firstName
          lastName
          email
          avatarUrl
        }
      }
    }
  }
`;
