import gql from "graphql-tag";

export const UpdateAdminMutation = gql`
  mutation updateAdmin($where: admin_bool_exp!, $set: admin_set_input) {
    update_admin(where: $where, _set: $set) {
      returning {
        id
        onBoarded
      }
    }
  }
`;
