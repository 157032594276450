import gql from 'graphql-tag';

export const RemoveExistingCover = gql`
	mutation RemoveExistingCover($loungeId: uuid) {
		update_book(
			where: { lounges: { lounge: { id: { _eq: $loungeId } } } }
			_set: { isLoungeCover: false }
		) {
			affected_rows
			returning {
				id
			}
		}
	}
`;
