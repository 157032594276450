const dyshYellow = '#FCC733';
const dyshPurple = '#5139C9';

export const Colors = {

  /* TODO Remove old colors after redesign */
  border           : '#D8D8D8',
  default          : '#021930',
  defaultBackground: 'rgba(240, 243, 255, .5)',
  defaultBackground2: 'rgba(238, 235, 250, .5)',
  error            : '#e74c3c',
  highlight        : '#21BF34',
  muted            : '#02193047',
  mutedBackground  : '#f3f3f3',
  mutedLine        : '#eee',
  negativeAction   : '#F23050',
  primaryAction    : '#21BF34',
  primaryActionBg  : '#4FE06B',
  shadow           : '#ddd',
  success          : '#27ae60',
  white            : '#fff',

  /* New colors */
  /* eslint-disable-next-line sort-keys */
  appleBlue: 'rgb(0,122,255)',
  blue200  : '#BBE6ED',
  blue300  : '#0E5A66',
  blue500  : '#1B75BB',
  blue700  : '#0C64A9',
  gray100  : 'rgba(140, 150, 174, .5)',
  gray200  : 'rgba(140, 150, 174, .1)',
  gray50   : '#EDEDED',
  gray500  : 'rgb(140, 150, 174)',
  gray700  : '#8C96AE',
  gray900  : '#021930',
  green100 : '#DCF2CC',
  green200 : '#D4F0CD',
  green500 : '#73D789',
  green700 : 'rgba(48, 112, 32, .6)',
  green800 : '#307020',
  indigo100: '#E2E9FF',
  indigo500: '#91ACFF',
  orange100: '#FEDECC',
  orange500: '#ED7C3D',
  purple100: '#E7E2FE',
  purple500: '#9E60FF',
  red100   : '#FEEAEC',
  red200   : '#FFDCDE',
  red500   : '#FF9DA7',
  red800   : '#F3494C',
  dyshYellow: dyshYellow,
  dyshLightYellow: 'rgba(255, 241, 197, .7)',
  dyshPurple: dyshPurple,
  dyshPurpleLight: '#eeebfa',
  dyshPurpleLight100: '#dcd7f4'
}