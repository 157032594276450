import React, { useState } from "react";
import { Label, Input } from "reactstrap";
import { textTruncate } from "utils/textTruncate";
import { FlexDiv } from "../style";

const NewInput = ({ label, values, setValues }) => {
  const name = label?.name;
  const [state, setState] = useState(false);

  const editPage =
    (label?.status === "edit" && !state) || label?.type === "text";

  const isCreate = label?.status === "create";
  const isEdit = label?.status === "edit" && state;

  return (
    <div>
      {editPage ? (
        <FlexDiv borderBottom>
          <Label>{label?.label}:</Label>
          <span style={{ marginLeft: 10, max: 200, whiteSpace: "nowrap" }}>
            {textTruncate(label?.value, 45)}
          </span>
          {!isCreate && label?.isEditable && (
            <i onClick={() => setState(true)} className="bx bx-pencil ml-3"></i>
          )}
        </FlexDiv>
      ) : (
        isCreate && (
          <FlexDiv
            direction={label?.status === "create" ? "column" : "row"}
            borderBottom
          >
            <Label>{label?.label}</Label>
            <Input
              id="name"
              name="name"
              type="text"
              value={values[name]}
              className="form-control"
              placeholder={label?.placeholder}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  [`${name}`]: value,
                });
              }}
              style={{ width: "100%" }}
            />
          </FlexDiv>
        )
      )}
      {isEdit && (
        <>
          <FlexDiv
            direction={label?.status === "create" ? "column" : "row"}
            borderBottom
            align="center"
          >
            <Label style={{ marginRight: 10 }}>
              <span style={{ whiteSpace: "nowrap" }}>{label?.label}</span>:
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
              value={values[name]}
              className="form-control"
              placeholder={label?.placeholder}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  [`${name}`]: value,
                });
              }}
              style={{ width: "100%" }}
            />
            <i
              onClick={() => setState(false)}
              className="fas fa-times ml-3"
            ></i>
          </FlexDiv>
        </>
      )}
    </div>
  );
};

export default NewInput;
