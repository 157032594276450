import gql from 'graphql-tag';

export const WithdrawTeamInvite = gql`
	mutation WithdrawTeamInvite($inviteId: uuid) {
		delete_loungeTeamMembersApplications(
			where: { invitedUserId: { _eq: $inviteId } }
		) {
			returning {
				id
			}
		}
	}
`;
