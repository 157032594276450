import gql from 'graphql-tag'

export const AddBookToLoungeMutation = gql`
  mutation addBookToLounge($objects: [loungeBook_insert_input!]!) {
    insert_loungeBook(objects: $objects) {
      returning {
        id
        book {
          id
          title
        }
        lounge {
          id
          name
        }
      }
    }
  }
`
