import gql from "graphql-tag";

export const LoungeQuery = gql`
  query loungeQuery($search: lounge_bool_exp, $limit: Int, $offset: Int) {
    lounge(
      where: $search
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc }
    ) {
      id
      name
      description
      message
      ageRestriction
      imageUrl
      isActive
      isPrivate
      approvedAt
      createdAt
      user {
        id
        username
      }
      loungeBooks {
        book {
          id
          title
        }
      }
      loungeBooks_aggregate {
        aggregate {
          count
        }
      }
      loungeOwner {
        id
        firstName
        lastName
        username
        email
        avatarUrl
      }
      plans {
        plan {
          id
          name
          books {
            book {
              id
              title
              description
              author
              bookUrl
              createdAt
              imageUrl
            }
          }
        }
      }
      loungeUsers {
        id
        user {
          id
          username
          firstName
          lastName
          avatarUrl
        }
      }
      plans_aggregate {
        aggregate {
          count
        }
      }
      user {
        username
        plans_aggregate {
          aggregate {
            count
          }
        }
      }
      loungeUsers_aggregate {
        aggregate {
          count
        }
      }
    }

    lounge_aggregate(where: $search) {
      aggregate {
        count
      }
    }
  }
`;

export const LoungeOwnerLoungeQuery = gql`
  query loungeQuery($search: lounge_bool_exp, $limit: Int, $offset: Int) {
    lounge(
      where: $search
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc }
    ) {
      id
      name
      description
      message
      ageRestriction
      imageUrl
      isActive
      isPrivate
      approvedAt
      createdAt
      loungeBooks {
        book {
          id
          title
        }
      }
      loungeBooks_aggregate {
        aggregate {
          count
        }
      }
      loungeOwner {
        id
        firstName
        lastName
        username
        email
        avatarUrl
      }
      plans {
        plan {
          id
          name
          books {
            book {
              id
              title
              description
              author
              bookUrl
              createdAt
              imageUrl
            }
          }
        }
      }
      loungeUsers {
        id
        user {
          id
          username
          firstName
          lastName
          avatarUrl
        }
      }
      plans_aggregate {
        aggregate {
          count
        }
      }
      user {
        username
        plans_aggregate {
          aggregate {
            count
          }
        }
      }
      loungeUsers_aggregate {
        aggregate {
          count
        }
      }
    }

    lounge_aggregate(where: $search) {
      aggregate {
        count
      }
    }
  }
`;
