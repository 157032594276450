import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import error from "../../assets/images/error-img.png";

const Pages404 = ({ message, btnText, icon, route }) => {
  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-5'>
        <Container>
          <Row>
            <Col lg='12'>
              <div className='text-center mb-2 mx-auto'>
                {icon ? (
                  <h1 className='display-2 font-weight-bold mb-0 pb-0' style={{fontSize: 80, color: '#777777'}}>
                    <i class='bx bxs-folder-open'></i>
                  </h1>
                ) : (
                  <h1 className='display-2 font-weight-medium'>
                    4
                    <i className='bx bx-buoy bx-spin text-primary display-3'></i>
                    4
                  </h1>
                )}
                <h4 className='text-uppercase'>
                  {message ? message : "Sorry, page not found"}
                </h4>
                <div className='mt-2 text-center'>
                  <Link
                    className='btn btn-primary waves-effect waves-light'
                    to={route ? route : '/dashboard'}
                  >
                    {btnText ? btnText : "Back to Dashboard"}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          {!icon && <Row className='justify-content-center'>
            <Col md='8' xl='6'>
              <div>
                <img src={error} alt='' className='img-fluid' />
              </div>
            </Col>
          </Row>}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pages404;
