import { useMutation, useQuery } from "@apollo/react-hooks";
import EditUser from "components/EditUser";
import { GetAUserQuery } from "graphql/a_user_query";
import { ConfirmUserMutation } from "graphql/update/confirm_user";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { errorNotify, successNotify } from "utils/toaster";

const UserDetail = () => {
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const [confirmUser, { loading: confirmLoading }] =
    useMutation(ConfirmUserMutation);
  // const [addUser] = useMutation(AddUserMutation);
  // const [removeUser] = useMutation(RemoveUserMutation);

  const { userId } = useParams();

  const { data: userData, loading: userLoading } = useQuery(GetAUserQuery, {
    variables: {
      userId: userId ? userId : null,
    },
  });

  let user = userData?.user ? userData?.user[0] : {};
  let loading = userLoading;

  useEffect(() => {
    setIsEmailConfirmed(user?.isEmailConfirmed);
    setIsSuspended(user?.blocked);
  }, [loading, user]);

  const handleConfirmUser = async () => {
    try {
      const res = await confirmUser({
        variables: {
          where: { id: { _eq: userId } },
          set: {
            isEmailConfirmed: true,
            blocked: isSuspended,
          },
        },
      });
      const message = `${res?.data?.update_user?.returning[0]?.username} confirm successfully`;
      successNotify(message);
      setIsEmailConfirmed(true);
    } catch (error) {
      setIsEmailConfirmed(false);
      const err = error?.toString()?.split(":")[2]?.trim();
      errorNotify(err || "Something went wrong");
    }
  };
  const handleUnConfirmUser = async () => {
    try {
      const res = await confirmUser({
        variables: {
          where: { id: { _eq: userId } },
          set: {
            isEmailConfirmed: false,
            blocked: isSuspended,
          },
        },
      });
      const message = `${res?.data?.update_user?.returning[0]?.username} unconfirmed successfully`;
      successNotify(message);
      setIsEmailConfirmed(false);
    } catch (error) {
      setIsEmailConfirmed(true);
      const err = error?.toString()?.split(":")[2]?.trim();
      errorNotify(err || "Something went wrong");
    }
  };

  return (
    <Wrapper>
      <Title>Edit User</Title>
      <EditUser
        user={user}
        handleConfirmUser={handleConfirmUser}
        handleUnConfirmUser={handleUnConfirmUser}
        onClick={
          user?.isEmailConfirmed ? handleUnConfirmUser : handleConfirmUser
        }
        setIsEmailConfirmed={setIsEmailConfirmed}
        setIsSuspended={setIsSuspended}
        isSuspended={isSuspended}
        isEmailConfirmed={isEmailConfirmed}
        loading={confirmLoading}
      />
      <ToastContainer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: 10vh;
`;

const Title = styled.h1`
  margin-top: 2%;
`;

export default UserDetail;
