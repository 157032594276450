import { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { LoungeOwnerBookAggregate } from "graphql/books";
import { LoungeQuery } from "graphql/lounges";
import { GroupQuery } from "graphql/query/groups";
import { LoungeOwnerMostPurchaseBookQuery } from "graphql/query/mostPurchaseBook";
import { LoungeOwnerUserPurchaseQuery } from "graphql/query/userPurchaseQuery";
import { UsersForLast30DaysQuery } from "graphql/users";
import { LoungeOwnerLoungeWithMostUsersQuery } from "graphql/query/loungeWithMostUsers";
import { LoungeOwnerMostActiveGroup } from "graphql/query/mostActiveGroupQuery";
import { LoungeOwnerLatestTransactionQuery } from "graphql/query/latestTransactionQuery";
import { LoungeOwnerLoungeUsersQuery } from "graphql/query/ownedLounges";
import moment from "moment";
import { UserContext } from "context/UserContext";
import _ from "lodash";

export const useAdminQueries = () => {
  const [dates] = useState({
    startDate: moment().subtract(30, "days").format(),
    endDate: new Date().toISOString(),
  });

  const { adminId, adminAccountId } = useContext(UserContext);

  const { data: booksData, loading: loungeOwnerBookLoading } = useQuery(
    LoungeOwnerBookAggregate,
    {
      variables: {
        adminId,
        adminAccountId,
      },
    }
  );
  const { loading: loungeOwnerUserLoading } = useQuery(
    UsersForLast30DaysQuery,
    {
      variables: {
        startDate: dates.startDate,
        endDate: dates.endDate,
      },
    }
  );
  const { data: usersData, loading: loungeOwnerUserCountLoading } = useQuery(
    LoungeOwnerLoungeUsersQuery,
    {
      variables: {
        adminId,
        adminAccountId,
        // startDate: dates.startDate,
        // endDate: dates.endDate,
      },
    }
  );
  const {
    data: loungeData,
    loading: loungeOwnerLoungeLoading,
    error: loungeError,
  } = useQuery(LoungeQuery, {
    variables: {
      search: {
        _and: [
          {
            ownedByAccountId: { _eq: adminAccountId },
          },
          {
            _or: [
              { createdByAdminId: { _eq: adminId } },
              { updatedByAdminId: { _eq: adminId } },
            ],
          },
        ],
      },
    },
  });
  const { data: groupData, loading: loungeOwnerGroupLoading } = useQuery(
    GroupQuery,
    {
      variables: {
        search: {
          lounges: {
            lounge: {
              _and: [
                {
                  _or: [
                    { createdByAdminId: { _eq: adminId } },
                    { updatedByAdminId: { _eq: adminId } },
                  ],
                },
                { ownedByAccountId: { _eq: adminAccountId } },
              ],
            },
          },
        },
      },
    }
  );
  const { data: userPurchaseData, loading: loungeOwnerUserPurchaseLoading } =
    useQuery(LoungeOwnerUserPurchaseQuery, {
      variables: {
        adminId,
        adminAccountId,
      },
    });
  const { data: mostPuchaseBookData, loading: loungeOwnerMostPurchaseLoading } =
    useQuery(LoungeOwnerMostPurchaseBookQuery, {
      variables: {
        adminId,
        adminAccountId,
      },
    });
  const {
    data: loungeWithMostUsersData,
    loading: loungeOwnerLoungeWithMostUsersLoading,
  } = useQuery(LoungeOwnerLoungeWithMostUsersQuery, {
    variables: {
      adminId,
      adminAccountId,
    },
  });
  const { data: mostActiveGroupData, loading: loungeOwnerMostActiveLoading } =
    useQuery(LoungeOwnerMostActiveGroup, {
      variables: {
        where: {
          lounges: {
            lounge: {
              _and: [
                { ownedByAccountId: { _eq: adminAccountId } },
                {
                  _or: [
                    { createdByAdminId: { _eq: adminId } },
                    { updatedByAdminId: { _eq: adminId } },
                  ],
                },
              ],
            },
          },
        },
      },
    });
  const {
    data: latestTransactionData,
    loading: loungeOwnerLatestTransactionLoading,
  } = useQuery(LoungeOwnerLatestTransactionQuery);

  const loungeUsers = {
    user: _.flatten(
      _.map(usersData?.lounge, (lounge) => lounge?.loungeUsers)
    ).slice(0, 3),
    userCount: _.flatten(
      _.map(usersData?.lounge, (lounge) => lounge?.loungeUsers)
    ),
  };

  const loungeOwnerUsers = {
    user: _.uniqBy(
      _.orderBy(
        _.map(loungeUsers?.user, (user) => user?.user),
        "createdAt",
        "desc"
      ),
      "username"
    ),
  };

  const loungeOwnerData = {
    loungeOwnerUsers,
    loungeUsers,
    loungeOwnerLoungeData: loungeData,
    loungeOwnerGroupData: groupData,
    loungeOwnerUserPurchaseData: userPurchaseData,
    loungeOwnerMostActiveGroupData: mostActiveGroupData,
    loungeOwnerLoungeWithMostUsersData: loungeWithMostUsersData,
    loungeOwnerLatestTransaction: latestTransactionData,
    loungeOwnerMostPurchaseBookQuery: mostPuchaseBookData,
    loungeOwnerBookData: booksData,
  };

  const loungeOwnerLoading = {
    loungeOwnerUserLoading,
    loungeOwnerBookLoading,
    loungeOwnerLoungeLoading,
    loungeOwnerGroupLoading,
    loungeOwnerUserPurchaseLoading,
    loungeOwnerMostActiveLoading,
    loungeOwnerLoungeWithMostUsersLoading,
    loungeOwnerLatestTransactionLoading,
    loungeOwnerUserCountLoading,
    loungeOwnerMostPurchaseLoading,
  };

  const adminError = {
    loungeError,
  };

  return { loungeOwnerData, loungeOwnerLoading, adminError };
};
