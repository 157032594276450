import moment from "moment";
import React from "react";
import { pluralizeText } from "utils/pluralizeText";
import { Flex } from "../style";

const DetailOthers = ({
  create,
  date,
  count1,
  count2,
  isContent,
  group,
  priceValue,
}) => {
  const count1Key = count1 && Object.keys(count1)[0];
  const count2Key = count2 && Object.keys(count2)[0];
  return (
    <div>
      <Flex borderBottom padding={`20px 5%`}>
        <span>Created Date:</span>
        <span className="owner">{create ? "N/A" : moment(date).fromNow()}</span>
      </Flex>
      {count1 && (
        <Flex borderBottom padding={`20px 5%`}>
          <span>{count1Key}:</span>
          <span className="owner">
            {create
              ? "N/A"
              : `${
                  typeof count1[count1Key] === "string"
                    ? count1[count1Key]
                    : `${
                        count1[count1Key] === 0
                          ? "N/A"
                          : pluralizeText(count1[count1Key], "user")
                      }`
                }`}
          </span>
        </Flex>
      )}
      {count2 && (
        <Flex borderBottom padding={`20px 5%`}>
          <span>{count2Key}:</span>
          <span className="owner">
            {create
              ? "N/A"
              : `${
                  typeof count2[count2Key] === "string"
                    ? count2[count2Key]
                    : pluralizeText(count2[count2Key], "user")
                }`}
          </span>
        </Flex>
      )}
    </div>
  );
};

export default DetailOthers;
