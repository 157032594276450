import { Colors } from "assets/colors/Colors";
import React, { useState } from "react";
import Pagination from "react-bootstrap-4-pagination";

const TablePagination = (props) => {
  const [current, setCurrent] = useState(1);
  const { totalCount, setOffset, limit } = props;

  const totalPages = Math.ceil(totalCount / limit);

  let mdSize = {
    totalPages,
    currentPage: current,
    showMax: 5,
    threeDots: true,
    prevNext: true,
    onClick: function (page) {
      setCurrent(page);
      setOffset((page - 1) * limit);
    },
  };

  return (
    <Pagination
      {...mdSize}
      activeBgColor={Colors.appleBlue}
      activeBorderColor={Colors.appleBlue}
    />
  );
};

export default TablePagination;
