import React from "react";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

//Import Breadcrumb
import { UsersQuery } from "../../graphql/users";

//Import Card
import TableSearchAndCreate from "components/TableList/TableSearchAndCreate";
import _ from "lodash";
import NewTableList from "components/TableList/NewTable";
import { textTruncate } from "utils/textTruncate";
import ImageUi from "components/Common/ImageUi";

const limit = 10;

const Users = () => {
  const history = useHistory();

  const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(0);

  const { data: usersData, loading: userLoading } = useQuery(UsersQuery, {
    variables: {
      search: {
        _or: [
          { username: { _ilike: `%${query}%` } },
          { firstName: { _ilike: `%${query}%` } },
          { lastName: { _ilike: `%${query}%` } },
          { email: { _ilike: `%${query}%` } },
        ],
      },
      limit: limit,
      offset: offset,
    },
  });
  const users = usersData?.user;
  const usersCount = usersData?.user_aggregate?.aggregate?.count;

  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "First Name" },
    { id: 2, title: "Last Name" },
    { id: 3, title: "Username" },
    { id: 4, title: "Email Address" },
    { id: 5, title: "Email Status" },
    { id: 6, title: "Date Joined" },
  ];

  const data = _.map(users, (user) => ({
    id: user?.id,
    image: <ImageUi imageUrl={user?.avatarUrl} text={user?.username} />,
    firstName: textTruncate(user?.firstName),
    lastNameName: textTruncate(user?.lastName),
    username: textTruncate(user?.username),
    email: textTruncate(user?.email),
    badge: (
      <span>
        {user?.isEmailConfirmed ? (
          <i className="bx bx-check-double bx-sm text-success"></i>
        ) : (
          <i className="bx bx-x bx-sm text-danger"></i>
        )}
      </span>
    ),
    date: moment(user?.createdAt).fromNow(),
  }));

  const handleQuery = ({ target: { value } }) => {
    setQuery(value);
  };

  return (
    <Wrapper>
      <div className="page-content">
        <TableSearchAndCreate
          onChange={handleQuery}
          btnLabel=""
          placeholder="Search users by there name, email or username..."
        />
        <NewTableList
          title="Users"
          columns={columns}
          data={data}
          totalCount={usersCount}
          limit={limit}
          paginate
          setOffset={setOffset}
          onRow={(record) => history.push(`/user/${record?.id}`)}
          loading={userLoading}
          trStyle={{
            cursor: "pointer",
          }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  overflow-y: auto;
`;

export default Users;
