import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import _ from "lodash";
import styled from "styled-components";
import ErrorHandler from "utils/CatchError";
import Pages500 from "pages/Utility/pages-500";
import { GetRoles } from "graphql/query/getRoles";
import { CreateAdminAccountMutation } from "graphql/insert/insert_admin_account";
import { errorNotify, successNotify } from "utils/toaster";
import { Config } from "../../config";

// const isErrorHelperFunc = (error) => {
//   const key = Object.keys(error)[0];
//   if (key === "error") {
//     return true;
//   }
//   return false;
// };

const adminType = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "LOUNGEOWNER", value: "LOUNGEOWNER" },
];

const CreateAdminAccount = ({ setIsOpen }) => {
  const [imgLoading, setImgLoading] = useState(false);
  const [values, setValues] = useState({
    shortName: "",
    entityName: "",
    type: "ADMIN",
  });

  const [file, setFile] = useState();

  const [insertAdminAccount, { loading }] = useMutation(
    CreateAdminAccountMutation
  );

  const { error: roleError } = useQuery(GetRoles);

  // const roles = _.map(rolesData?.roles, (role) => ({
  //   label: role?.name?.toUpperCase(),
  //   value: role?.id,
  // }));

  const handleInput = ({ target: { name, value } }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const createAccount = async () => {
    const form = new FormData();
    form.append("avatarUrl", file);
    try {
      if (!values.shortName || !values.entityName) {
        errorNotify("Account Name and Account Entity Name are required");
        return;
      }
      if (file) {
        setImgLoading(true);
        const resp = await fetch(`${Config.restApiUrl}/uploads/admin-account`, {
          method: "POST",
          "Content-type": "multipart/form-data",
          body: form,
        });

        const response = await resp.json();
        setImgLoading(false);

        const avatarUrl = response?.data?.avatarUrl;

        if (avatarUrl) {
          await insertAdminAccount({
            variables: {
              objects: {
                ...values,
                avatarUrl,
              },
            },
          });
        } else {
          await insertAdminAccount({
            variables: {
              objects: {
                ...values,
              },
            },
          });
        }
      } else {
        await insertAdminAccount({
          variables: {
            objects: {
              ...values,
            },
          },
        });
      }

      setValues({
        shortName: "",
        entityName: "",
        type: "",
      });
      setFile();

      successNotify("Successfully create an admin account");
    } catch (error) {
      setImgLoading(false);
      const err = ErrorHandler.getUserFriendlyError(error?.toString());
      errorNotify(err);
    }
  };

  const error = ErrorHandler.getUserFriendlyError(roleError?.toString());

  const finalLoading = imgLoading || loading;

  if (roleError?.toString()) {
    return <Pages500 error={error} />;
  }

  return (
    <div>
      <Form>
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">Account Name</Label>
          <Col md={8}>
            <Div>
              <Input
                type="text"
                name="shortName"
                value={values.shortName}
                onChange={handleInput}
                placeholder="Account Name..."
              />
            </Div>
          </Col>
        </FormGroup>
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">Account Entity</Label>
          <Col md={8}>
            <Div>
              <Input
                type="text"
                value={values.entityName}
                name="entityName"
                onChange={handleInput}
                placeholder="Account Entity..."
              />
            </Div>
          </Col>
        </FormGroup>
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">DYSH Account</Label>
          <Col md={8}>
            <Select
              onChange={({ value }) =>
                setValues({
                  ...values,
                  type: value,
                })
              }
              options={adminType}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mb-4 row">
          <Label className="col-form-label col-md-4">Upload Logo</Label>
          <Col md={8}>
            <Input
              type="file"
              value={values.username}
              onChange={({ target: { files } }) => {
                setFile(files[0]);
              }}
              placeholder="Account Name..."
            />
          </Col>
        </FormGroup>
        <ButtonGroup>
          <Button className="mr-4" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          {finalLoading ? (
            <Spinner />
          ) : (
            <Button
              color="primary"
              disabled={!values.shortName || !values.entityName}
              onClick={createAccount}
            >
              Create Admin Account
            </Button>
          )}
        </ButtonGroup>
      </Form>
    </div>
  );
};

const Div = styled.div`
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

export default CreateAdminAccount;
