import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { DetailLayoutView } from 'components/DetailView';
import React, { useContext, useState } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Col,
	Row, 
} from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { errorNotify, successNotify } from 'utils/toaster';
import { Config } from '../../config';
import { getExtName } from 'utils/getExtName';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { useHistory, useLocation } from 'react-router-dom';
import { 
	PrivateVideoPlaylistsQuery, 
	InsertVideoMutation, 
	VideoAggregateQuery, 
	PrivateVideoPlaylistMutation,
	PrivateVideoPlaylistAggregate 
} from 'graphql/query/video';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { UserContext } from 'context/UserContext';
import uploadGif from "assets/images/upload.gif";
import { defaultPlaylistImage } from 'constant/defaultImage';

const UploadVideo = React.memo(() => {
	const history = useHistory();
	const location = useLocation();
	const { user, token } = useContext(UserContext);
	const client = useApolloClient();
	const { loungeName, loungeId } = useContext(LoungePortalContext);
	const [addVideoToPlaylist, { loading: addingToPlaylist }] = useMutation(InsertVideoMutation); 

  const [videoUrlFile, setVideoUrlFile] = useState();
  const [videoFormFile, setVideoFormFile] = useState();
	const [publishModal, setPublishModal] = useState(false);
	const [draftModal, setDraftModal] = useState(false);
	const [switchModalToUploadView, setSwitchModalToUploadView] = useState(false);

	const [values, setValues] = useState({
		description: '',
		playlist: '',
	});

	const { data: playlistData } = useQuery(PrivateVideoPlaylistsQuery, {
		variables: {
			loungeId
		},
		fetchPolicy: 'cache-and-network'
	});
	const { data: privateVideoPlaylistData } = useQuery(PrivateVideoPlaylistAggregate, {
		variables: {
			loungeId
		},
		fetchPolicy: 'cache-and-network'
	});
	const [addPlaylist] = useMutation(PrivateVideoPlaylistMutation);

	let playlists = playlistData?.privateVideoPlaylist;
	const playlistCount = privateVideoPlaylistData?.privateVideoPlaylist_aggregate?.aggregate?.count;

	playlists = _.map(playlists, (playlist) => ({
		label: playlist?.title,
		value: playlist?.id,
	}));

	const handleContentUpload = async ({ target: { files } }) => {
		try {
			if (files.length === 0) return;
	
			const file = files[0];
			const extName = getExtName(file);
			const contentSize = file.size / 1000;
			const max = 100000;
	
			if (contentSize > max) {
				return errorNotify('Content size should be less than 100mb');
			}
	
			if (!(extName === 'mp4' || extName === '3gp' || extName === 'avi')) {
				return errorNotify('Please upload a valid video content');
			}
	
			const localUrl = URL.createObjectURL(file);
			setVideoUrlFile(localUrl); 
			setVideoFormFile(file);
		} catch (e) {
			console.log(e.message);
		}
	};

	const handleUploadVideo = async (arg) => {
		const {
			description,
			playlist: { label: playlistTitle, value: playlistId },
		} = values;

		const isDraft = !!arg;
		const playlistID = !location?.state?.playlistId ? playlistId : location?.state?.playlistId;
		const playListTitle = !location?.state?.playlistId ? playlistTitle : location?.state?.playlistTitle;

		if (!videoFormFile) {
			return errorNotify(
				'Please upload video'
			);
		}

		try {
			if (videoFormFile && !_.isEmpty(description)) {
				setSwitchModalToUploadView(true);
				let newPlaylistId;
				let newPlaylistTitle;

				if (playlistCount === 0) {
					const res = await addPlaylist({
						variables: {
							object: {
								title: 'New Playlist',
								description: 'A new playlist for you',
								imageUrl: defaultPlaylistImage,
								loungeId,
								createdBy: `${user.firstName} ${user.lastName}`,
							},
						},
						refetchQueries: ['PrivateVideoPlaylistsQuery']
					});
					newPlaylistId = res?.data?.insert_privateVideoPlaylist_one?.id;
					newPlaylistTitle = res?.data?.insert_privateVideoPlaylist_one?.title;
				}

				const form = new FormData();
				form.append('file', videoFormFile);
				form.append('playlist', playlistCount === 0 ? newPlaylistTitle : playListTitle);
				form.append('loungeName', loungeName);
				form.append('description', description);

				const res = await fetch(`${Config.restApiUrl}/uploads/video`, {
					method: 'POST',
					headers: {
						"Authorization": token
					},
					body: form,
				});
				
				const result = await res.json();

				const { video: { duration, public_id, secure_url, eager }} = result;
				const thumbnail = eager[0].url;

				if (secure_url) {
					const response = await client.query({ 
						query: VideoAggregateQuery, 
						variables: { 
							playlistId: playlistCount === 0 ? newPlaylistId : playlistID 
						},
						fetchPolicy: 'network-only'
					});
			
					const videosCount = response.data?.video_aggregate?.aggregate?.count;
					const videoPosition = videosCount + 1;

					const res = await addVideoToPlaylist({
						variables: {
							object: {
								loungeId,
								playlistId: playlistCount === 0 ? newPlaylistId : playlistID,
								position: videoPosition,
								duration,
								description,
								publicId: public_id,
								publishedBy: `${user.firstName} ${user.lastName}`,
								thumbnail,
								videoUrl: secure_url,
								isPublished: isDraft ? false : true 
							}
						}
					})

					history.push(`/playlists/${playlistCount === 0 ? newPlaylistId : playlistID}`);
					successNotify(`Video added to your playlist successfully`);
				} else {
					errorNotify('Video not uploaded');
				}

			} else {
				errorNotify('Please fill all fields to proceed');
			}
			
		} catch (error) {
			console.log(error.message)
			const err = error?.toString()?.split(':')[2]?.trim();
			errorNotify(err || 'Something went wrong');
		} finally {
			setSwitchModalToUploadView(false);
			setPublishModal(false);
			setDraftModal(false);
		}
	};

	return (
		<Wrapper>
			<Row>
				<Col>
					<DetailLayoutView
						uploadName='file'
						breadcrumbTitle='Playlists'
						breadcrumbItem='Upload Video'
						breadcrumbRoute={'/playlists'}
						showVideo={true}
						videoUrlFile={videoUrlFile}
						hideImage={false}
					>
						<NewDetailView
							key1={{
								label: 'Video Description',
								placeholder: 'Video Description',
								name: 'description',
								status: 'create',
								type: 'description',
							}}
							key2={{
								label: 'Video Upload Limit (100mb)',
								show: true,
								type: 'upload',
								handleUpload: handleContentUpload,
								status: 'create',
							}}
							key3={playlistCount === 0 ? null : !location?.state?.playlistId ? {
								label: 'Playlist',
								status: 'create',
								name: 'playlist',
								type: 'select',
								options: playlists,
							}: null}
							action={{
								type: 'action',
								actionText: {
									btn2Text: addingToPlaylist ? 'Saving To Draft...' : 'Save To Draft',
									btn3Text: addingToPlaylist ? 'Publishing Your Video...' : 'Publish',
								},
								action: {
									btn2: () => setDraftModal(true),
									btn3: () => setPublishModal(true),
								},
								actionLoading: {
								  btn2Loading: addingToPlaylist,
								  btn3Loading: addingToPlaylist,
								},
							}}
							values={values}
							setValues={setValues}
							setDraftModal={() => setDraftModal(true)}
						/>
					</DetailLayoutView>
				</Col>
			</Row>

			{/* publish publishModal */}
			<Modal isOpen={publishModal} centered={true}>
				{
					switchModalToUploadView ? (
						<ModalBody >
							<UploadProgressWrapper>
								<img src={uploadGif} alt="upload video" />
								<p>Publishing your video. This process may take a few minutes.</p>
							</UploadProgressWrapper>
						</ModalBody>
					) : (
						<>
							<ModalBody>
								<div>
									<p>Are you sure you want to publish this video?</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button color='secondary' onClick={() => setPublishModal(false)}>
									No
								</Button>{' '}
								<Button
									color='primary'
									onClick={() => {
										handleUploadVideo();
									}}
								>
									Yes
								</Button>
							</ModalFooter>
						</>
					)
				}
			</Modal>

			{/* draft publishModal */}
			<Modal isOpen={draftModal} centered={true}>
			  {
					switchModalToUploadView ? (
						<ModalBody >
							<UploadProgressWrapper>
								<img src={uploadGif} alt="upload video" />
								<p>Saving video to draft. This process may take a few minutes.</p>
							</UploadProgressWrapper>
						</ModalBody>
					) : (
						<>
						<ModalBody>
							<p>
								Are you sure you want to save {values.title?.toUpperCase()} to Draft
							</p>
						</ModalBody>
						<ModalFooter>
							<Button color='secondary' onClick={() => setDraftModal(false)}>
								No
							</Button>{' '}
							<Button
								color='primary'
								onClick={() => {
									handleUploadVideo("draft");
								}}
							>
							  Yes
							</Button>
						</ModalFooter>
				</>
				)}
			</Modal>
			<ToastContainer />
		</Wrapper>
	);
});

export const Wrapper = styled.div`
	margin-top: 10vh;
`;

export const UploadProgressWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 14px; 
		margin-top: 15px;
	}
`;

export default UploadVideo;
