import gql from "graphql-tag";

export const CreateAdminAccountMutation = gql`
  mutation addAdminAccount($objects: [adminAccount_insert_input!]!) {
    insert_adminAccount(objects: $objects) {
      returning {
        id
        shortName
        entityName
        admins {
          roleId
        }
      }
    }
  }
`;
