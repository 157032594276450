import gql from 'graphql-tag';

export const MARK_ALL_AS_READ = gql`
  mutation MARK_ALL_AS_READ {
    update_portalNotification(where: {read: {_eq: false}}, _set: {read: true}) {
      affected_rows
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation MARK_AS_READ($id: uuid!) {
    update_portalNotification(where: { id: {_eq: $id}}, _set: {read: true}) {
      affected_rows
    }
  }
`;
