import gql from 'graphql-tag'

export const AddBookMutation = gql`
  mutation addBook($objects: [book_insert_input!]!) {
    insert_book(objects: $objects) {
      returning {
        id
        title
      }
    }
  }
`