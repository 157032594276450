import gql from 'graphql-tag';

export const GetLoungeYoutubeQuery = gql`
	query getLoungeYoutubeQuery($loungeId: uuid!) {
		loungeYoutube(
			where: {loungeId: {_eq: $loungeId}},
		) {
			channelId
			id
			loungeId
			playlistId
			channelName
			lounge {
				id
				name
			}
		}
		loungeYoutube_aggregate(where: {loungeId: {_eq: $loungeId}}) {
			aggregate {
				count
			}
		}
	}
`;

export const GetLoungesYoutubeQuery = gql`
	query getLoungesYoutubeQuery($limit: Int!, $offset: Int!) {
		loungeYoutube(
			order_by: { createdAt: desc }
			limit: $limit
			offset: $offset
		) {
			channelId
			id
			loungeId
			playlistId
			channelName
			lounge {
				id
				name
			}
		}
		loungeYoutube_aggregate {
			aggregate {
				count
			}
		}
	}
`;

export const AddLoungeYoutubeData = gql`
	mutation addLoungeYoutubeData(
		$channelId: String!
		$channelName: String!
		$loungeId: uuid!
		$playlistId: String!
	) {
		insert_loungeYoutube_one(
			object: {
				channelId: $channelId
				channelName: $channelName
				loungeId: $loungeId
				playlistId: $playlistId
			}
		) {
			id
		}
	}
`;

export const UpdateLoungeYoutubeData = gql`
	mutation updateLoungeYoutubeData(
		$channelId: String!
		$channelName: String!
		$loungeId: uuid!
		$playlistId: String!
	) {
		update_loungeYoutube(
			where: { loungeId: { _eq: $loungeId } }
			_set: {
				channelId: $channelId
				channelName: $channelName
				playlistId: $playlistId
			}
		) {
			affected_rows
		}
	}
`;

export const DeleteLoungeYoutube = gql`
	mutation DeleteLoungeYoutube($loungeId: uuid!) {
		delete_loungeYoutube(where: { loungeId: { _eq: $loungeId } }) {
			affected_rows
		}
	}
`;
