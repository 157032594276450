import gql from "graphql-tag";

export const RemoveContentDraftsMutation = gql`
  mutation removeContentDraftsMutation($contentDraftId: uuid!) {
    delete_contentDrafts(where: { id: { _eq: $contentDraftId } }) {
      returning {
        id
        title
      }
    }
  }
`;
