import React from "react";
import { Flex } from "../style";

const DetailEnable = ({
  group,
  setValues,
  values,
  isLounge,
  state,
  setEditValues,
  editValues,
  create,
  editIcon,
  closeIcon,
  statusLabel,
  setStatusLabel,
}) => {
  const showVisibility = (isLounge && !state?.statusState) || group;

  return (
    <Flex borderBottom padding={`20px 5%`}>
      {showVisibility && (
        <Flex>
          <>
            <span>{create ? (group ? "Visible" : "Status") : "Status"}:</span>
            {!create && <span className="owner">{statusLabel}</span>}
          </>
          {editIcon({ ...state, statusState: true })}
        </Flex>
      )}

      {((isLounge && state?.statusState) || (create && isLounge)) && (
        <Flex>
          <div className={`form-group ${state.statusState ? "" : "ml-5"}`}>
            {create ? (
              <select
                className="custom-select"
                onChange={({ target: { value } }) =>
                  setValues({
                    ...values,
                    approvedAt:
                      value === "true" ? new Date().toISOString() : null,
                    isActive: value === "true" ? true : false,
                  })
                }
              >
                <option defaultValue>Enable Group</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            ) : (
              <select
                className="custom-select"
                onChange={({ target: { value } }) => {
                  setStatusLabel(value === "true" ? "Active" : "Inactive");
                  setEditValues({
                    ...editValues,
                    approvedAt:
                      value === "true" ? new Date().toISOString() : null,
                    isActive: value === "true" ? true : false,
                  });
                }}
              >
                <option defaultValue>Enable Group</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            )}
          </div>
          {closeIcon && closeIcon({ ...state, statusState: false })}
        </Flex>
      )}
    </Flex>
  );
};

export default DetailEnable;
