import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { GetObjectionableContents } from 'graphql/query/reviewsQuery';
import { Colors } from 'assets/colors/Colors';
import NewTableList from 'components/TableList/NewTable';
import ImageUi from 'components/Common/ImageUi';
import UITooltip from 'pages/Ui/UITooltip';

const limit = 20;

const Reviews = () => {
	const history = useHistory();
	const [offset, setOffset] = useState(0);

	const reviewType = {
		review: 'Review',
		reviewReply: 'Review Reply',
		chat: 'Chat',
		user: 'User',
	};

	const { data: objectionableData, refetch } = useQuery(
		GetObjectionableContents,
		{
			variables: {
				limit,
				offset,
			},
		}
	);

	const objectionsAggregate =
		objectionableData?.objectionableContent_aggregate?.aggregate?.count;
	const objections = objectionableData?.objectionableContent;
	const [items, setItems] = useState([]);

	useEffect(() => {
		function removeDuplicates(arr = []) {
			let uniques = [];
			let items = [];

			if (arr !== undefined) {
				const res = arr?.forEach((item) => {
					if (item?.review !== null) {
						if (!uniques.includes(item?.review?.id)) {
							uniques.push(item?.review?.id);
							items.push(item);
						}
					} else if (item?.reviewReply !== null) {
						if (!uniques.includes(item?.reviewReply?.id)) {
							uniques.push(item?.reviewReply?.id);
							items.push(item);
						}
					} else if (item?.chat !== null) {
						if (!uniques.includes(item?.chat?.id)) {
							uniques.push(item?.chat?.id);
							items.push(item);
						}
					} else {
						if (!uniques.includes(item?.reportedUser?.id)) {
							uniques.push(item?.reportedUser?.id);
							items.push(item);
						}
					}
				});
			}

			setItems(items);
		}

		removeDuplicates(objections);
	}, [objections]);

	const data = _.map(
		items,
		({ review, reviewReply, chat, planImage, reportedUser }) =>
			review !== null
				? {
						id: review?.id,
						image: (
							<ImageUi
								imageUrl={review?.book?.imageUrl}
								text={review?.book?.title}
							/>
						),
						review: (
							<UITooltip
								message={review?.review}
								target={`review-${review?.id}`}
							/>
						),
						reviewCount: (
							<span className='text-center d-block'>
								{review?.objectionableContents_aggregate?.aggregate?.count}
							</span>
						),
						raisedBy: `@${review?.objectionableContents[0]?.user?.username}`,
						reason: (
							<UITooltip
								message={review?.objectionableContents[0]?.reason}
								target={`reason-${review?.id}`}
							/>
						),
						reviewedBy: `@${review?.user?.username}`,
						type: reviewType?.review,
				  }
				: reviewReply !== null
				? {
						id: reviewReply?.id,
						image: (
							<ImageUi
								imageUrl={reviewReply?.review?.book?.imageUrl}
								text={reviewReply?.review?.book?.title}
							/>
						),
						review: (
							<UITooltip
								message={reviewReply?.reply}
								target={`review-${reviewReply?.id}`}
							/>
						),
						reviewCount: (
							<span className='text-center d-block'>
								{reviewReply?.objectionableContents_aggregate?.aggregate?.count}
							</span>
						),
						raisedBy: `@${reviewReply?.objectionableContents[0]?.user?.username}`,
						reason: (
							<UITooltip
								message={reviewReply?.objectionableContents[0]?.reason}
								target={`reason-${review?.id}`}
							/>
						),
						reviewedBy: `@${reviewReply?.user?.username}`,
						type: reviewType?.reviewReply,
				  }
				: chat !== null
				? {
						id: chat?.id,
						image: <ImageUi imageUrl={planImage} text={'plan image'} />,
						review: (
							<UITooltip
								message={chat?.message}
								target={`chat-${chat?.id}`}
							/>
						),
						reviewCount: (
							<span className='text-center d-block'>
								{chat?.objectionableContentsByChat_aggregate?.aggregate?.count}
							</span>
						),
						raisedBy: `@${chat?.objectionableContentsByChat[0]?.user?.username}`,
						reason: (
							<UITooltip
								message={chat?.objectionableContentsByChat[0]?.reason}
								target={`reason-${chat?.id}`}
							/>
						),
						reviewedBy: `@${chat?.user?.username}`,
						type: reviewType?.chat,
				  }
				: {
						id: reportedUser?.id,
						image: (
							<ImageUi imageUrl={reportedUser?.avatarUrl} text={'user image'} />
						),
						review: (
							<UITooltip
								message={`${reportedUser?.firstName} ${reportedUser?.lastName}`}
								target={`user-${reportedUser?.id}`}
							/>
						),
						reviewCount: (
							<span className='text-center d-block'>
								{
									reportedUser?.reportedUserObjectionCount_aggregate?.aggregate
										?.count
								}
							</span>
						),
						raisedBy: `@${reportedUser?.reportedUserObjectionCount[0]?.user?.username}`,
						reason: (
							<UITooltip
								message={reportedUser?.reportedUserObjectionCount[0]?.reason}
								target={`reason-${reportedUser?.id}`}
							/>
						),
						reviewedBy: `@${reportedUser?.username}`,
						type: reviewType?.user,
				  }
	);

	useEffect(() => {
		if (refetch) {
			refetch();
		}

		// eslint-disable-next-line
	}, []);

	const columns = [
		{ id: 2, title: 'Image' },
		{ id: 3, title: 'Review / Chat Msg / User' },
		{ id: 4, title: 'Objection Count' },
		{ id: 5, title: 'Reported By' },
		{ id: 6, title: 'Reason' },
		{ id: 7, title: 'Owner' },
		{ id: 8, title: 'Type' },
		// { id: 9, title: "Action" },
	];

	return (
		<Wrapper>
			<div>
				<NewTableList
					data={data}
					columns={columns}
					title='Reports'
					totalCount={objectionsAggregate}
					limit={limit}
					setOffset={setOffset}
					paginate
					loading={false}
					actionEvent={() => {}}
					actions={{
						title: 'Hide',
						background: Colors.red200,
						color: Colors.red800,
					}}
					onRow={(record) =>
						history.push(`/review/${record?.id}`, {
							review: record.type === reviewType.review,
							reviewReply: record.type === reviewType.reviewReply,
							chat: record.type === reviewType.chat,
							user: record.type === reviewType.user,
						})
					}
					trStyle={{
						cursor: 'pointer',
					}}
				/>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 50px;
	padding-top: 15vh;
	overflow-y: auto;
`;

// const ActionBtn = styled.button`
//   outline: none;
//   border: none;
//   border-radius: 4px;
//   padding: 5px 20px;
//   background-color: ${Colors.red200};
//   color: ${Colors.red800};
//   cursor: pointer;
// `;

export default Reviews;
