import React, { useState } from "react";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useMutation, useQuery } from "@apollo/react-hooks";
import EditUser from "components/EditUser";
import { DeactivateAdminMutation, DeleteAdminMutation } from "graphql/delete/delete_admin";
import { AdminUserQuery } from "graphql/query/adminUser";
import { errorNotify, successNotify } from "utils/toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminDetail = () => {
  const [deactivateAdmin, { loading: deactivateLoading }] = useMutation(
    DeactivateAdminMutation
  );
  const [deleteAdmin, { loading: deleting }] = useMutation(
    DeleteAdminMutation
  );
  const { adminId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteAdminModalOpen, setIsDeleteAdminModalOpen] = useState(false);
  const history = useHistory();
  const { data: adminData } = useQuery(AdminUserQuery, {
    variables: {
      adminId: adminId ? adminId : null,
    },
  });

  let admin = {
    ...adminData?.admin[0],
    role: adminData?.admin[0]?.role?.name,
  };

  const handleDeactivateAdmin = async () => {
    try {
      if (adminId) {
        await deactivateAdmin({
          variables: {
            adminId,
            set: {
              suspended: true,
            },
          },
        });
        setIsOpen(false);
        const message = `${admin?.user?.username} suspended successfully`;
        successNotify(message);
      }
    } catch (error) {
      const err = error?.toString()?.split(":")[2]?.trim();
      errorNotify(err || "Something went wrong, try again");
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      if (adminId) {
        await deleteAdmin({
          variables: {
            adminId
          }
        });
        setIsDeleteAdminModalOpen(false);
        const message = `${admin?.user?.username} deleted successfully`;
        successNotify(message);
        history.goBack();
      }
    } catch (error) {
      const err = error?.toString()?.split(":")[2]?.trim();
      errorNotify(err || "Something went wrong, try again");
    }
  };

  return (
    <Wrapper>
      <Title>Admin Detail</Title>
      <EditUser
        user={admin?.user}
        role={admin?.role}
        isSuspended={admin?.suspended}
        handleConfirmUser={() => {}}
        handleUnConfirmUser={() => {}}
        deleting={deleting}
        onClick={() => setIsOpen(true)}
        setIsDeleteAdminModalOpen={() => setIsDeleteAdminModalOpen(true)}
        hideSelect={true}
        loading={deactivateLoading}
        btn1={{ label: "Deactivate Admin", color: "danger" }}
        btn2={{ label: "Delete Admin", color: "danger" }}
      />
      <ToastContainer />

      <Modal isOpen={isOpen}>
        <ModalBody style={{ position: "relative" }}>
          <span
            onClick={() => setIsOpen(false)}
            style={{
              fontSize: 25,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <p>Are you sure you want to deactivate this user?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleDeactivateAdmin}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isDeleteAdminModalOpen}>
        <ModalBody style={{ position: "relative" }}>
          <span
            onClick={() => setIsDeleteAdminModalOpen(false)}
            style={{
              fontSize: 25,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <p>Are you sure you want to delete this admin?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleDeleteAdmin}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsDeleteAdminModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: 10vh;
`;

const Title = styled.h1`
  margin-top: 2%;
`;

export default AdminDetail;
