import React from "react";

const ImageUi = ({ imageUrl, text, width = 45, height = 45 }) => {
  return (
    <div>
      {imageUrl ? (
        <img
          src={imageUrl}
          width={width}
          height={height}
          className="rounded-circle"
          alt="profile"
        />
      ) : (
        <div
          className="avatar-sm"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span
            style={{
              width,
              height,
            }}
            className={
              "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
            }
          >
            {text ? text[0]?.toUpperCase() : ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default ImageUi;
