import { Colors } from "assets/colors/Colors";
import React from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Col, Media, Spinner } from "reactstrap";

const CountCard = ({ loading, count, title, col = "3", icon = "user", route="" }) => {
  const history = useHistory();

  return (
    <Col md={col}>
      <Card
        className="mini-stats-wid"
        onClick={() => history.push(route)}
        style={{ cursor: "pointer" }}
      >
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted font-weight-medium">{title}</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                {loading ? (
                  <Spinner type="grow" color="primary" />
                ) : count || typeof count === "number" ? (
                  <h4
                    className="ml-2 mb-0"
                    style={{
                      fontSize: 20,
                      fontWeight: "bolder",
                    }}
                  >
                    {count}
                  </h4>
                ) : (
                  <span>No Data</span>
                )}
              </div>
            </Media>
            <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
              <span
                className="avatar-title"
                style={{ backgroundColor: Colors.dyshPurple }}
              >
                <i className={`fas fa-${icon} font-size-24`}></i>
              </span>
            </div>
          </Media>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CountCard;
