import gql from "graphql-tag";

export const DeleteAdminAccountMutation = gql`
  mutation deleteAdminAccount($adminAccountId: uuid!) {
    delete_admin(where: { id: { _eq: $adminAccountId } }) {
      returning {
        id
        entityName
      }
    }
  }
`;

export const DeleteAdminAccountByNameMutation = gql`
  mutation deleteAdminAccount($adminAccountName: String) {
    delete_adminAccount(where: { entityName: { _eq: $adminAccountName } }) {
      returning {
        id
        entityName
      }
    }
  }
`;
