import gql from "graphql-tag";

export const UsersQuery = gql`
  query MyQuery($search: user_bool_exp, $limit: Int, $offset: Int) {
    user(
      where: $search
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      id
      firstName
      lastName
      email
      username
      avatarUrl
      dateOfBirth
      isEmailConfirmed
      createdAt
    }

    user_aggregate(where: $search) {
      aggregate {
        count
      }
    }
  }
`;

export const UsersForLast30DaysQuery = gql`
  query MyQuery(
    $startDate: timestamptz
    $endDate: timestamptz
    $limit: Int
    $offset: Int
  ) {
    user(
      where: {
        _and: [
          { createdAt: { _gte: $startDate } }
          { createdAt: { _lte: $endDate } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      id
      firstName
      lastName
      email
      username
      avatarUrl
      dateOfBirth
      isEmailConfirmed
      createdAt
    }

    user_aggregate {
      aggregate {
        count
      }
    }
  }
`;
