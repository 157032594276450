import { Colors } from "assets/colors/Colors";
import React from "react";
import { Input } from "reactstrap";
import { Flex } from "../style";

const DetailOwner = ({
  state,
  create,
  owner,
  editIcon,
  closeIcon,
  isContent,
  setValues,
  values,
  setEditValues,
  editValues,
  placeholder,
  isLounge,
  edit,
}) => {
  const titlePlaceholder = placeholder && placeholder["author"];

  return (
    <>
      <Flex borderBottom padding={`20px 5%`}>
        {!state.ownerState && !create && (
          <div style={{ width: "100%" }}>
            <span>{Object.keys(owner)[0]}:</span>
            {Object.keys(owner)[0] === "owner" && (
              <span className="owner">
                {owner?.owner}{" "}
                {!isLounge && editIcon({ ...state, ownerState: true })}
              </span>
            )}
            {Object.keys(owner)[0] === "author" && (
              <span className="owner">
                {owner?.author} {editIcon({ ...state, ownerState: true })}
              </span>
            )}
          </div>
        )}

        {isLounge && create && (
          <div style={{ width: "100%" }}>
            <span>{Object.keys(owner)[0]}:</span>
            {Object.keys(owner)[0] === "owner" && (
              <span className="owner">N/A</span>
            )}
          </div>
        )}

        {!state.ownerState && create && isContent && (
          <Flex direction="column" width="100%">
            <p>
              <span style={{ color: Colors.red800 }}>*</span> <span>Author</span>
            </p>
            <Input
              id="name"
              name="name"
              type="text"
              style={{ width: "100%" }}
              className="form-control"
              placeholder={
                titlePlaceholder ? titlePlaceholder : "Enter Content Author..."
              }
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  name: value,
                  author: value,
                });
              }}
            />
            {closeIcon({ ...state, ownerState: false })}
          </Flex>
        )}

        {state.ownerState && edit && isContent && (
          <>
            <Input
              id="name"
              type="text"
              value={editValues.author}
              className="form-control"
              placeholder={
                titlePlaceholder ? titlePlaceholder : "Enter Content Author..."
              }
              onChange={({ target: { value } }) => {
                setEditValues({
                  ...editValues,
                  author: value,
                });
              }}
              style={{ width: "100%" }}
            />
            {closeIcon({ ...state, ownerState: false })}
          </>
        )}
      </Flex>
    </>
  );
};

export default DetailOwner;
