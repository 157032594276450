import React, { useContext, useLayoutEffect } from 'react';
import _ from 'lodash';
import VideoCardView from 'components/VideoCardView';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { useQuery } from "@apollo/react-hooks";
import { PrivateVideoPlaylistsQuery } from 'graphql/query/video';
import { LoungePortalContext } from 'context/LoungePortalContext';

const VideoPlaylists = React.memo(() => {
	const { loungeId } = useContext(LoungePortalContext);
  const { data, loading, refetch } = useQuery(PrivateVideoPlaylistsQuery, {
		variables: {
			loungeId
		},
		fetchPolicy: 'cache-and-network',
		skip: !loungeId
	});

	useLayoutEffect(() => {
		refetch();
	}, [loungeId])

	return (
		<Wrapper>
			<Row>
				<Col>
					<VideoCardView
						data={data?.privateVideoPlaylist}
						loading={loading}
						createRoute="/upload-video"
					/>
				</Col>
			</Row>
		</Wrapper>
	);
});

const Wrapper = styled.div`
	height: 100vh;
	overflow-y: auto;
	background-color: white;
	padding: 2% 3%;
	padding-top: calc(10vh + 2%);

	.border-dash {
		display: block;
		width: 100%;
		height: 250px;
		border-radius: 8px;
		border: 5px dashed #aaaaaa;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.plus {
			font-size: 35px;
			color: #aaaaaa;
		}

		.create {
			font-size: 30;
			color: #aaaaaa;
			font-weight: bolder;
		}
	}

	.input-upload {
		display: none;
	}

	.content {
		height: 250px;
		border: 1px solid #eeeeee;

		p {
			margin: 0;
		}

		.content-footer {
			border-top: 2px solid #aaaaaa;
			padding: 15px 0;
		}
	}
`;

export default VideoPlaylists;
