/* eslint-disable no-lone-blocks */
import React from 'react';
import { Empty } from 'antd';
import { Tbody, Thead } from 'react-super-responsive-table';
import { Card, CardBody, Button } from 'reactstrap';
import TablePagination from './Pagination';
import styled from 'styled-components';
import CustomSpinner from 'ui/CustomSpinner';
import { textTruncate } from 'utils/textTruncate';
import { Colors } from 'assets/colors/Colors';
import { useHistory } from 'react-router-dom';
import TableSearchAndCreate from './TableSearchAndCreate';

const NewTableList = ({
	data = [],
	columns = [],
	actionBtn,
	title = '',
	onRow,
	totalCount = 1,
	setOffset,
	limit,
	paginate,
	emptyMsg = 'No Data',
	loading = false,
	isSeeAll = false,
	goToSeeAll,
	trStyle = {},
	onChange,
	placeholder,
	btnLabel,
	route,
	search,
	isFilter,
	setQuery,
	filterList,
	hideSearch = false,
}) => {
	const history = useHistory();

	return (
		<Container>
			<Card>
				<CardBody>
					{title && (
						<h2 className='mb-4 d-flex justify-content-between align-items-center'>
							{textTruncate(title, 100)}{' '}
							{isSeeAll && (
								<p
									className='text-right font-size-16 see_all '
									onClick={() => history.push(goToSeeAll ?? '')}
								>
									See All
								</p>
							)}
						</h2>
					)}
					{search && (
						<div className=''>
							<TableSearchAndCreate
								inputStyle={{
									background: '#F1F1F4',
									maxWidth: 500,
									width: '100%',
								}}
								onChange={onChange}
								placeholder={placeholder}
								btnLabel={btnLabel}
								route={route ? route : null}
								isFilter={isFilter}
								hideSearch={hideSearch}
								setQuery={setQuery}
								filterList={filterList}
							/>
						</div>
					)}
					<div className='table-responsive' style={{ position: 'relative' }}>
						<table
							className='table table-centered table-nowrap mb-0'
							style={{ minHeight: 150 }}
						>
							<Thead className='thead-light'>
								<tr>
									{columns?.map((heading) => (
										<th style={{ maxWidth: 150 }} key={heading?.id}>
											{heading?.title}
										</th>
									))}
								</tr>
							</Thead>
							{data?.length <= 0 ? (
								loading ? (
									<LoadingDiv>
										<CustomSpinner />
									</LoadingDiv>
								) : (
									<Tbody style={{ position: 'relateive' }}>
										<tr
											style={{
												position: 'absolute',
												left: '50%',
												top: '70%',
												transform: 'translate(-50%, -50%)',
											}}
										>
											<td style={{ width: '100%' }}>
												{<Empty description={<span>{emptyMsg}</span>} />}
											</td>
										</tr>
									</Tbody>
								)
							) : loading ? (
								<LoadingDiv>
									<CustomSpinner />
								</LoadingDiv>
							) : (
								<Tbody>
									{data?.map((list, i) => {
										const values = Object.values(list);
										return (
											<tr onClick={() => onRow && onRow(list)} style={trStyle}>
												{values?.slice(1).map((value) => (
													<td>{value}</td>
												))}
											</tr>
										);
									})}
								</Tbody>
							)}
						</table>
					</div>
					{data?.length > 0 && paginate && (
						<div className='mt-3 pr-3'>
							<TablePagination
								totalCount={totalCount}
								setOffset={setOffset}
								limit={limit}
							/>
						</div>
					)}
					{actionBtn}
				</CardBody>
			</Card>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	.see_all {
		color: ${Colors.dyshPurple};
		cursor: pointer;
	}
`;

const LoadingDiv = styled.div`
	width: 100%;
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export default NewTableList;
