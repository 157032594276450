import React from "react";
import { useHistory } from "react-router";

const truncateText = (text) => {
  return text?.length > 50 ? `${text.slice(0, 100)}...` : text;
};

const LoungeItem = ({ routeName, item }) => {
  const history = useHistory();

  return (
    <div className='col-sm-12 col-md-6 col-lg-3'>
      <div className='card'>
        <div className='card-body p-0 m-0 content d-flex flex-column justify-content-between'>
          <div className='p-3'>
            <div className='text-right w-100'>
              <i className='bx bx-dots-horizontal bx-md menu'></i>
            </div>
            <div className='content-body' onClick={() => history.push(`${routeName}/${item?.id}`)}>
              <h3 className='text-truncate'>{item?.name}</h3>
              <p className=''>{truncateText(item?.description)}</p>
            </div>
          </div>
          <div className='content-footer d-flex justify-content-between px-3'>
            <p>
              <i className='bx bx-user-circle'></i> {item?.user}
            </p>
            <p>
              <i className='bx bx-book'></i> {item?.books}
            </p>
            <p>
              <i className='bx bx-user'></i> {item?.groups}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoungeItem;
