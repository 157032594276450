import gql from "graphql-tag";

export const UpadatePlanMutation = gql`
  mutation UpdatePlan(
    $set: plan_set_input
    # $planId: uuid!
    $where: plan_bool_exp!
  ) {
    update_plan(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

