class ErrorHandler {
  static getUserFriendlyError(error, msg) {
    let otherErrors = error?.split(":")[0].trim();
    let err = error?.split(":")[1].trim();
    err = otherErrors === "TypeError" ? otherErrors : err;

    switch (err) {
      case "GraphQL error":
        return "Oops! Something went wrong!\n Help us improve your experience by sending an error report, and we will deal with your issue swiftly. Thank You";

      case "Network error":
        return "A network error occurred!. Please check that you have a working internet connection";

      case "TypeError":
        return "Oops! Something went wrong!\n Help us improve your experience by sending an error report, and we will deal with your issue swiftly. Thank You";

      default:
        return error && (msg || "Oops! Something went wrong!");
    }
  }

  static getUserDataNotFoundError() {
    return "Oops! We can't seem to find your data, not to worry, this is probably a temporary issue. Please try logging in again in a few minutes.";
  }
}

export default ErrorHandler;
