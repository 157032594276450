/* eslint-disable no-lone-blocks */
import React from "react";
import { Empty } from "antd";
import { Tbody, Thead } from "react-super-responsive-table";
import moment from "moment";
import { Badge, Card, CardBody } from "reactstrap";
import TablePagination from "./Pagination";
import ProfileTableImage from "components/Common/ProfileTableImage";
import styled from "styled-components";
import CustomSpinner from "ui/CustomSpinner";
import { textTruncate } from "utils/textTruncate";
import { Colors } from "assets/colors/Colors";
import { useHistory } from "react-router-dom";

const TableList = ({
  data = [],
  columns = [],
  title = "",
  onRow,
  totalCount = 1,
  setOffset,
  limit,
  paginate,
  emptyMsg = "No Data",
  loading = false,
  actionEvent,
  actions,
  isSeeAll = false,
  goToSeeAll,
  trStyle = {},
}) => {
  const history = useHistory();

  return (
    <Container>
      <Card>
        <CardBody>
          <h2 className="mb-4">{textTruncate(title, 100)}</h2>
          <div className="table-responsive" style={{ position: "relative" }}>
            <table
              className="table table-centered table-nowrap mb-0"
              style={{ minHeight: 150 }}
            >
              <Thead className="thead-light">
                <tr>
                  {columns?.map((heading) => (
                    <th style={{ maxWidth: 150 }} key={heading?.id}>
                      {heading?.title}
                    </th>
                  ))}
                </tr>
              </Thead>
              {data?.length <= 0 ? (
                loading ? (
                  <LoadingDiv>
                    <CustomSpinner />
                  </LoadingDiv>
                ) : (
                  <Tbody style={{ position: "relateive" }}>
                    <tr
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "70%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <td style={{ width: "100%" }}>
                        {<Empty description={<span>{emptyMsg}</span>} />}
                      </td>
                    </tr>
                  </Tbody>
                )
              ) : loading ? (
                <LoadingDiv>
                  <CustomSpinner />
                </LoadingDiv>
              ) : (
                <Tbody>
                  {data?.map((list) => {
                    const keys = Object.keys(list);

                    return (
                      <tr
                        onClick={() => onRow && onRow(list)}
                        style={{
                          ...trStyle,
                          maxHeight: 34,
                          textAlign: "center",
                        }}
                      >
                        {keys.map((key, i) => (
                          <td
                            style={{ maxWidth: 170, overflow: "hidden" }}
                            title={
                              key !== "date" &&
                              key !== "image" &&
                              key !== "badge" &&
                              list[key]
                            }
                          >
                            {key === "image" ? (
                              <ProfileTableImage user={list} />
                            ) : key === "date" ? (
                              moment(list[key]).fromNow()
                            ) : key === "badge" ? (
                              list[key] ? (
                                <i className="bx bx-check-double bx-sm text-success"></i>
                              ) : (
                                <i className="bx bx-x bx-sm text-danger"></i>
                              )
                            ) : key === "key" ? (
                              <div></div>
                            ) : key === "visible" ? (
                              <Badge
                                style={{
                                  backgroundColor:
                                    list?.visible === "Private"
                                      ? "orangered"
                                      : "green",
                                  color: "white",
                                }}
                              >
                                {list?.visible}
                              </Badge>
                            ) : key === "receipt" ? (
                              <a
                                href={list[key]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Receipt
                              </a>
                            ) : (
                              <span style={{ textAlign: "center" }}>
                                {textTruncate(list[key])}
                              </span>
                            )}
                          </td>
                        ))}
                        {actionEvent && (
                          <td>
                            <ActionBtn
                              backgroundColor={actions?.background}
                              color={actions?.color}
                              onClick={() => actionEvent(list)}
                            >
                              {actions?.title}
                            </ActionBtn>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </Tbody>
              )}
            </table>
            {isSeeAll && (
              <p
                className="text-right mt-2 see_all"
                onClick={() => history.push(goToSeeAll ?? "")}
              >
                See All
              </p>
            )}
          </div>
          {data?.length > 0 && paginate && (
            <div className="float-right mt-3 pr-3">
              <TablePagination
                totalCount={totalCount}
                setOffset={setOffset}
                limit={limit}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  .see_all {
    color: ${Colors.dyshPurple};
    cursor: pointer;
  }
`;

const LoadingDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ActionBtn = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#eeeeee"};
  color: ${({ color }) => color && color};
  cursor: pointer;
`;

export default TableList;
