import gql from "graphql-tag";

export const UpdateBookGenreMutation = gql`
  mutation updateContentGenre($where: bookGenre_bool_exp!, $objects: bookGenre_set_input) {
    update_bookGenre(
      where: $where
      _set: $objects
    ) {
      returning {
        id
      }
    }
  }
`;
