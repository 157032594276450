/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Col, Container, Row, Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import TableList from "components/TableList";
import NewTableList from "components/TableList/NewTable";
import { LoungePortalContext } from "context/LoungePortalContext";
import { UserContext } from "context/UserContext";
import {
  LoungePortalBookAggregate,
  LoungePortalContentDraftsAggregate,
} from "graphql/books";
import { LatestTransactionQuery } from "graphql/query/latestTransactionQuery";
import { LoungeOwnerMostActiveGroup } from "graphql/query/mostActiveGroupQuery";
import { GetOwnedLounges } from "graphql/query/ownedLounges";
import CountCard from "./commons/CountCard";
import { latestTransactionFile } from "./data/latestTransactionFile";
import { LoungePortalLoungeUser } from "./data/loungeWithMostUsersFile";
import { mostActiveGroup } from "./data/mostActiveGroup";
import { useAdminQueries } from "./queries/adminDashboardQueries";
import Onboarding from "pages/Onboarding";
import { defaultImage } from "constant/defaultImage";
import { UpdateAdminMutation } from "graphql/update/update_admin";

const LoungeOwnerDashboard = () => {
  const { admin, isAdmin, adminAccountId } = useContext(UserContext);
  const { loungeId } = useContext(LoungePortalContext);

  const [updateAdmin, { error }] = useMutation(UpdateAdminMutation);

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: loungeData,
    loading: loungeLoading,
    error: loungeError,
    refetch,
  } = useQuery(GetOwnedLounges, {
    variables: {
      search: adminAccountId
        ? { adminAccount: { id: { _eq: adminAccountId } } }
        : {},
      // search: user?.id ? { userId: { _eq: user?.id ? user?.id : "" } } : {},
    },
  });

  const lounge = loungeData?.lounge[0];
  const loungeUsers = lounge?.loungeUsers;

  const toggle = async () => {
    setIsOpen(!isOpen);
    await onBoardedCallback();
  };

  const {
    data: userPurchaseData,
    loading: userPurchaseLoading,
    error: userPurchaseError,
    refetch: userPurchaseRefetch,
  } = useQuery(LatestTransactionQuery, {
    variables: {
      where: loungeId
        ? {
            book: {
              lounges: {
                lounge: adminAccountId
                  ? { adminAccount: { id: { _eq: adminAccountId } } }
                  : {},
              },
            },
          }
        : {},
    },
  });

  const {
    data: mostActiveGroupData,
    loading: loungeOwnerMostActiveLoading,
    error: mostActiveGroupError,
    networkStatus,
  } = useQuery(LoungeOwnerMostActiveGroup, {
    variables: {
      where: {
        lounges: {
          lounge: adminAccountId
            ? { adminAccount: { id: { _eq: adminAccountId } } }
            : {},
        },
      },
    },
  });

  const {
    data: contentDraftAggregate,
    loading: contentDraftAggreagateLoading,
    refetch: contentDraftsAggregateRefetch,
  } = useQuery(LoungePortalContentDraftsAggregate, {
    variables: {
      loungeId: loungeId ? loungeId : null,
    },
  });

  const {
    data: bookAggregate,
    loading: bookAggregateLoading,
    refetch: bookAggregateRefetch,
  } = useQuery(LoungePortalBookAggregate, {
    variables: {
      loungeId: loungeId ? loungeId : null,
    },
  });

  const onBoardedCallback = async () => {
    try {
      await updateAdmin({
        variables: {
          where: {
            adminAccountId: {
              _eq: adminAccountId,
            },
          },
          set: {
            onBoarded: true,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userPurchaseRefetch) {
      userPurchaseRefetch();
    }
    if (bookAggregateRefetch) {
      bookAggregateRefetch();
    }
    if (contentDraftsAggregateRefetch) {
      contentDraftsAggregateRefetch();
    }

    if (admin?.onBoarded !== undefined && !admin?.onBoarded) {
      setIsOpen(true);
    }
    // onBoardedCallback();

    // eslint-disable-next-line
  }, [admin?.onBoarded]);

  const userCount = lounge?.loungeUsers_aggregate?.aggregate?.count;
  const groupCount = lounge?.plans_aggregate?.aggregate?.count;
  const bookCount = bookAggregate?.book_aggregate?.aggregate?.count;
  const contentDraftCount =
    contentDraftAggregate?.contentDrafts_aggregate?.aggregate?.count;
  const contentCount = bookCount + contentDraftCount;

  const {
    loungeOwnerData: {
      loungeOwnerMostActiveGroupData,
      loungeOwnerLatestTransaction,
    },
  } = useAdminQueries();

  const { data: loungeTableList, columns: loungeColumn } =
    LoungePortalLoungeUser(loungeUsers);
  const { data: mostActiveGroupTableList, columns: mostActiveGroupColumns } =
    mostActiveGroup(mostActiveGroupData?.plan);
  const {
    data: latestTransactionTableList,
    columns: latestTransactionColumns,
  } = latestTransactionFile(userPurchaseData?.userPurchase);

  const countCards = [
    {
      id: 1,
      title: "User",
      count: userCount,
      loading: loungeLoading,
      icon: "user",
      col: "4",
      route: isAdmin ? "/users" : "/lounge-user",
    },
    {
      id: 2,
      title: "Group",
      count: groupCount,
      loading: loungeLoading,
      icon: "object-group",
      col: "4",
      route: isAdmin ? "/groups" : "/group-owner",
    },
    {
      id: 3,
      title: "Content",
      count: contentCount,
      loading: contentDraftAggreagateLoading || bookAggregateLoading,
      icon: "book",
      col: "4",
      route: isAdmin ? "/contents" : "/content-owner",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <h1 className="mb-4">{lounge?.name}</h1>
          </Col>
        </Row>
        <Row>
          {countCards?.map((props) => (
            <CountCard {...props} />
          ))}
        </Row>

        <Row>
          <Col>
            <NewTableList
              columns={loungeColumn}
              data={loungeTableList}
              title="New Users"
              isSeeAll={true}
              goToSeeAll="/lounge-user"
            />
          </Col>
          <Col>
            <NewTableList
              columns={mostActiveGroupColumns}
              data={mostActiveGroupTableList?.slice(0, 3)}
              title="Active Groups"
              isSeeAll={true}
              goToSeeAll="/group-owner"
            />
          </Col>
        </Row>
        {loungeId && (
          <TableList
            columns={latestTransactionColumns}
            data={latestTransactionTableList}
            title="Recent Transactions"
            loading={userPurchaseLoading}
          />
        )}
      </Container>

      <CustomModal
        isOpen={!admin?.onBoarded && isOpen}
        toggle={toggle}
        className="p-0"
        style={{ overflow: "hidden", padding: 0 }}
        size="xl"
      >
        <ModalBody className="body" style={{ padding: 0 }}>
          <Onboarding toggle={toggle} />
        </ModalBody>
      </CustomModal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: calc(10vh + 3%);
  padding-bottom: 5%;
  height: 100vh;
  overflow-y: auto;

  .body {
    padding: 0px !important;
  }
`;

export const CustomModal = styled(Modal)`
  .modal-content {
    background-color: transparent !important;
    border: none;
    border-radius: 0;
  }
`;

export default LoungeOwnerDashboard;
