import gql from 'graphql-tag'

export const GetAUserQuery = gql`
  query getAUserQuery($userId: uuid){
    user(where: {id: {_eq: $userId}}){
      id
      username
      firstName
      lastName
      email
      avatarUrl
      isEmailConfirmed
      blocked
      dateOfBirth
      isPublic
      createdAt
      plans_aggregate{
        aggregate{
          count
        }
      }
      plans{
        id
        name
        createdAt
        duration
        lounges{
          lounge{
            id
            description
            imageUrl
            ageRestriction
            
          }
        }
        lounges_aggregate{
          aggregate{
            count
          }
        }
        user{
          id
          username
        }
      }
    }
  }
`