import React from "react";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { DetailLayoutView } from "components/DetailView";
import NewDetailView from "components/DetailView/NewDetailScreen";
import CustomSpinner from "ui/CustomSpinner";
import { LoungeUserQuery } from "graphql/query/loungeUsers";

const defaultImg =
  "https://readapp.ams3.digitaloceanspaces.com/example/lounge/unisex-avatar.png";

const LoungeUserDetail = () => {
  const { loungeUserId } = useParams();

  const { data, loading } = useQuery(LoungeUserQuery, {
    variables: {
      loungeUserId,
    },
  });

  const loungeUser = data?.loungeUser[0]?.user;
  const image = loungeUser?.avatarUrl ? loungeUser?.avatarUrl : defaultImg;

  return (
    <Wrapper>
      {loading ? (
        <CustomSpinner />
      ) : (
        <DetailLayoutView
          image={image}
          group
          breadcrumbTitle="Lounge User"
          breadcrumbItem="Lounge User Detail"
          breadcrumbRoute={"/lounge-user"}
        >
          <NewDetailView
            key0={{
              label: "Full Name",
              status: "edit",
              type: "text",
              value: `${loungeUser?.firstName} ${loungeUser?.lastName}`,
              style: {
                fontSize: 16,
              },
            }}
            key1={{
              label: "Username",
              status: "edit",
              type: "text",
              value: loungeUser?.username,
            }}
            key2={{
              label: "Email",
              status: "edit",
              type: "text",
              value: loungeUser?.email,
            }}
            key3={{
              label: "Created At",
              type: "text",
              value: moment(loungeUser?.createdAt).fromNow(),
            }}
          />
        </DetailLayoutView>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 10vh);
  margin-top: 10vh;
  overflow-y: auto;
`;

export default LoungeUserDetail;
