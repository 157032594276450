import gql from 'graphql-tag';

export const DeleteMessageForEveryone = gql`
	mutation deleteMessageForEveryone($chatId: uuid!) {
		update_chat(where: { id: { _eq: $chatId } }, _set: {deleted: true, policyViolated: true}) {
			returning {
				id
				userId
				planId
				createdAt
				message
				deleted
			}
		}
	}
`;

export const UpdateReviewMutation = gql`
	mutation updateReview($reviewId: uuid, $set: reviews_set_input) {
		update_reviews(where: { id: { _eq: $reviewId } }, _set: $set) {
			returning {
				id
				review
				deleted
			}
		}
	}
`;

export const UpdateReviewReplyMutation = gql`
	mutation updateReviewReply(
		$reviewReplyId: uuid
		$set: reviewReplies_set_input
	) {
		update_reviewReplies(where: { id: { _eq: $reviewReplyId } }, _set: $set) {
			returning {
				id
				reply
				deleted
			}
		}
	}
`;
