/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import ErrorHandler from "utils/CatchError";
import { UserContext } from "context/UserContext";
import Pages500 from "pages/Utility/pages-500";
import { GroupQuery } from "graphql/query/groups";
import NewTableList from "components/TableList/NewTable";
import ImageUi from "components/Common/ImageUi";
import Breadcrumbs from "components/Common/Breadcrumb";
import UITooltip from "pages/Ui/UITooltip";
import { textTruncate } from "utils/textTruncate";

const limit = 12;

const Groups = () => {
  const { getUserDetailsFromLocalStorage } = useContext(UserContext);

  const history = useHistory();
  const [query, setQuery] = useState("");
  const [loungeQuery, setLoungeQuery] = useState("");
  const [offset, setOffset] = useState(0);

  const {
    data: groupData,
    loading: groupLoadingState,
    error: loungeError,
    refetch,
  } = useQuery(GroupQuery, {
    variables: {
      search: {
        _and: [
          {
            _or: [
              { name: { _ilike: `%${query}%` } },
              { description: { _ilike: `%${query}%` } },
            ],
          },
          loungeQuery
            ? { lounges: { lounge: { name: { _eq: loungeQuery } } } }
            : {},
        ],
      },
      limit: limit,
      offset: offset,
    },
  });

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    getUserDetailsFromLocalStorage();
  }, [refetch]);

  const groups = groupData?.plan;
  const groupAggregate = groupData?.plan_aggregate?.aggregate?.count;

  const handleQuery = ({ target: { value } }) => {
    setQuery(value);
  };

  const error = ErrorHandler.getUserFriendlyError(loungeError?.toString());

  if (loungeError?.toString()) {
    return <Pages500 error={error} />;
  }

  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "Group Name" },
    { id: 3, title: "Group Description" },
    { id: 4, title: "Group Owner" },
    { id: 5, title: "Group Owner Email"},
    { id: 6, title: "Group Visibility" },
    { id: 7, title: "Status"},
    { id: 8, title: "Date Created"},
  ];

  const data = _.map(groups, (group) => ({
    id: group?.id,
    image: (
      <ImageUi imageUrl={group?.books[0]?.book?.imageUrl} text={group?.name} />
    ),
    name: textTruncate(group?.name, 20),
    description: Boolean(group?.description) ? (
      <UITooltip
        message={group?.description}
        target={`group-description-${group?.id}`}
      />
    ) : (
      "N/A"
    ),
    owner: `${group?.user?.firstName} ${group?.user?.lastName}`,
    ownerEmail: textTruncate(group?.user?.email, 20),
    visible: group?.private ? "Private" : "Public",
    status: group?.deleted ? "Deleted" : "Live",
    date: moment(group?.createdAt).fromNow(),
  }));

  return (
    <Wrapper>
      <Breadcrumbs title="Group" breadcrumbItem="Group" />
      <NewTableList
        onChange={handleQuery}
        placeholder="Search groups by name or description..."
        btnLabel="Create Group"
        route="/create-group"
        isFilter={true}
        setQuery={setLoungeQuery}
        data={data}
        columns={columns}
        // title="Groups"
        totalCount={groupAggregate}
        limit={limit}
        paginate
        setOffset={setOffset}
        onRow={(record) => history.push(`/groups/${record?.id}`)}
        loading={groupLoadingState}
        trStyle={{
          cursor: "pointer",
        }}
        search
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: calc(10vh + 3%);
  overflow-y: auto;
`;

export default Groups;
