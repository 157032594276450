import _ from "lodash";
import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";

import { Flex } from "../style";

const DetailGenre = ({
  values,
  setValues,
  data,
  setGenreType,
  setLoungeQuery,
  queryLounge,
  contentObj,
  state,
  setState,
  create,
  editIcon,
  closeIcon,
  editValues,
  setEditValues,
  edit,
}) => {
  let genres = data["genres"];

  genres = _.map(genres, (genre) => ({
    label: genre?.title,
    value: genre?.id,
  }));

  return (
    <Flex borderBottom padding={`20px 5%`}>
      {!create && <span>Genre: {contentObj?.genre?.title ?? "N/A"}</span>}

      {create && (
        <FormGroup className="select2-container w-100 row">
          <Label className="col-2 mr-3">Genre: </Label>
          <Col>
            <Select
              options={genres}
              style={{ width: "100% !important" }}
              onChange={({ value, label }) => {
                setValues({
                  ...values,
                  genreQuery: value,
                });
                setGenreType(label);
              }}
            />
          </Col>
        </FormGroup>
      )}

      {/* <Flex width='100%'>
        {!create && edit && state?.genreState && (
          <FormGroup className='select2-container w-100 row'>
            <Label className='col-2'>Genre: </Label>
            <Col>
              <Select
                options={genres}
                style={{ width: "100% !important" }}
                onChange={({ value }) =>
                  setEditValues({
                    ...editValues,
                    genreQuery: value,
                  })
                }
              />
            </Col>
          </FormGroup>
        )}
        {state?.genreState && closeIcon({ ...state, genreState: false })}
      </Flex> */}
    </Flex>
  );
};

export default DetailGenre;
