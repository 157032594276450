import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "react-apollo";
import moment from "moment";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { QueryALoungeOwnerApplication } from "../../graphql/query/queryAloungeOwnerApplication";
import CustomSpinner from "../../ui/CustomSpinner";
import { DetailLayoutView } from "components/DetailView";
import Pages500 from "pages/Utility/pages-500";
import ErrorHandler from "utils/CatchError";
import { ToastContainer } from "react-toastify";
import { errorNotify, successNotify } from "utils/toaster";
import NewDetailView from "components/DetailView/NewDetailScreen";
import { AddLoungeMutation } from "graphql/insert/insert_lounge";
import { defaultImage } from "constant/defaultImage";
import { GetAllLoungeOwnerApplication } from "graphql/query/loungeOwnerApplication";
import { UpdateLoungeOwnerApplicationMutation } from "graphql/update/update_lounge_owner_application";
import { FlexDiv } from "components/DetailView/style";
import { Roles } from "config";
import { GetRoles } from "graphql/query/getRoles";
import { CreateAdminAccountMutation } from "graphql/insert/insert_admin_account";
import { DeleteAdminAccountMutation } from "graphql/delete/delete_admin_account";
import { Config } from "config";
import { isErrorHelperFunc } from "components/InviteUserForm";

const ApplicationDetail = () => {
  const history = useHistory();

  const [preview] = useState();

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [title, setTitle] = useState("");

  const [reason, setReason] = useState("");

  const { applicationId } = useParams();

  const [addLounge, { loading: addLoungeLoading }] =
    useMutation(AddLoungeMutation);

  const [
    updateLoungeOwnerApplication,
  ] = useMutation(UpdateLoungeOwnerApplicationMutation);

  const [createAdminAccount] = useMutation(CreateAdminAccountMutation);

  const [deleteAdminAccount] = useMutation(DeleteAdminAccountMutation);

  const {
    data: applicationData,
    loading: applicationLoading,
    error: loungeError,
  } = useQuery(QueryALoungeOwnerApplication, {
    variables: {
      applicationId,
    },
  });

  const { data: rolesData } = useQuery(GetRoles, {
    variables: {
      where: { name: { _eq: Roles.ADMIN } },
    },
  });

  const application = applicationData?.loungeOwnerApplications[0];

  const revertApproveLounge = async (applicationId) => {
    try {
      await updateLoungeOwnerApplication({
        refetchQueries: [
          {
            query: GetAllLoungeOwnerApplication,
          },
        ],
        variables: {
          where: {
            id: { _eq: applicationId },
          },
          set: {
            acceptedAt: null,
          },
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const createAdminFunc = async () => {
    try {
      const adminAccount = await createAdminAccount({
        variables: {
          objects: {
            shortName: application?.entityName
              ? application?.entityName
              : application?.user?.username,
            entityName: application?.entityName
              ? application?.entityName
              : application?.user?.username,
            type: "LOUNGEOWNER",
          },
        },
      });

      const adminAccountId =
        adminAccount?.data?.insert_adminAccount?.returning[0]?.id;
      const adminAccountName =
        adminAccount?.data?.insert_adminAccount?.returning[0]?.entityName;

      const roleId = rolesData?.roles[0]?.id;

      await addLounge({
        variables: {
          objects: {
            name: application?.offeredLoungeName,
            description: application?.description,
            imageUrl: defaultImage ?? null,
            isActive: false,
            isPrivate: false,
            userId: application?.requestingUserId,
            ownedByAccountId: adminAccountId,
          },
        },
      });

      const values = {
        userId: application?.requestingUserId,
        adminAccountId,
        roleId,
        isAccepted: true,
      };

      if (adminAccountId && roleId) {
        setLoading(true);

        const res = await fetch(`${Config.restApiUrl}/auth/admin/invite`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        setLoading(false);

        const response = await res.json();

        const isError = isErrorHelperFunc(response);

        if (isError) {
          await deleteAdminAccount({
            variables: {
              adminAccountName,
            },
          });
          throw new Error(response?.error);
        }
      } else {
        await deleteAdminAccount({
          variables: {
            adminAccountName,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const approveLounge = async () => {
    const applicationId = application?.id;

    try {
      await createAdminFunc();

      await updateLoungeOwnerApplication({
        refetchQueries: [
          {
            query: GetAllLoungeOwnerApplication,
          },
        ],
        variables: {
          where: {
            id: { _eq: application?.id },
          },
          set: {
            acceptedAt: new Date().toISOString(),
          },
        },
      });

      history.push("/applications");

      setAcceptModal(false);
      successNotify("Lounge Approved");
    } catch (error) {
      revertApproveLounge(applicationId);
      errorNotify(error.toString());
    }
  };

  const rejectLounge = async () => {
    try {
      setRejectLoading(true);
      const res = await fetch(
        `${Config.restApiUrl}/auth/admin/reject-lounge-owner-application`,
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...application,
            reason: reason ? reason : null,
            applicationId: application?.id,
            email: application?.user?.email,
            firstname: application?.user?.firstName,
            isRejected: true,
          }),
        }
      );

      const response = await res.json();

      setRejectLoading(false);

      const isError = isErrorHelperFunc(response);

      if (isError) {
        return errorNotify(response?.error);
      }

      setRejectModal(false);
      successNotify("Lounge Rejected");

      history.push("/applications");
    } catch (error) {
      errorNotify(error.toString());
    }
  };

  const customError = ErrorHandler.getUserFriendlyError(
    loungeError?.toString()
  );

  useEffect(() => {
    setTitle(application?.offeredLoungeName);
    // eslint-disable-next-line
  }, [applicationLoading]);

  if (loungeError?.toString()) {
    return <Pages500 error={customError} />;
  }

  return (
    <Wrapper>
      {applicationLoading ? (
        <CustomSpinner />
      ) : (
        <DetailLayoutView
          image={preview}
          onChange={() => {}}
          update
          breadcrumbTitle="Application"
          breadcrumbItem={application?.offeredLoungeName}
          breadcrumbRoute={"/applications"}
          hideImage={true}
        >
          <NewDetailView
            key1={{
              label: "Lounge Name",
              status: "edit",
              type: "text",
              isEditable: true,
              value: application?.offeredLoungeName,
            }}
            key2={{
              label: "Requesting user",
              status: "edit",
              type: "text",
              value: `${application?.user?.firstName} ${application?.user?.lastName}`,
            }}
            key3={{
              label: "Email",
              status: "edit",
              type: "text",
              value: application?.user?.email,
            }}
            key4={{
              label: "Lounge Description",
              type: "text",
              status: "edit",
              isEditable: true,
              value: application?.description,
            }}
            key5={{
              label: "Entity Name",
              type: "text",
              status: "edit",
              isEditable: true,
              value: application?.entityName,
            }}
            key6={{
              label: "Created At",
              type: "text",
              value: moment(application?.createdAt).fromNow(),
            }}
            action={{
              type: "action",
              actionText: {
                btn2Text: "Accept",
                btn3Text: "Reject",
              },
              action: {
                btn2: () => setAcceptModal(true),
                btn3: () => setRejectModal(true),
                status: "edit",
              },
              // actionLoading: {
              //   btn2Loading: addLoungeLoading || loading,
              //   btn3Loading: updateLoungeOwnerApplicationLoading,
              // },
              actionStyle: {
                btn2Style: {
                  background: "success",
                },
                btn3Style: {
                  background: "danger",
                },
              },
            }}
          />
          <ToastContainer />
        </DetailLayoutView>
      )}

      {/* Accept Modal */}
      <Modal isOpen={acceptModal} centered={true}>
        <ModalBody>
        <p>Are you sure you want to accept {title} lounge request?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setAcceptModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (application) {
                approveLounge();
              }
            }}
          >
            {addLoungeLoading || loading ? (
              <Spinner style={{ width: 14, height: 14 }} />
            ) : (
              "Yes"
            )}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Reject Modal */}
      <Modal isOpen={rejectModal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to reject {title} lounge request?</p>
          <FlexDiv direction="column" padding="0px">
            <Label>Reason for rejecting lounge</Label>
            <textarea
              className="form-control"
              id="projectdesc"
              style={{ width: "100%" }}
              rows="3"
              placeholder="Reason for rejecting lounge"
              onChange={({ target: { value } }) => {
                setReason(value);
              }}
            ></textarea>
          </FlexDiv>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setRejectModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (application) {
                rejectLounge();
              }
            }}
          >
            {rejectLoading ? (
              <Spinner style={{ width: 14, height: 14 }} />
            ) : (
              "Yes"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 10vh);
  margin-top: 10vh;
  padding-bottom: 5vh;
  overflow-y: auto;
`;

export default ApplicationDetail;
