import { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { BookAggregate } from "graphql/books";
import { LoungeQuery } from "graphql/lounges";
import { GroupQuery } from "graphql/query/groups";
import { MostPurchaseBookQuery } from "graphql/query/mostPurchaseBook";
import { UserPurchaseQuery } from "graphql/query/userPurchaseQuery";
import { UsersQuery, UsersForLast30DaysQuery } from "graphql/users";
import { LoungeWithMostUsersQuery } from "graphql/query/loungeWithMostUsers";
import { MostActiveGroupQuery } from "graphql/query/mostActiveGroupQuery";
import { LatestTransactionQuery } from "graphql/query/latestTransactionQuery";
import moment from "moment";
import { UserContext } from "context/UserContext";
import { useAdminQueries } from "./adminDashboardQueries";

export const useSuperAdminQueries = () => {
  const [dates] = useState({
    startDate: moment().subtract(30, "days").format(),
    endDate: new Date().toISOString(),
  });

  const { isAdmin } = useContext(UserContext);

  const { data: booksData, loading: bookLoading } = useQuery(BookAggregate);
  const { data: users, loading: userLoading } = useQuery(
    UsersForLast30DaysQuery,
    {
      variables: {
        startDate: dates.startDate,
        endDate: dates.endDate,
        limit: 3,
      },
    }
  );
  const { data: usersData, loading: userCountLoading } = useQuery(UsersQuery);
  const {
    data: loungeData,
    loading: loungeLoading,
    error: loungeError,
  } = useQuery(LoungeQuery, {
    variables: {
      search: {
        isActive: {
          _eq: true,
        },
      },
    },
  });
  const { data: groupData, loading: groupLoading } = useQuery(GroupQuery);
  const { data: userPurchaseData, loading: userPurchaseLoading } =
    useQuery(UserPurchaseQuery);
  const { data: mostPuchaseBookData, loading: mostPurchaseLoading } = useQuery(
    MostPurchaseBookQuery
  );
  const { data: loungeWithMostUsersData, loading: loungeWithMostUsersLoading } =
    useQuery(LoungeWithMostUsersQuery, {
      variables: {
        limit: 3,
      },
    });
  const { data: mostActiveGroupData, loading: mostActiveLoading } = useQuery(
    MostActiveGroupQuery,
    {
      variables: {
        limit: 3,
      },
    }
  );
  const { data: latestTransactionData, loading: latestTransactionLoading } =
    useQuery(LatestTransactionQuery, {
      variables: {
        limit: 3,
      },
    });

  // const {
  //   loungeOwnerData: {
  //     loungeOwnerUsers,
  //     loungeOwnerGroupData,
  //     loungeOwnerMostActiveGroupData,
  //     loungeOwnerLoungeWithMostUsersData,
  //     loungeOwnerLatestTransaction,
  //     loungeOwnerBookData,
  //     loungeOwnerLoungeData,
  //     loungeOwnerMostPurchaseBookQuery,
  //     loungeUsers,
  //     loungeOwnerUserPurchaseData,
  //   },
  // } = useAdminQueries();

  const {
    loungeOwnerLoading: {
      loungeOwnerUserLoading,
      loungeOwnerBookLoading,
      loungeOwnerLoungeLoading,
      loungeOwnerGroupLoading,
      loungeOwnerUserPurchaseLoading,
      loungeOwnerMostActiveLoading,
      loungeOwnerLoungeWithMostUsersLoading,
      loungeOwnerLatestTransactionLoading,
      loungeOwnerUserCountLoading,
      loungeOwnerMostPurchaseLoading,
    },
  } = useAdminQueries();

  const superAdminData = {
    users: users,
    usersData: usersData,
    loungeData: loungeData,
    groupData: groupData,
    userPurchaseData: userPurchaseData,
    mostActiveGroupData: mostActiveGroupData,
    loungeWithMostUsersData: loungeWithMostUsersData,
    latestTransactionData: latestTransactionData,
    mostPuchaseBookData: mostPuchaseBookData,
    booksData: booksData,
  };
  // const superAdminData = {
  //   users: isAdmin ? users : loungeOwnerUsers,
  //   usersData: isAdmin ? usersData : loungeUsers,
  //   loungeData: isAdmin ? loungeData : loungeOwnerLoungeData,
  //   groupData: isAdmin ? groupData : loungeOwnerGroupData,
  //   userPurchaseData: isAdmin ? userPurchaseData : loungeOwnerUserPurchaseData,
  //   mostActiveGroupData: isAdmin
  //     ? mostActiveGroupData
  //     : loungeOwnerMostActiveGroupData,
  //   loungeWithMostUsersData: isAdmin
  //     ? loungeWithMostUsersData
  //     : loungeOwnerLoungeWithMostUsersData,
  //   latestTransactionData: isAdmin
  //     ? latestTransactionData
  //     : loungeOwnerLatestTransaction,
  //   mostPuchaseBookData: isAdmin
  //     ? mostPuchaseBookData
  //     : loungeOwnerMostPurchaseBookQuery,
  //   booksData: isAdmin ? booksData : loungeOwnerBookData,
  // };

  const superAdminLoading = {
    userLoading: isAdmin ? userLoading : loungeOwnerUserLoading,
    bookLoading: isAdmin ? bookLoading : loungeOwnerBookLoading,
    loungeLoading: isAdmin ? loungeLoading : loungeOwnerLoungeLoading,
    groupLoading: isAdmin ? groupLoading : loungeOwnerGroupLoading,
    userPurchaseLoading: isAdmin
      ? userPurchaseLoading
      : loungeOwnerUserPurchaseLoading,
    mostActiveLoading: isAdmin
      ? mostActiveLoading
      : loungeOwnerMostActiveLoading,
    loungeWithMostUsersLoading: isAdmin
      ? loungeWithMostUsersLoading
      : loungeOwnerLoungeWithMostUsersLoading,
    latestTransactionLoading: isAdmin
      ? latestTransactionLoading
      : loungeOwnerLatestTransactionLoading,
    userCountLoading: isAdmin ? userCountLoading : loungeOwnerUserCountLoading,
    mostPurchaseLoading: isAdmin
      ? mostPurchaseLoading
      : loungeOwnerMostPurchaseLoading,
  };

  const superAdminError = {
    loungeError,
  };

  return { superAdminData, superAdminLoading, superAdminError };
};
