import gql from 'graphql-tag';

export const UserDeleteApplicationQuery = gql`
	query UserAccountDeleteApplication($limit: Int, $offset: Int) {
		userAccountDeleteApplication(
			order_by: { dateOfApplicationSubmission: desc }
			limit: $limit
			offset: $offset
		) {
			id
			dateOfApplicationSubmission
			dateOfAccountDeletion
			accountDeleted
			email
			reasonOfAccountDeletion
			userId
			username
		}
		userAccountDeleteApplication_aggregate {
			aggregate {
				count
			}
		}
	}
`;
