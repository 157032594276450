import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError } from "./actions";

//Include Both Helper File with needed methods
import { loginUserHelper } from "../../../helpers/auth_helper";

import { Config } from "../../../config";
import { message } from "antd";

function* loginUser({ payload: { user, history } }) {
  try {
    const userObj = { email: user.email, password: user.password };
    const payload = {
      userObj,
      history,
    };
    const response = yield call(
      loginUserHelper,
      `${Config.restApiUrl}/auth/admin-sign-in`,
      payload
    );

    yield put(apiError(null));

    // if(key === 'error') {
    message.error("Network issues try again");
    // const networkError = response?.error?.split(':')[1]
    // if(networkError) {
    //     message.error("You have network issues")
    //     yield put(apiError("You have network issues"));
    //     history.push('/login');
    //     return
    // }
    // console.log(response)
    // history.push('/login');
    // yield put(apiError(response?.error));
    // message.error(response.error)
    // return
    // }

    localStorage.setItem("authUser", JSON.stringify(response));

    // history.push('/dashboard');
  } catch (error) {
    message.error("Network issues try again");
    // history.push('/login');
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
