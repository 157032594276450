import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import styled from 'styled-components';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
	Badge,
	Spinner,
	Modal,
	ModalBody,
	ModalFooter,
	Button,
} from 'reactstrap';

import Breadcrumbs from 'components/Common/Breadcrumb';
import ImageUi from 'components/Common/ImageUi';
// import NewTableList from "components/TableList/NewTable";
import {
	GetInvitedUsers,
	GetInvitedLoungeOwner,
	GetLoungeOwnerAdmins,
} from 'graphql/query/queryLoungeOwnerAdmin';
import { UserContext } from 'context/UserContext';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { pluralizeTextWithoutCount } from 'utils/pluralizeText';
import CustomTable from 'components/TableList/CustomTable';
import { Colors } from 'assets/colors/Colors';
import { WithdrawTeamInvite } from 'graphql/delete/withdrawTeamInvite';
import { WithdrawLoungeOwnershipInvite } from 'graphql/delete/withdrawLoungeOwnershipInvite';
import { errorNotify, successNotify } from 'utils/toaster';
import { Config } from 'config';
import { isErrorHelperFunc } from 'components/InviteUserForm';
import { useEffect } from 'react';

const LoungeOwnerAdmins = () => {
	const { isAdmin, adminAccountId, user, admin } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);

	const history = useHistory();

	const [withdrawInviteModal, setWithdrawInviteModal] = useState(false);
	const [withdrawLoungeOwnerInviteModal, setWithdrawLoungeOwnerInviteModal] =
		useState(false);

	const [resendInviteModal, setResendInviteModal] = useState(false);
	const [
		resendLoungeOwnershipInviteModal,
		setResendLoungeOwnershipInviteModal,
	] = useState(false);
	const [resendLoadingState, setResendLoadingState] = useState(false);
	const [resendLoungeOwnerLoadingState, setResendLoungeOwnerLoadingState] =
		useState(false);
	const [inviteId, setInviteId] = useState();
	const [resendValues, setResendValues] = useState();

	const { data: adminData, loading } = useQuery(GetLoungeOwnerAdmins, {
		variables: {
			adminAccountId,
		},
	});

	const { data: invitedUserData, loading: invitedUserLoading } = useQuery(
		GetInvitedUsers,
		{
			variables: {
				ownerId: user?.id,
				adminAccountId,
			},
		}
	);

	const {
		data: invitedLoungeOwnerData,
		loading: invitedLoungeOwnerLoading,
		error: invitedLoungeOwnerError,
	} = useQuery(GetInvitedLoungeOwner, {
		variables: {
			ownerId: user?.id,
			adminAccountId,
		},
	});

	const [withdrawTeamInvite, { loading: withdrawTeamInviteLoading }] =
		useMutation(WithdrawTeamInvite);

	const [
		withdrawLoungeOwnershipInvite,
		{ loading: withdrawLoungeOwnershipInviteLoading },
	] = useMutation(WithdrawLoungeOwnershipInvite);

	const admins = adminData?.admin;

	const pendingUsers = invitedUserData?.loungeTeamMembersApplications;

	const pendingOwners =
		invitedLoungeOwnerData?.loungeOwnershipInviteApplications;

	// const columns = [
	//   { id: 1, title: "Image" },
	//   { id: 2, title: "First Name" },
	//   { id: 3, title: "Last Name" },
	//   { id: 4, title: "Username" },
	//   { id: 5, title: "Admin Type" },
	//   { id: 6, title: "Suspended Status" },
	//   { id: 7, title: "Date Created" },
	// ];

	const columns2 = [
		{ id: 1, name: 'Image', selector: 'image' },
		{ id: 2, name: 'First Name', selector: 'firstName' },
		{ id: 3, name: 'Last Name', selector: 'lastName' },
		{ id: 4, name: 'Username', selector: 'username' },
		{ id: 5, name: 'Admin Type', selector: 'adminType' },
		{ id: 7, name: 'Supension Status', selector: 'status' },
		{ id: 6, name: 'Date Created', selector: 'date' },
	];

	const pendingColumns = [
		{ id: 1, name: 'Image', selector: 'image' },
		{ id: 2, name: 'First Name', selector: 'firstName' },
		{ id: 3, name: 'Last Name', selector: 'lastName' },
		{ id: 4, name: 'Username', selector: 'username' },
		{ id: 6, name: 'Date Created', selector: 'date' },
		{ id: 7, name: 'Action', selector: 'actions' },
	];

	const data = _.map(admins, ({ id, suspended, user, role, createdAt }) => ({
		id,
		image: (
			<ImageUi
				imageUrl={user?.avatarUrl}
				text={user?.username}
				width={35}
				height={35}
			/>
		),
		firstName: user?.firstName,
		lastName: user?.lastName,
		username: user?.username,
		adminType: role?.name === 'ADMIN' ? 'OWNER' : role?.name,
		status: (
			<div
				className='text-center'
				style={{
					textAlign: 'center',
					width: '100px',
				}}
			>
				{!suspended ? (
					<i className='bx bx-check-double bx-sm text-success'></i>
				) : (
					<i className='bx bx-x bx-sm text-danger'></i>
				)}
			</div>
		),
		date: moment(createdAt).fromNow(),
	}));

	// const pendingUsersData = _.map(pendingUsers, ({ id, user, createdAt }) => ({
	//   id,
	//   image: <ImageUi imageUrl={user?.avatarUrl} text={user?.username} />,
	//   firstName: user?.firstName,
	//   lastName: user?.lastName,
	//   username: user?.username,
	//   email: user?.email,
	//   date: moment(createdAt).fromNow(),
	// }));

	const pendingUsersData = _.map(
		pendingUsers,
		({ id, user, owner, createdAt }) => ({
			image: (
				<ImageUi
					imageUrl={user?.avatarUrl}
					text={user?.username}
					width={35}
					height={35}
				/>
			),
			firstName: user?.firstName,
			lastName: user?.lastName,
			username: user?.username,
			date: moment(createdAt).fromNow(),
			actions: (
				<div>
					<Badge
						color={Colors.green800}
						style={{
							background: Colors.red200,
							color: Colors.red800,
							cursor: 'pointer',
							marginRight: 10,
						}}
						onClick={() => {
							setInviteId(user?.id);
							setWithdrawInviteModal(true);
						}}
					>
						Remove
					</Badge>
					<Badge
						color={Colors.green800}
						style={{
							background: Colors.green100,
							color: Colors.green800,
							cursor: 'pointer',
							marginRight: 10,
						}}
						onClick={() => {
							setResendValues({
								invitedUserId: user?.id,
								loungeOwnerId: owner?.id,
							});
							setResendInviteModal(true);
						}}
					>
						Resend
					</Badge>
				</div>
			),
			id: user?.id,
		})
	);

	const pendingLoungeOwnershipData = _.map(
		pendingOwners,
		({ id, invitedUser, owner, createdAt }) => ({
			image: (
				<ImageUi
					imageUrl={invitedUser?.avatarUrl}
					text={invitedUser?.username}
					width={35}
					height={35}
				/>
			),
			firstName: invitedUser?.firstName,
			lastName: invitedUser?.lastName,
			username: invitedUser?.username,
			date: moment(createdAt).fromNow(),
			actions: (
				<div>
					<Badge
						color={Colors.green800}
						style={{
							background: Colors.red200,
							color: Colors.red800,
							cursor: 'pointer',
							marginRight: 10,
						}}
						onClick={() => {
							setInviteId(invitedUser?.id);
							setWithdrawLoungeOwnerInviteModal(true);
						}}
					>
						Remove
					</Badge>
					<Badge
						color={Colors.green800}
						style={{
							background: Colors.green100,
							color: Colors.green800,
							cursor: 'pointer',
							marginRight: 10,
						}}
						onClick={() => {
							setResendValues({
								invitedUserId: invitedUser?.id,
								loungeOwnerId: owner?.id,
							});
							setResendLoungeOwnershipInviteModal(true);
						}}
					>
						Resend
					</Badge>
				</div>
			),
			id: invitedUser?.id,
		})
	);

	return (
		<Wrapper>
			<Breadcrumbs title='Admins' breadcrumbItem='Admins' />
			<div>
				{loading ? (
					<div style={{ textAlign: 'center', minHeight: 200, lineHeight: 10 }}>
						<Spinner />
					</div>
				) : (
					<CustomTable
						data={data}
						columns={columns2}
						total={10}
						onClick={(row) => history.push(`/admins/${row?.id}`)}
						style={{ cursor: 'pointer' }}
					/>
				)}
				{/* <NewTableList
          onChange={() => {}}
          placeholder="Search groups by name or description..."
          btnLabel="Create Group"
          route="/"
          isFilter={false}
          setQuery={() => {}}
          data={data}
          columns={columns}
          onRow={(record) => history.push(`/admins/${record?.id}`)}
          totalCount={0}
          limit={0}
          setOffset={() => {}}
          loading={loading}
          trStyle={{
            cursor: "pointer",
          }}
          search
        /> */}
			</div>
			{admin?.role?.name !== 'TEAM-MEMBER' && (
				<>
					<div>
						<h2>
							Pending{' '}
							{pluralizeTextWithoutCount(
								pendingUsersData.length,
								'Team Member'
							)}{' '}
							Invite
						</h2>
						{invitedUserLoading ? (
							<div
								style={{ textAlign: 'center', minHeight: 200, lineHeight: 10 }}
							>
								<Spinner />
							</div>
						) : (
							<CustomTable
								data={pendingUsersData}
								columns={pendingColumns}
								total={10}
								onClick={() => {}}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</div>

					<div>
						<h2>Pending Lounge Ownership Invite</h2>
						{invitedUserLoading ? (
							<div
								style={{ textAlign: 'center', minHeight: 200, lineHeight: 10 }}
							>
								<Spinner />
							</div>
						) : (
							<CustomTable
								data={pendingLoungeOwnershipData}
								columns={pendingColumns}
								total={10}
								onClick={() => {}}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</div>
				</>
			)}

			<Modal isOpen={withdrawInviteModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to withdraw this invite?</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color='secondary'
						onClick={() => setWithdrawInviteModal(false)}
					>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={async () => {
							try {
								if (inviteId) {
									await withdrawTeamInvite({
										refetchQueries: [
											{
												query: GetInvitedUsers,
												variables: {
													ownerId: user?.id,
													adminAccountId,
												},
											},
										],
										variables: {
											inviteId,
										},
									});
									setWithdrawInviteModal(false);
									successNotify('Pending invited user withdraw is successful');
								}
							} catch (error) {
								errorNotify('Could not withdraw this invite, try again');
							}
						}}
					>
						{withdrawTeamInviteLoading ? (
							<Spinner style={{ width: 14, height: 14 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={resendInviteModal} centered={true}>
				<ModalBody>
					<p>You are about to resend an invite, are you sure?</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setResendInviteModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={async () => {
							const isEmpty = _.isEmpty(resendValues);
							try {
								if (!isEmpty) {
									setResendLoadingState(true);
									const res = await fetch(
										`${Config.restApiUrl}/auth/admin/invite-team-member`,
										{
											method: 'POST',
											headers: {
												Accept: '*/*',
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												invitedUserId: resendValues?.invitedUserId,
												loungeOwnerId: resendValues?.loungeOwnerId,
											}),
										}
									);

									const response = await res.json();

									const isError = isErrorHelperFunc(response);
									setResendLoadingState(false);

									if (isError) {
										setResendLoadingState(false);
										return errorNotify(response?.error);
									}
									setResendInviteModal(false);
									successNotify('Resending team member invite was successful');
								}
							} catch (error) {
								setResendLoadingState(false);
								errorNotify('Fail to resend invite, try again');
							}
						}}
					>
						{resendLoadingState ? (
							<Spinner style={{ width: 14, height: 14 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={withdrawLoungeOwnerInviteModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to withdraw this invite?</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color='secondary'
						onClick={() => setWithdrawLoungeOwnerInviteModal(false)}
					>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={async () => {
							try {
								if (inviteId) {
									await withdrawLoungeOwnershipInvite({
										refetchQueries: [
											{
												query: GetInvitedLoungeOwner,
												variables: {
													ownerId: user?.id,
													adminAccountId,
												},
											},
										],
										variables: {
											inviteId,
										},
									});
									setWithdrawLoungeOwnerInviteModal(false);
									successNotify('Pending invited user withdraw is successful');
								}
							} catch (error) {
								errorNotify('Could not withdraw this invite, try again');
							}
						}}
					>
						{withdrawLoungeOwnershipInviteLoading ? (
							<Spinner style={{ width: 14, height: 14 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={resendLoungeOwnershipInviteModal} centered={true}>
				<ModalBody>
					<p>You are about to resend an invite, are you sure?</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color='secondary'
						onClick={() => setResendLoungeOwnershipInviteModal(false)}
					>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={async () => {
							const isEmpty = _.isEmpty(resendValues);
							try {
								if (!isEmpty) {
									setResendLoungeOwnerLoadingState(true);
									const res = await fetch(
										`${Config.restApiUrl}/auth/admin/lounge-ownership-invite`,
										{
											method: 'POST',
											headers: {
												Accept: '*/*',
												'Content-Type': 'application/json',
											},
											body: JSON.stringify({
												invitedUserId: resendValues?.invitedUserId,
												loungeOwnerId: resendValues?.loungeOwnerId,
												loungeId,
											}),
										}
									);

									const response = await res.json();

									const isError = isErrorHelperFunc(response);
									setResendLoungeOwnerLoadingState(false);

									if (isError) {
										setResendLoungeOwnerLoadingState(false);
										return errorNotify(response?.error);
									}
									setResendLoungeOwnershipInviteModal(false);
									successNotify('Lounge ownership invite was sent successful');
								}
							} catch (error) {
								resendLoungeOwnerLoadingState(false);
								errorNotify('Fail to resend invite, try again');
							}
						}}
					>
						{resendLoungeOwnerLoadingState ? (
							<Spinner style={{ width: 14, height: 14 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 0 5%;
	padding-top: calc(10vh + 3%);
	overflow-y: auto;
`;

export default LoungeOwnerAdmins;
