import gql from 'graphql-tag';

export const LoungeUsersQuery = gql`
	query loungeUserQuery($loungeId: uuid, $limit: Int, $offset: Int) {
		loungeUser(
			where: { lounge: { id: { _eq: $loungeId } } }
			order_by: { user: { createdAt: desc } }
			limit: $limit
			offset: $offset
		) {
			id
			user {
				id
				firstName
				lastName
				username
				email
				avatarUrl
				createdAt
			}
		}

		loungeUser_aggregate(where: { lounge: { id: { _eq: $loungeId } } }) {
			aggregate {
				count
			}
		}
	}
`;

export const LoungeUserQuery = gql`
	query loungeUserQuery($loungeUserId: uuid) {
		loungeUser(where: { id: { _eq: $loungeUserId } }) {
			user {
				id
				firstName
				lastName
				username
				email
				avatarUrl
				createdAt
			}
		}
	}
`;
