import gql from 'graphql-tag';

export const GetAGroupQuery = gql`
	query getAGroup($planId: uuid) {
		plan(where: { id: { _eq: $planId } }) {
			id
			userId
			name
			description
			private
			deleted
			createdAt
			duration
			updatedAt
			books {
				book {
					id
					imageUrl
					title
					author
					bookUrl
				}
			}
			user {
				id
				firstName
				lastName
				username
				email
			}
			users_aggregate(
				where: {
					declinedAt: { _is_null: true }
					acceptedAt: { _is_null: false }
				}
			) {
				aggregate {
					count
				}
			}
			users {
				user {
					id
					firstName
					lastName
					username
					email
				}
			}
			chatMessages_aggregate {
				aggregate {
					count
				}
			}
		}

		plan_aggregate {
			aggregate {
				count
			}
		}
	}
`;
