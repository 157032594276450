import React, { useContext, useState } from 'react';
import { LoungeQuery } from 'graphql/lounges';
import { useMutation, useQuery } from 'react-apollo';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { errorNotify, successNotify } from 'utils/toaster';
import _ from 'lodash';
import { Button, ButtonGroup, Col, Label, FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import {
	AddLoungeYoutubeData,
	UpdateLoungeYoutubeData,
} from '../../graphql/query/loungeYoutube';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { UserContext } from 'context/UserContext';

const YoutubeForm = ({
	addData,
	setModal,
	rowData,
	setRowData,
	initialRowData,
	refetch,
	refetchLoungeYoutube
}) => {
	const { loungeId, loungeName } = useContext(LoungePortalContext);
	const { isAdminPortal } = useContext(UserContext);

	const [values, setValues] = useState({
		channelName: rowData?.channelName ?? '',
		channelId: rowData?.channelId ?? '',
		playlistId: rowData?.playlistId ?? '',
		loungeId: isAdminPortal ? rowData?.loungeId : loungeId,
		loungeName: isAdminPortal ? rowData?.loungeName : loungeName
	});

	const resetForm = () => {
		setRowData(initialRowData);
		setValues(initialRowData);
	};

	const [addLoungeYoutubeData, { loading: adding }] =
		useMutation(AddLoungeYoutubeData);
	const [updateLoungeYoubeData, { loading: updating }] = useMutation(
		UpdateLoungeYoutubeData
	);
	const { data: loungeData } = useQuery(LoungeQuery, {
		variables: {
			search: {
				name: {
					_ilike: '%%',
				},
			},
		},
	});

	const handleSelect = (select) => {
		setValues({
			...values,
			loungeId: select.value,
		});
	};

	const lounges = isAdminPortal ? _.map(loungeData?.lounge, (lounge) => ({
		label: lounge?.name,
		value: lounge?.id,
	})) : [{
		label: loungeName,
		value: loungeId
	}]

	return (
		<Container>
			<FormGroup className='m-4 row'>
				<Label className='col-form-label col-md-4'>Channel Name:</Label>
				<Col md={8}>
					<Input
						type='text'
						value={values?.channelName}
						onChange={({ target: { value } }) => {
							setValues({
								...values,
								channelName: value,
							});
						}}
						placeholder='Enter Channel Name'
					/>
				</Col>
			</FormGroup>
			<FormGroup className='m-4 row'>
				<Label className='col-form-label col-md-4'>Channel ID:</Label>
				<Col md={8}>
					<Input
						type='text'
						value={values?.channelId}
						onChange={({ target: { value } }) => {
							setValues({
								...values,
								channelId: value,
							});
						}}
						placeholder='Enter Channel ID'
					/>
				</Col>
			</FormGroup>
			<FormGroup className='m-4 row'>
				<Label className='col-form-label col-md-4'>Playlist ID:</Label>
				<Col md={8}>
					<Input
						type='text'
						value={values?.playlistId}
						onChange={({ target: { value } }) => {
							setValues({
								...values,
								playlistId: value,
							});
						}}
						placeholder='Enter Playlist ID'
					/>
				</Col>
			</FormGroup>
			{addData ? (
				<FormGroup className='m-4 row'>
					<Label className='col-form-label col-md-4'>Lounge:</Label>
					<Col md={8}>
						<Select
							options={[...lounges]}
							onChange={handleSelect}
							className='select'
						/>
					</Col>
				</FormGroup>
			) : (
				<FormGroup className='m-4 row'>
					<Label className='col-form-label col-md-12'>
						{rowData?.loungeName} Lounge
					</Label>
				</FormGroup>
			)}
			<FormGroup className='m-4 row flex-center'>
				<ButtonGroup>
					<Button className='mr-4' onClick={() => setModal(false)}>
						Cancel
					</Button>
					{addData ? (
						<Button
							onClick={async () => {
								try {
									await addLoungeYoutubeData({
										variables: {
											channelName: values.channelName.trim(),
											channelId: values.channelId.trim(),
											playlistId: values.playlistId.trim(),
											loungeId: isAdminPortal ? values.loungeId : loungeId,
										},
									});
									if (isAdminPortal) {
										await refetch();
									} else {
										await refetchLoungeYoutube();
									}
									setModal(false);
									resetForm();
									successNotify('Added Successfully');
								} catch (e) {
									errorNotify(
										"Unable to add channel data, Ensure all fields are filled and lounge you selected doesn't exist on the table"
									);
								}
							}}
							className='mr-4'
							color={'success'}
						>
							{adding ? 'Adding Channel Data...' : 'Add Channel Data'}
						</Button>
					) : (
						<Button
							onClick={async () => {
								try {
									await updateLoungeYoubeData({
										variables: {
											channelName: values.channelName.trim(),
											channelId: values.channelId.trim(),
											playlistId: values.playlistId.trim(),
											loungeId: rowData?.loungeId,
										},
									});
									await refetch();
									setModal(false);
									resetForm();
									successNotify('Updated Successfully');
								} catch (e) {
									errorNotify('Unable to Update channel data');
								}
							}}
							className='mr-4'
							color={'success'}
						>
							{updating ? 'Updating Channel Data...' : 'Update Channel Data'}
						</Button>
					)}
				</ButtonGroup>
			</FormGroup>
			<ToastContainer />
		</Container>
	);
};

const Container = styled.div`
	.select {
		width: 100%;
	}
`;

export default YoutubeForm;
