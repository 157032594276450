import React, { useRef } from "react";

const VideoPlayer = ({ videoUrl }) => {
  const videoRef = useRef(null);

  return (
    <div style={{ position: 'absolute'}}>
      <video
        ref={videoRef}
        width="600"
        controls
        src={videoUrl}
      />
    </div>
  );
};

export default VideoPlayer;
