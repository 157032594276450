import { UserContext } from "context/UserContext";
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const { admin } = useContext(UserContext);

  const isAuth = admin
    ? Boolean(admin?.emailConfirmed) && !admin?.suspended
      ? true
      : false
    : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthProtected &&
          !isAuth &&
          !localStorage.getItem("authUser")
          // &&
        ) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
