import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import CardView from 'components/CardView';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { QueryBooks, QueryContentDrafts } from 'graphql/books';
import TableSearchAndCreate from 'components/TableList/TableSearchAndCreate';
// import { UserContext } from "context/UserContext";
import { LoungePortalContext } from 'context/LoungePortalContext';

const ContentForOwner = () => {
	// const { user } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);
	const [showExclusiveContent, setShowExclusiveContent] = useState(false);

	const [, setQuery] = useState('');
	const [, setLoungeQuery] = useState('');

	const {
		data: bookData,
		loading,
		refetch: bookRefetch,
	} = useQuery(QueryBooks, {
		variables: {
			where: {
				_and: [
					loungeId ? { lounges: { lounge: { id: { _eq: loungeId } } } } : {},
					showExclusiveContent ? { isExclusive: { _eq: true } } : {},
				],
			},
		},
	});

	useEffect(() => {
		async function fetch() {
			await bookRefetch();
		}

		fetch();
	}, [showExclusiveContent]);

	// const {
	//   data: bookData,
	//   loading,
	//   refetch: bookRefetch,
	// } = useQuery(QueryBooks, {
	//   variables: {
	//     where: {
	//       _and: [
	//         {
	//           _and: [
	//             { title: { _ilike: `%${query}%` } },
	//             loungeQuery
	//               ? { lounges: { lounge: { name: { _eq: loungeQuery } } } }
	//               : {},
	//           ],
	//         },
	//         {
	//           _or: [
	//             {
	//               lounges: {
	//                 lounge: { createdByAdminId: { _eq: user?.adminId } },
	//               },
	//             },
	//             {
	//               lounges: {
	//                 lounge: { updatedByAdminId: { _eq: user?.adminId } },
	//               },
	//             },
	//           ],
	//         },
	//       ],
	//     },
	//   },
	// });

	const {
		data: contentDraftData,
		loading: contentDraftLoading,
		refetch: contentDraftsRefetch,
	} = useQuery(QueryContentDrafts, {
		variables: {
			where: {
				_and: [loungeId ? { lounge: { id: { _eq: loungeId } } } : {}],
			},
		},
	});

	// const {
	//   data: contentDraftData,
	//   loading: contentDraftLoading,
	//   refetch: contentDraftsRefetch,
	// } = useQuery(QueryContentDrafts, {
	//   variables: {
	//     where: {
	//       _and: [
	//         {
	//           _and: [
	//             { title: { _ilike: `%${query}%` } },
	//             loungeQuery ? { lounge: { name: { _eq: loungeQuery } } } : {},
	//           ],
	//         },
	//         {
	//           _or: [
	//             {
	//               lounge: { createdByAdminId: { _eq: user?.adminId } },
	//             },
	//             {
	//               lounge: { updatedByAdminId: { _eq: user?.adminId } },
	//             },
	//           ],
	//         },
	//       ],
	//     },
	//   },
	// });

	const contents = _.orderBy(
		_.concat(bookData?.book, contentDraftData?.contentDrafts),
		'createdAt',
		'desc'
	);

	useEffect(() => {
		if (bookRefetch) {
			bookRefetch();
		}
		if (contentDraftsRefetch) {
			contentDraftsRefetch();
		}

		// eslint-disable-next-line
	}, []);

	const handleInput = ({ currentTarget: { value } }) => setQuery(value);

	const books = loungeId
		? _.map(contents, (book) => ({
				id: book?.id,
				title: book?.title,
				author: book?.author,
				priceValue: book?.priceValue,
				priceCurrency: book?.priceCurrency,
				imageUrl: book?.imageUrl,
				description: book?.description,
				isPublished: book?.isPublished,
				readStats:
					book && book?.readStats?.length && book?.readStats[0]?.readCount,
				numberOfTimesReshared: book?.numberOfTimesReshared,
				resharedDate: book?.resharedDate
		  }))
		: [];

	return (
		<Wrapper>
			<Row>
				<Col>
					<TableSearchAndCreate
						onChange={handleInput}
						placeholder='Search content by name or description...'
						btnLabel='Create Content'
						route='/create-book?type=lounge-owner'
						inputStyle={{
							border: '1px solid #bbbbbb',
						}}
						loading={loading}
						showExclusiveContent={showExclusiveContent}
						setShowExclusiveContent={() =>
							setShowExclusiveContent(!showExclusiveContent)
						}
						isFilter={false}
						setQuery={setLoungeQuery}
					/>

					<CardView
						createLabel='Create Content'
						createRoute='/create-book'
						data={books}
						routeName='/content'
						loading={loading || contentDraftLoading}
					/>
				</Col>
			</Row>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	overflow-y: auto;
	background-color: white;
	padding: 2% 3%;
	padding-top: calc(10vh + 2%);

	.border-dash {
		display: block;
		width: 100%;
		height: 250px;
		border-radius: 8px;
		border: 5px dashed #aaaaaa;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.plus {
			font-size: 35px;
			color: #aaaaaa;
		}

		.create {
			font-size: 30;
			color: #aaaaaa;
			font-weight: bolder;
		}
	}

	.input-upload {
		display: none;
	}

	.content {
		height: 250px;
		border: 1px solid #eeeeee;

		p {
			margin: 0;
		}

		.content-footer {
			border-top: 2px solid #aaaaaa;
			padding: 15px 0;
		}
	}
`;

export default ContentForOwner;
