/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useMemo } from "react";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import Select from "react-select";
import styled from "styled-components";
import { Tabs } from "antd";

import Breadcrumbs from "components/Common/Breadcrumb";
import { QueryBooks } from "graphql/books";
import { QueryContentDrafts } from "graphql/query/getContentDrafts";
import BookList from "./BookList";

import NetworkErrorComponent from "ui/NetworkErrorComponent";
import { LoungeQuery } from "graphql/lounges";
import _ from "lodash";
// import Tabs from '../../components/Common/Tabs'

const { TabPane } = Tabs;

function callback(key) {}

const ContentTab = (props) => {
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="All" key="1">
        <BookList bookData={props?.all} />
      </TabPane>
      <TabPane tab="Books" key="2">
        <BookList bookData={props?.books} />
      </TabPane>
      <TabPane tab="Blogs" key="3">
        <BookList bookData={props?.blogs} />
      </TabPane>
      <TabPane tab="Articles" key="4">
        <BookList bookData={props?.articles} />
      </TabPane>
      <TabPane tab="Magazines" key="5">
        <BookList bookData={props?.magazines} />
      </TabPane>
      <TabPane tab="Drafts" key="6">
        <BookList bookData={props?.drafts} />
      </TabPane>
    </Tabs>
  );
};

const Book = () => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [loungeQuery, setLoungeQuery] = useState("");

  const {
    data: bookData,
    loading: bookLoading,
    error,
    refetch,
  } = useQuery(QueryBooks, {
    variables: {
      where: {
        _and: [
          { title: { _ilike: `%${query}%` } },
          loungeQuery
            ? { lounges: { lounge: { name: { _eq: loungeQuery } } } }
            : {},
        ],
      },
    },
  });

  const {
    data: contentDraftsData,
    loading: contentDraftsLoading,
    refetch: contentDraftsRefetch,
  } = useQuery(QueryContentDrafts, {
    variables: {
      where: {
        _and: [
          { title: { _ilike: `%${query}%` } },
          loungeQuery
            ? { lounges: { lounge: { name: { _eq: loungeQuery } } } }
            : {},
        ],
      },
    },
  });

  const { data: loungeData } = useQuery(LoungeQuery, {
    variables: {
      search: {
        name: {
          _ilike: "%%",
        },
      },
    },
  });

  const bookCopy = _.map(bookData?.book, (book) => {
    const genre =
      book.genres?.length > 0
        ? {
            ...book?.genres[0]?.genre,
          }
        : null;
    const lounge =
      book.lounges?.length > 0
        ? {
            ...book?.lounges[0]?.lounge,
          }
        : null;

    // delete book.genres;
    delete book.lounges;

    return {
      ...book,
      genre,
      lounge,
    };
  });

  const contents = _.orderBy(
    [...bookCopy?.concat(contentDraftsData?.contentDrafts)],
    "createdAt",
    "desc"
  );

  const lounges = _.map(loungeData?.lounge, (lounge) => ({
    label: lounge?.name,
    value: lounge?.name,
  }));

  const books = useCallback(
    () =>
      contents?.filter((book) => book?.genre && book?.genre?.title === "Books"),
    [bookData, contents]
  );

  const articles = useCallback(
    () =>
      contents?.filter(
        (book) => book?.genre && book?.genre?.title === "Articles"
      ),
    [bookData, contents]
  );
  const blogs = useCallback(
    () =>
      contents?.filter((book) => book?.genre && book?.genre?.title === "Blogs"),
    [bookData, contents]
  );
  const magazines = useCallback(
    () =>
      contents?.filter(
        (book) => book?.genre && book?.genre?.title === "Magazines"
      ),
    [bookData, contents]
  );
  const drafts = useMemo(
    () => contents?.filter((book) => !book?.isPublished),
    [bookData, contents]
  );

  const handleQuery = ({ target: { value } }) => {
    setQuery(value);
  };

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    if (contentDraftsRefetch) {
      contentDraftsRefetch();
    }
  }, []);

  const handleSelect = (select) => {
    if (select.value === "All") {
      setLoungeQuery("");
      return;
    }
    setLoungeQuery(select?.value);
  };

  if (error?.toString()) {
    return <NetworkErrorComponent />;
  }

  return (
    <BookStyle>
      <Breadcrumbs title="Contents" breadcrumbItem="Contents" />
      <Flex>
        <form
          className="app-search d-none d-lg-flex"
          style={{
            maxWidth: 800,
            width: "100%",
          }}
        >
          <div
            className="position-relative"
            style={{
              maxWidth: 500,
              width: "100%",
            }}
          >
            <input
              type="text"
              className="form-control"
              style={{
                width: "100%",
              }}
              placeholder="Search books..."
              onChange={handleQuery}
            />
            <span className="bx bx-search-alt"></span>
          </div>
          <Select
            options={[{ label: "All", value: "All" }, ...lounges]}
            onChange={handleSelect}
            className="select"
          />
        </form>
        <Button color={"success"} onClick={() => history.push("/create-book")}>
          Create Content
        </Button>
      </Flex>
      <div>{error && error}</div>
      {bookLoading || contentDraftsLoading ? (
        <div
          style={{
            height: "50vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <ContentTab
          all={contents}
          books={books()}
          articles={articles()}
          blogs={blogs()}
          magazines={magazines()}
          drafts={drafts}
        />
      )}
    </BookStyle>
  );
};

const BookStyle = styled.div`
  padding: calc(10vh + 3%) 4% 50px 4%;
  height: 100vh;
  overflow-y: auto;

  .select {
    min-width: 200px;
    margin-left: 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

export default Book;
