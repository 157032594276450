import React from "react";
import { Button } from "reactstrap";

const NetworkErrorComponent = ({error}) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className='fas fa-exclamation-circle'
        style={{ fontSize: 60, color: "orangered" }}
      ></div>
      <p style={{ margin: "15px 0" }}>
        {error}
      </p>
      <Button onClick={() => window.location.reload()}>Try Again</Button>
    </div>
  );
};

export default NetworkErrorComponent;
