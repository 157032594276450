import React, { useContext, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import InviteUserForm from "components/InviteUserForm";
// import { UserContext } from "context/UserContext";
import CreateAdminAccount from "components/CreateAdminAccountForm";
import { UserContext } from "context/UserContext";
import InviteTeamMember from "components/InviteUserForm/InviteTeamMember";

const InviteButton = ({ label = "Invite Admin" }) => {
  const { isAdmin } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdminAccountOpen, setIsAdminAcountOpen] = useState(false);

  // const [state, setState] = useState({
  //   menu: false,
  //   name: "Add",
  // });

  // const toggle = () => {
  //   setState({
  //     ...state,
  //     menu: !state.menu,
  //   });
  // };

  return (
    <div>
      <div
        className="mr-3 text-white d-flex align-self-center"
        style={{ height: "100%" }}
      >
        <span
          className="mr-3 text-white d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(true)}
        >
          <i className="bx bx-user font-size-17 align-middle mr-1"></i>
          {label}
        </span>
      </div>

      <Modal isOpen={isOpen}>
        <ModalBody style={{ position: "relative" }}>
          <h3 className="text-center mb-5">
            {isAdmin ? "Invite Admin" : "Invite Team Member"}
          </h3>
          <span
            onClick={() => setIsOpen(!isOpen)}
            style={{
              fontSize: 25,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          {isAdmin && (
            <InviteUserForm
              setIsOpen={setIsOpen}
              setIsAdminAcountOpen={setIsAdminAcountOpen}
            />
          )}
          {!isAdmin && <InviteTeamMember setIsOpen={setIsOpen} />}
        </ModalBody>
      </Modal>
      <Modal isOpen={isAdminAccountOpen}>
        <ModalBody style={{ position: "relative" }}>
          <h3 className="text-center mb-5">Create Admin Account</h3>
          <span
            onClick={() => setIsAdminAcountOpen(!isOpen)}
            style={{
              fontSize: 25,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <CreateAdminAccount setIsOpen={setIsAdminAcountOpen} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InviteButton;
