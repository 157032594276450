/* eslint-disable sort-keys */
/* eslint-disable key-spacing */

export const token = process.env.REACT_APP_GRAPHQL_API_SECRET;

export const Config = {
	appCacheKey: '@app-cache',
	inviteTokenKey: '@invite-token',
	readingTimeKey: '@reading-time',
	pushTokenKey: '@push-token',
	userStorageKey: '@user-data',
	lastChatTimestampKey: '@user-last-message-time',
	chatNotification: 'chatNotification',

	// eslint-disable-next-lineno-process-env
	...(process.env.REACT_APP_ENV === 'development'
		? {
				graphqlUrl:
					'https://dysh-staging-nnf8w.ondigitalocean.app/hasura/v1/graphql',
				graphqlWsUrl:
					'wss://dysh-staging-nnf8w.ondigitalocean.app/hasura/v1/graphql',
				purchaseWebViewUrl:
					'https://dysh-staging-nnf8w.ondigitalocean.app/purchase',
				restApiUrl: 'https://dysh-staging-nnf8w.ondigitalocean.app/api',
				// restApiUrl: 'http://localhost:3000/api',
				newsApiToken: '222732fa11a9483ebc3aecb4567d7cbe',
				mpanelApiToken: '30f6465dbc14cbcf61f88a477994e3c1',
				instabugToken: '9297ab63393072a262d78908c3b997f4',
		  }
		: {
				graphqlUrl:
					'https://dysh-production-6taiv.ondigitalocean.app/hasura/v1/graphql',
				graphqlWsUrl:
					'ws://dysh-production-6taiv.ondigitalocean.app/hasura/v1/graphql',
				purchaseWebViewUrl:
					'https://dysh-production-6taiv.ondigitalocean.app/purchase',
				restApiUrl: 'https://dysh-production-6taiv.ondigitalocean.app/api',
				mpanelApiToken: '32fed1ab6fdff13ce7bc8f53bf09c276',
				instabugToken: '9297ab63393072a262d78908c3b997f4',
		  }),
};

export const Roles = {
	SUPERADMIN: 'SUPER-ADMIN',
	ADMIN: 'ADMIN',
	TEAMMEMBER: 'TEAM-MEMBER',
	VIEWER: 'VIEWER',
	LOUNGEOWNER: 'LOUNGE-OWNER',
};

export const Topics = {
	forAll: 'all',
};

export const bgNotiTappedKey = 'bgNoti';
export const quitNotiTappedKey = 'quitNoti';
