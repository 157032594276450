import gql from "graphql-tag";

export const ArchiveContentMutation = gql`
  mutation archiveContent($objects: [archiveContent_insert_input!]!) {
    insert_archiveContent(objects: $objects) {
      returning {
        id
        title
        author
      }
    }
  }
`;
