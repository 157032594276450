import gql from "graphql-tag";

export const UpdateProfileImage = gql`
  mutation updateAdminProfileImage($userId: uuid!, $set: user_set_input) {
    update_user(where: { id: { _eq: $userId } }, _set: $set) {
      returning {
        id
        firstName
        lastName
        username
        email
        avatarUrl
      }
    }
  }
`;
