import React from "react";
import { Flex } from "../style";

const DetailVisibility = ({
  create,
  setValues,
  setEditValues,
  values,
  editValues,
  group,
  privacy,
  setPrivacyLabel,
  privacyLabel,
  isLounge,
  editIcon,
  state,
  closeIcon,
}) => {
  const showVisibility = isLounge && !state?.privateState;

  return (
    <Flex borderBottom padding={`20px 5%`}>
      {showVisibility && (
        <Flex>
          <>
            <span>{create ? (group ? "Visible" : "Privacy") : "Privacy"}:</span>
            {!create && <span className='owner'>{privacyLabel}</span>}
          </>
          {editIcon({ ...state, privateState: true })}
        </Flex>
      )}

      {isLounge && state.privateState && (
        <Flex>
          <div className={`form-group ${state.privateState ? "" : "ml-5"}`}>
            {create ? (
              <select
                className='custom-select'
                onChange={({ target: { value } }) => {
                  setPrivacyLabel(value === "private" ? "Private" : "Public");
                  setValues({
                    ...values,
                    isPrivate: value === "private" ? true : false,
                  });
                }}
              >
                <option defaultValue>Choose Status</option>
                <option value='private'>Private</option>
                <option value='public'>Public</option>
              </select>
            ) : (
              <select
                className='custom-select'
                onChange={({ target: { value } }) => {
                  setPrivacyLabel(value === "private" ? "Private" : "Public");
                  setEditValues({
                    ...editValues,
                    isPrivate: value === "private" ? true : false,
                  });
                }}
              >
                <option defaultValue>Choose Status</option>
                <option value='private'>Private</option>
                <option value='public'>Public</option>
              </select>
            )}
          </div>
          {closeIcon && closeIcon({ ...state, privateState: false })}
        </Flex>
      )}

      {create && (
        <div className='form-group'>
          <select
            className='custom-select ml-4'
            onChange={({ target: { value } }) => {
              setPrivacyLabel(value === "private" ? "Private" : "Public");
              setValues({
                ...values,
                isPrivate: value === "private" ? true : false,
              });
            }}
          >
            <option defaultValue>Choose Status</option>
            <option value='private'>Private</option>
            <option value='public'>Public</option>
          </select>
        </div>
      )}
    </Flex>
  );
};

export default DetailVisibility;
