import gql from 'graphql-tag';

export const UpdateBookMutation = gql`
	mutation updateBook($where: book_bool_exp!, $set: book_set_input) {
		update_book(where: $where, _set: $set) {
			returning {
				id
				title
				author
			}
		}
	}
`;

export const UpdateBookWithIncMutation = gql`
	mutation updateBook($where: book_bool_exp!, $set: book_set_input, $inc: book_inc_input) {
		update_book(where: $where, _set: $set, _inc: $inc) {
			returning {
				id
				title
				author
			}
		}
	}
`;
