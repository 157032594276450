import gql from "graphql-tag";

export const GetAllLoungeOwnerApplication = gql`
  query loungeApplication {
    loungeOwnerApplications(
      where: {
        _and: [
          {
            _or: [
              { rejectedAt: { _is_null: true } }
              { acceptedAt: { _is_null: false } }
            ]
          }
          {
            _or: [
              { rejectedAt: { _is_null: false } }
              { acceptedAt: { _is_null: true } }
            ]
          }
        ]
      }
    ) {
      id
      requestingUserId
      description
      offeredLoungeName
      entityName
      rejectionReason
      createdAt
      updatedAt
      user {
        id
        username
        firstName
        lastName
        email
        avatarUrl
      }
    }

    loungeOwnerApplications_aggregate {
      aggregate {
        count
      }
    }
  }
`;
