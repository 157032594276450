/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import ProfileImage from "components/Common/ProfileImage";
import { UserContext } from "context/UserContext";
import { useQuery } from "react-apollo";
import { GetAUserQuery } from "graphql/a_user_query";

const ProfileMenu = (props) => {
  const { isAdmin, user } = useContext(UserContext);

  const { data: userData } = useQuery(GetAUserQuery, {
    variables: {
      userId: user?.id,
    },
  });

  const profile = userData?.user[0];

  const [state, setState] = useState({
    menu: false,
    name: "",
  });

  const history = useHistory();

  const toggle = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={state.menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <ProfileImage user={profile} isAdmin={isAdmin} />
          <span className="d-none d-xl-inline-block ml-2 mr-1">
            {state.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => history.push("/profile")}>
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t("Profile")}
          </DropdownItem>
          {/* <DropdownItem onClick={() => {}}>
            <i className='bx bx-wallet font-size-16 align-middle mr-1'></i>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem onClick={() => {}}>
            <span className='badge badge-success float-right mt-1'>5</span>
            <i className='bx bx-wrench font-size-17 align-middle mr-1'></i>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem onClick={() => {}}>
            <i className='bx bx-lock-open font-size-16 align-middle mr-1'></i>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(ProfileMenu));
