import gql from "graphql-tag";

export const GetRoles = gql`
  query getRoles($where: roles_bool_exp) {
    roles(where: $where) {
      id
      name
      description
    }
  }
`;
