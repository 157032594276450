import styled from 'styled-components';

export const SwitchAccountStyles = styled.div`
	.content {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #eff2f6;
		padding: 10px;
		&:hover {
			cursor: pointer;
			background-color: #eff2f6;
		}
	}

	.content-container {
		border: 1px solid #eff2f6;
		borderbottom: none;
	}

	img {
		height: 50px;
		width: 50px;
		background: #cccccc;
	}

	.right-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 20px;
	}
`;
