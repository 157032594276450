import React from "react";
import _ from "lodash";
import moment from "moment";
import { textTruncate } from "utils/textTruncate";
import ProfileTableImage from "components/Common/ProfileTableImage";

export const latestUsersArray = (latestUsersProp) => {
  const columns = [
    { id: 1, title: "Image" },
    { id: 2, title: "First Name" },
    { id: 3, title: "Last Name" },
    { id: 4, title: "Username" },
    { id: 5, title: "Email Address" },
    { id: 6, title: "Status" },
    { id: 7, title: "Time Joined" },
  ];

  const data = _.map(latestUsersProp, (user) => ({
    id: user?.id,
    image: <ProfileTableImage user={user} />,
    firstName: textTruncate(user?.firstName),
    lastNameName: textTruncate(user?.lastName),
    username: textTruncate(user?.username),
    email: textTruncate(user?.email),
    badge: (
      <span>
        {user?.isEmailConfirmed ? (
          <i className="bx bx-check-double bx-sm text-success"></i>
        ) : (
          <i className="bx bx-x bx-sm text-danger"></i>
        )}
      </span>
    ),
    date: moment(user?.createdAt).fromNow(),
  }));

  return {
    columns,
    data,
  };
};
