import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { useQuery } from 'react-apollo';
import { LoungeQuery } from 'graphql/lounges';
import _ from 'lodash';
import CustomSpinner from 'ui/CustomSpinner';
import { UserContext } from 'context/UserContext';

const TableSearchAndCreate = ({
	onChange,
	placeholder,
	btnLabel,
	route,
	inputStyle,
	isFilter = false,
	hideSearch,
	setQuery,
	filterList,
	loading,
	showExclusiveContent,
	setShowExclusiveContent,
}) => {
	const history = useHistory();
	const showButton = Boolean(btnLabel) && btnLabel !== 'Create Group';

	const isFilterList = !_.isEmpty(filterList);

	const { user, isAdmin } = useContext(UserContext);

	const { data: loungeData } = useQuery(LoungeQuery, {
		variables: {
			search: {
				_and: [
					{
						name: {
							_ilike: `%%`,
						},
					},
					!isAdmin ? { ownedByAccountId: { _eq: user?.adminAccountId } } : {},
					!isAdmin
						? {
								_or: [
									{ createdByAdminId: { _eq: user?.adminId } },
									{ updatedByAdminId: { _eq: user?.adminId } },
								],
						  }
						: {},
				],
			},
		},
		fetchPolicy: 'network-only',
	});

	const lounges = _.map(loungeData?.lounge, (lounge) => ({
		label: lounge?.name,
		value: lounge?.name,
	}));

	const handleSelect = (select) => {
		if (setQuery) {
			if (select.value === 'All') {
				setQuery('');
				return;
			}
			setQuery(select?.value);
		}
	};

	const loungeLists = [{ label: 'All', value: 'All' }, ...lounges];
	const filterListsData = filterList;

	return (
		<Flex>
			<form
				className='app-search d-none d-lg-flex'
				style={{
					width: '100%',
				}}
			>
				{hideSearch && (
					<div className='position-relative w-100'>
						<input
							type='text'
							className='form-control w-100'
							placeholder={placeholder}
							onChange={onChange}
							style={inputStyle}
						/>
						<span className='bx bx-search-alt'></span>
					</div>
				)}
				{isFilter && (
					<Select
						options={isFilterList ? filterListsData : loungeLists}
						onChange={handleSelect}
						className='select'
					/>
				)}
			</form>
			{showButton && (
				<>
					<Button
						onClick={setShowExclusiveContent}
						color='success'
						style={{ whiteSpace: 'nowrap', marginLeft: 20 }}
					>
						{showExclusiveContent ? 'Show All' : 'Show Only Exclusive Contents'}
					</Button>
					<Button
						onClick={() => history.push(route)}
						color='success'
						style={{ whiteSpace: 'nowrap', marginLeft: 20 }}
					>
						{btnLabel}
					</Button>
				</>
			)}
		</Flex>
	);
};

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;

	.select {
		min-width: 200px;
		margin-left: 20px;
	}
`;

export default TableSearchAndCreate;
