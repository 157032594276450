import gql from 'graphql-tag';

export const DELETE_ALL_PORTAL_NOTIFICATION = gql`
  mutation DELETE_ALL_PORTAL_NOTIFICATION {
    delete_portalNotification(where: {}) {
      affected_rows
    }
  }
`;

export const DELETE_ALL_NOTIFY_ADMIN = gql`
  mutation DELETE_ALL_NOTIFY_ADMIN {
    delete_notifyAdmin(where: {}) {
      affected_rows
    }
  }
`;
