import gql from 'graphql-tag';

export const GetOwnedLounges = gql`
	query getOwnedLounges($search: lounge_bool_exp, $limit: Int, $offset: Int) {
		lounge(
			where: $search
			limit: $limit
			offset: $offset
			order_by: { name: asc }
		) {
			id
			name
			imageUrl
			description
			isActive
			isPrivate
			createdAt
			updatedAt
			loungeUsers_aggregate {
				aggregate {
					count
				}
			}
			plans_aggregate {
				aggregate {
					count
				}
			}
			loungeBooks_aggregate {
				aggregate {
					count
				}
			}
			loungeUsers(order_by: { user: { createdAt: desc } }) {
				user {
					id
					lastName
					firstName
					username
					email
					createdAt
					avatarUrl
				}
			}
			plans {
				plan {
					id
					name
					description
					chatMessages_aggregate {
						aggregate {
							count
						}
					}
					books_aggregate {
						aggregate {
							count
						}
					}
				}
			}
		}

		lounge_aggregate {
			aggregate {
				count
			}
		}
	}
`;

export const LoungeOwnerLoungeUsersQuery = gql`
	query loungeUsers($adminId: uuid, $adminAccountId: uuid) {
		lounge(
			where: {
				_and: [
					{
						_and: [
							{
								_or: [
									{ createdByAdminId: { _eq: $adminId } }
									{ updatedByAdminId: { _eq: $adminId } }
								]
							}
							{ ownedByAccountId: { _eq: $adminAccountId } }
						]
					}
					{ loungeUsers: { id: { _is_null: false } } }
				]
			}
		) {
			id
			name
			loungeUsers(
				where: { lounge: { loungeUsers: { id: { _is_null: false } } } }
			) {
				user {
					id
					firstName
					lastName
					username
					email
					avatarUrl
					isEmailConfirmed
					createdAt
				}
			}
		}
	}
`;

// export const LoungeOwnerLoungeUsersQuery = gql`
//   query loungeUsers($adminId: uuid, $adminAccountId: uuid, $startDate: timestamptz, $endDate: timestamptz) {
//     lounge(
//       where: {
//         _and: [
//           {
//             loungeUsers: {
//               user: {
//                 _and: [
//                   { createdAt: { _gte: $startDate } }
//                   { createdAt: { _lte: $endDate } }
//                 ]
//               }
//             }
//           }
//           {
//             _and: [
//               {
//                 _or: [
//                   { createdByAdminId: { _eq: $adminId } }
//                   { updatedByAdminId: { _eq: $adminId } }
//                 ]
//               }
//               { ownedByAccountId: { _eq: $adminAccountId } }
//             ]
//           }
//           { loungeUsers: { id: { _is_null: false } } }
//         ]
//       }
//     ) {
//       id
//       name
//       loungeUsers(
//         where: { lounge: { loungeUsers: { id: { _is_null: false } } } }
//       ) {
//         user {
//           id
//           firstName
//           lastName
//           username
//           email
//           avatarUrl
//           isEmailConfirmed
//           createdAt
//         }
//       }
//     }
//   }
// `;
