/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import _ from "lodash";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import CountCard from "./commons/CountCard";
import { latestUsersArray } from "./data/latestUsers";
import { loungesWithMostUsers } from "./data/loungeWithMostUsersFile";
import { mostActiveGroup } from "./data/mostActiveGroup";
import { latestTransactionFile } from "./data/latestTransactionFile";
import Pages500 from "pages/Utility/pages-500";
import ErrorHandler from "utils/CatchError";
import { useSuperAdminQueries } from "./queries/superAdminDashboardQueries";
import { UserContext } from "context/UserContext";
import NewTableList from "components/TableList/NewTable";

const NewDashboard = () => {
  const { isAdmin } = useContext(UserContext);

  const {
    superAdminData: {
      users,
      usersData,
      loungeData,
      groupData,
      userPurchaseData,
      mostActiveGroupData,
      loungeWithMostUsersData,
      latestTransactionData,
      mostPuchaseBookData,
      booksData,
    },
  } = useSuperAdminQueries();

  const {
    superAdminLoading: {
      userLoading,
      bookLoading,
      loungeLoading,
      groupLoading,
      userPurchaseLoading,
      mostActiveLoading,
      loungeWithMostUsersLoading,
      latestTransactionLoading,
      userCountLoading,
      mostPurchaseLoading,
    },
  } = useSuperAdminQueries();

  const {
    superAdminError: { loungeError },
  } = useSuperAdminQueries();

  const loungeUsers = loungeWithMostUsersData?.lounge?.map((loungeUser) => {
    return {
      ...loungeUser,
      count: _.uniqBy(loungeUser?.loungeUsers, "user.username").length,
      loungeUsers: _.uniqBy(loungeUser?.loungeUsers, "user.username"),
    };
  });

  const { data, columns } = latestUsersArray(users?.user);
  const { data: loungeTableList, columns: loungeColumn } =
    loungesWithMostUsers(loungeUsers);
  const { data: mostActiveGroupTableList, columns: mostActiveGroupColumns } =
    mostActiveGroup(mostActiveGroupData?.plan);
  const {
    data: latestTransactionTableList,
    columns: latestTransactionColumns,
  } = latestTransactionFile(latestTransactionData?.userPurchase);

  const userCount = usersData?.user?.length;
  const bookCount = booksData?.book_aggregate?.aggregate?.count;
  const purchaseCount =
    userPurchaseData?.userPurchase_aggregate?.aggregate?.count;
  const groupCount = groupData?.plan_aggregate?.aggregate?.count;
  const loungeCountActive = loungeData?.lounge_aggregate?.aggregate?.count;

  const mostPurchaseTitle = mostPuchaseBookData?.userPurchase[0]?.book?.title;

  const error0 = ErrorHandler.getUserFriendlyError(loungeError?.toString());

  if (loungeError?.toString()) {
    return <Pages500 error={error0} />;
  }

  const countCards = [
    {
      id: 1,
      title: "User",
      count: userCount,
      loading: userCountLoading,
      icon: "user",
      col: "3",
      route: isAdmin && "/users",
    },
    {
      id: 2,
      title: "Lounge",
      count: loungeCountActive,
      loading: loungeLoading,
      icon: "file",
      col: "3",
      route: isAdmin ? "/lounges" : "/lounge-owner",
    },
    {
      id: 3,
      title: "Group",
      count: groupCount,
      loading: groupLoading,
      icon: "user",
      col: "3",
      route: isAdmin ? "/groups" : "/group-owner",
    },
    {
      id: 4,
      title: "Content",
      count: bookCount,
      loading: bookLoading,
      icon: "book",
      col: "3",
      route: isAdmin ? "/contents" : "/content-owner",
    },
    {
      id: 5,
      title: "Number Of Content Purchases",
      count: purchaseCount,
      loading: userPurchaseLoading,
      icon: "dollar-sign",
      col: "6",
      route: isAdmin && "/transactions",
    },
    {
      id: 6,
      title: "Most Purchased Book",
      count: mostPurchaseTitle,
      loading: mostPurchaseLoading,
      icon: "book",
      col: "6",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Row>
          {countCards?.map((props) => (
            <CountCard {...props} />
          ))}
        </Row>

        {isAdmin && (
          <NewTableList
            columns={columns}
            data={data}
            title="New Users (Past 30 days)"
            isSeeAll={true}
            goToSeeAll="/users"
          />
        )}

        <Row>
          <Col>
            <NewTableList
              columns={loungeColumn}
              data={loungeTableList}
              title="Most Popular Lounges By Users"
            />
          </Col>
          <Col>
            <NewTableList
              columns={mostActiveGroupColumns}
              data={mostActiveGroupTableList}
              title="Most Active Groups"
            />
          </Col>
        </Row>
        <NewTableList
          columns={latestTransactionColumns}
          data={latestTransactionTableList}
          title="Recent Transactions"
          isSeeAll={true}
          goToSeeAll="/transactions"
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: calc(10vh + 5%);
  padding-bottom: 5%;
  height: 100vh;
  overflow-y: auto;
`;

export default NewDashboard;
