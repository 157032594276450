import gql from "graphql-tag";

export const QueryALoungeOwnerApplication = gql`
  query queryALoungeOwnerApplication($applicationId: uuid) {
    loungeOwnerApplications(where: { id: { _eq: $applicationId } }) {
      id
      requestingUserId
      description
      offeredLoungeName
      entityName
      rejectionReason
      createdAt
      updatedAt
      user {
        id
        username
        firstName
        lastName
        email
        avatarUrl
      }
    }
  }
`;
