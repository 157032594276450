import { Colors } from "assets/colors/Colors";
import React from "react";

const ProfileImage = ({ user, isAdmin }) => {
  return (
    <div>
      {!user?.avatarUrl ? (
        <div className="avatar-sm mx-auto mb-4">
          <span
            className={"avatar-title rounded-circle font-size-16"}
            style={{
              backgroundColor: isAdmin
                ? Colors.dyshPurple
                : Colors.dyshPurpleLight100,
              color: isAdmin ? Colors.white : Colors.dyshPurple,
            }}
          >
            {user?.username ? user?.username?.slice(0, 1).toUpperCase() : null}
          </span>
        </div>
      ) : (
        <div className="mb-4">
          <img
            className="rounded-circle avatar-sm"
            src={user?.avatarUrl}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
