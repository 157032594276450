import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { textTruncate } from "utils/textTruncate";

const NewBreadcrumbs = ({
  breadcrumbItem,
  subBreadcrumbItem = breadcrumbItem,
  title,
  route,
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-30" style={{ whiteSpace: "nowrap" }}>
              {textTruncate(breadcrumbItem, 30)}
            </h1>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem>
                  <Link to={route}>
                    <span style={{ whiteSpace: "nowrap" }}>{title}</span>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">
                    <span style={{ whiteSpace: "nowrap" }}>
                      {textTruncate(subBreadcrumbItem, 30)}
                    </span>
                  </Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NewBreadcrumbs;
