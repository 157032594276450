import gql from "graphql-tag";

export const UpdateContentDraft = gql`
  mutation updateContentDrafts(
    $where: contentDrafts_bool_exp!
    $set: contentDrafts_set_input
  ) {
    update_contentDrafts(where: $where, _set: $set) {
      returning {
        id
        title
        author
      }
    }
  }
`;
