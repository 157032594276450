import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";

import { Config } from "./config";
import store from "./store";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import { UserProvider } from "./context/UserContext";
import { LoungePortalProvider } from "context/LoungePortalContext";

const token = process.env.REACT_APP_HASURA_TOKEN;

TimeAgo.addDefaultLocale(en);
// TimeAgo.addLocale(ru)

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: Config.graphqlUrl,
});
const link2 = setContext((_, { headers }) => ({
  headers: {
    "x-hasura-admin-secret": token,
  },
})).concat(link);

const client = new ApolloClient({
  cache,
  link: link2,
});

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <UserProvider>
        <LoungePortalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LoungePortalProvider>
      </UserProvider>
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById("root"));
