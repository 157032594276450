import gql from 'graphql-tag';

export const WithdrawLoungeOwnershipInvite = gql`
	mutation WithdrawLoungeOwnershipInvite($inviteId: uuid) {
		delete_loungeOwnershipInviteApplications(
			where: { invitedUserId: { _eq: $inviteId } }
		) {
			returning {
				id
			}
		}
	}
`;
