import { Colors } from "assets/colors/Colors";
import styled from "styled-components";

export const Container = styled.div`
  /* padding: 0; */
  height: 90vh;
  overflow-y: auto;

  i {
    font-size: 20px;
    cursor: pointer;
  }

  .bx-x {
    font-size: 25px;
    margin-left: 10px;
  }

  .bx-pencil {
    font-size: 15px;
    margin-left: 10px;
  }

  #name {
    width: 80%;
  }

  textarea {
    width: 100%;
  }

  .header-title {
    margin: 0;
    margin-right: 10px;
    padding: 0;
  }

  .owner {
    margin-left: 10px;
  }
`;

export const ContainerDiv = styled.div`
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  i {
    font-size: 20px;
    color: ${Colors.gray700};
    cursor: pointer;
  }

  .bx-x {
    font-size: 25px;
    margin-left: 10px;
  }

  .bx-pencil {
    font-size: 15px;
    margin-left: 10px;
  }

  #name {
    width: 80%;
  }

  textarea {
    width: 100%;
  }

  .header-title {
    margin: 0;
    margin-right: 10px;
    padding: 0;
  }

  .owner {
    margin-left: 10px;
  }
`;

export const Flex = styled.div`
  width: ${({ width }) => width && width};
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  align-items: ${({ align }) => (align ? align : "")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? "1px solid #eeeeee" : ""};
  padding: ${({ padding }) => padding && padding};
`;

export const FlexDiv = styled.div`
  width: ${({ width }) => width && width};
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  align-items: ${({ align }) => (align ? align : "")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? "1px solid #eeeeee" : ""};
  padding: ${({ padding }) => (padding ? padding : "20px 5%")};
`;
