import React, { useState } from "react";
import { Label } from "reactstrap";
import { FlexDiv } from "../style";

const NewTextarea = ({ description, values, setValues }) => {
  const name = description?.name;

  const [state, setState] = useState(false);

  const editPage =
    (description?.status === "edit" && !state) || description?.type === "text";

  const isCreate = description?.status === "create";
  const isEdit = description?.status === "edit" && state;

  return (
    <div>
      {editPage ? (
        <FlexDiv direction="column" borderBottom>
          <div className="d-flex align-items-start">
            <Label>{description?.label}</Label>
            {!isCreate && description?.isEditable && (
              <i
                onClick={() => setState(true)}
                className="bx bx-pencil ml-3"
              ></i>
            )}
          </div>
          <span style={{ whiteSpace: "wrap" }}>{description?.value}</span>
        </FlexDiv>
      ) : (
        isCreate && (
          <FlexDiv
            direction={description?.status === "create" ? "column" : "row"}
            borderBottom
          >
            <Label>{description?.label}</Label>
            <textarea
              className="form-control"
              id="projectdesc"
              style={{ width: "100%" }}
              rows="3"
              value={values[name]}
              placeholder={description?.placeholder}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  [`${name}`]: value,
                });
              }}
            ></textarea>
          </FlexDiv>
        )
      )}
      {isEdit && (
        <FlexDiv direction="column" borderBottom>
          <Label>{description?.label}</Label>
          <div className="d-flex">
            <textarea
              className="form-control"
              id="projectdesc"
              style={{ width: "100%" }}
              rows="3"
              value={values[name]}
              placeholder={description?.placeholder}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  [`${name}`]: value,
                });
              }}
            ></textarea>
            <i
              onClick={() => setState(false)}
              className="fas fa-times ml-3"
            ></i>
          </div>
        </FlexDiv>
      )}
    </div>
  );
};

export default NewTextarea;
