import gql from "graphql-tag";

export const UserPurchaseQuery = gql`
  query userPurchaseQuery {
    userPurchase {
      id
      receiptUrl
      user {
        id
        firstName
        lastName
        username
      }
      book {
        id
        title
        description
        bookUrl
        imageUrl
      }
    }

    userPurchase_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const LoungeOwnerUserPurchaseQuery = gql`
  query userPurchaseQuery($adminId: uuid, $adminAccountId: uuid) {
    userPurchase(
      where: {
        book: {
          lounges: {
            lounge: {
              _and: [
                {
                  _or: [
                    { createdByAdminId: { _eq: $adminId } }
                    { updatedByAdminId: { _eq: $adminId } }
                  ]
                }
                { ownedByAccountId: { _eq: $adminAccountId } }
              ]
            }
          }
        }
      }
    ) {
      id
      receiptUrl
      user {
        id
        firstName
        lastName
        username
      }
      book {
        id
        title
        description
        bookUrl
        imageUrl
      }
    }

    userPurchase_aggregate(
      where: {
        book: {
          lounges: {
            lounge: {
              _and: [
                {
                  _or: [
                    { createdByAdminId: { _eq: $adminId } }
                    { updatedByAdminId: { _eq: $adminId } }
                  ]
                }
                { ownedByAccountId: { _eq: $adminAccountId } }
              ]
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
