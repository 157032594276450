import gql from 'graphql-tag';

export const QueryBooks = gql`
	query booksQuery($where: book_bool_exp) {
		book(where: $where, order_by: { createdAt: desc }) {
			id
			title
			description
			author
			imageUrl
			bookUrl
			priceValue
			priceCurrency
			isPublished
			numberOfTimesReshared
			resharedDate
			createdAt
			genres {
				genre {
					id
					title
				}
			}
			readers_aggregate {
				aggregate {
					count
				}
			}
			lounges {
				lounge {
					id
					name
					description
					createdAt
				}
			}
			plans {
				plan {
					name
				}
			}
			readStats {
				readCount
			}
		}
	}
`;

export const QueryContentDrafts = gql`
	query contentDraftsQuery($where: contentDrafts_bool_exp) {
		contentDrafts(where: $where, order_by: { createdAt: desc }) {
			id
			title
			description
			author
			imageUrl
			bookUrl
			priceValue
			priceCurrency
			isPublished
			createdAt
			genre {
				id
				title
			}
			lounge {
				id
				name
				description
				createdAt
			}
		}
	}
`;

export const ContentForLoungeOwnerQuery = gql`
	query queryContents($search: book_bool_exp) {
		book(where: $search) {
			id
			title
			description
			author
			imageUrl
			bookUrl
			priceValue
			priceCurrency
			createdAt
			genres {
				genre {
					id
					title
				}
			}
			readers_aggregate {
				aggregate {
					count
				}
			}
			lounges {
				lounge {
					id
					name
					description
					createdAt
				}
			}
			plans {
				plan {
					name
				}
			}
		}
	}
`;

export const BookAggregate = gql`
	query bookAggregate {
		book_aggregate {
			aggregate {
				count
			}
		}
	}
`;

export const LoungeOwnerBookAggregate = gql`
	query bookAggregate($adminId: uuid, $adminAccountId: uuid) {
		book_aggregate(
			where: {
				lounges: {
					lounge: {
						_and: [
							{
								_or: [
									{ createdByAdminId: { _eq: $adminId } }
									{ updatedByAdminId: { _eq: $adminId } }
								]
							}
							{ ownedByAccountId: { _eq: $adminAccountId } }
						]
					}
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const LoungePortalBookAggregate = gql`
	query bookAggregate($loungeId: uuid) {
		book_aggregate(where: { lounges: { lounge: { id: { _eq: $loungeId } } } }) {
			aggregate {
				count
			}
		}
	}
`;

export const LoungePortalContentDraftsAggregate = gql`
	query contentDraftsAggregate($loungeId: uuid) {
		contentDrafts_aggregate(where: { lounge: { id: { _eq: $loungeId } } }) {
			aggregate {
				count
			}
		}
	}
`;
