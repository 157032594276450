import React from "react";
import _ from "lodash";
import { FormGroup, Col, Label } from "reactstrap";
import Select from "react-select";

import { Flex } from "../style";

const DetailLounge = ({
  values,
  setValues,
  data,
  contentObj,
  create,
  state,
  setState,
  editIcon,
  closeIcon,
  editValues,
  setEditValues,
  edit
}) => {
  let lounges = data["lounges"];

  lounges = _.map(lounges, (lounge) => ({
    label: lounge?.name,
    value: lounge?.id,
  }));

  return (
    <Flex borderBottom padding={`20px 5%`}>
      {!create && <span>Lounge: {contentObj?.lounge?.name ?? "N/A"}</span>}
      {/* {!create && !state?.loungeState && (
        <Flex width='100%'>
          {<span>Lounge: {contentObj?.lounge?.name ?? "N/A"}</span>}
          {!state?.loungeState && editIcon({ ...state, loungeState: true })}
        </Flex>
      )} */}

      {create && (
        <FormGroup className='select2-container w-100 row'>
          <Label className='col-2 mr-3'>Lounge: </Label>
          <Col>
            <Select
              options={lounges}
              style={{ width: "30% !important" }}
              onChange={({ value }) =>
                setValues({
                  ...values,
                  loungeQuery: value,
                })
              }
            />
          </Col>
        </FormGroup>
      )}

      {/* <Flex width='100%'>
        {!create && edit && state?.loungeState && (
          <FormGroup className='select2-container w-100 row'>
            <Label className='col-2'>Lounge: </Label>
            <Col>
              <Select
                options={lounges}
                style={{ width: "100% !important" }}
                onChange={({ value }) =>
                  setEditValues({
                    ...editValues,
                    loungeQuery: value,
                  })
                }
              />
            </Col>
          </FormGroup>
        )}
        {state?.loungeState && closeIcon({ ...state, loungeState: false })}
      </Flex> */}
    </Flex>
  );
};

export default DetailLounge;
