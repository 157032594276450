import gql from 'graphql-tag';

export const MostActiveGroupQuery = gql`
	query mostActiveGroup($limit: Int) {
		plan(order_by: { chatMessages_aggregate: { count: desc } }, limit: $limit) {
			id
			name
			chatMessages_aggregate {
				aggregate {
					count
				}
			}
			chatMessages(order_by: { updatedAt: desc }) {
				id
				message
				createdAt
			}
			books {
				book {
					title
					id
					imageUrl
					bookUrl
					author
				}
			}
			users_aggregate(
				where: {
					declinedAt: { _is_null: true }
					acceptedAt: { _is_null: false }
				}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const LoungeOwnerMostActiveGroup = gql`
	query mostActiveGroup(
		$where: plan_bool_exp
		$adminId: uuid
		$adminAccountId: uuid
	) {
		plan(where: $where, order_by: { chatMessages_aggregate: { count: desc } }) {
			id
			name
			chatMessages_aggregate {
				aggregate {
					count
				}
			}
			users_aggregate(
				where: {
					declinedAt: { _is_null: true }
					acceptedAt: { _is_null: false }
				}
			) {
				aggregate {
					count
				}
			}
			chatMessages(order_by: { updatedAt: desc }) {
				id
				message
				createdAt
			}
			books {
				book {
					title
					id
					imageUrl
					bookUrl
					author
				}
			}
		}
	}
`;
