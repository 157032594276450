import { useMutation } from "@apollo/react-hooks";
import { DetailLayoutView } from "components/DetailView";
import NewDetailView from "components/DetailView/NewDetailScreen";
import { Config } from "config";
import { UserContext } from "context/UserContext";
import { AddLoungeMutation } from "graphql/insert/insert_lounge";
import { UsersQuery } from "graphql/users";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { errorNotify, successNotify } from "utils/toaster";
import { enableOptions } from "./static/activeOrPrivate";

const defaultImage =
  "https://readapp.ams3.digitaloceanspaces.com/example/lounge/dysh-app-icon.png";

const CreateLounge = () => {
  const { user, isAdmin } = useContext(UserContext);
  const [queryUsername, setQueryUsername] = useState("");

  const [preview, setPreview] = useState();
  const [imageUrlFile, setImageUrlFile] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    imageUrl: "",
    isPrivate: false,
    isActive: false,
    user: {},
  });

  const { data } = useQuery(UsersQuery, {
    variables: {
      search: {
        _or: [
          values.user?.value
            ? { username: { _ilike: `%${values.user?.label}%` } }
            : {},
        ],
      },
      limit: 5,
    },
  });

  const users = data?.user;

  const history = useHistory();
  const [addLounge, { loading: addLoungeLoading }] =
    useMutation(AddLoungeMutation);

  const handleAddLounge = async () => {
    const { name, description, imageUrl, isPrivate, isActive, userId } = values;
    try {
      const form = new FormData();
      form.append("imageUrl", imageUrlFile);

      setImageLoading(true);

      const res = await fetch(`${Config.restApiUrl}/uploads/lounge`, {
        method: "POST",
        "Content-type": "multipart/form-data",
        body: form,
      });

      const response = await res.json();
      setImageLoading(false);

      const imageUrlResponse = response?.data?.imageUrl;

      if (name && description) {
        if (user?.id) {
          const response = await addLounge({
            variables: {
              objects: {
                name,
                description,
                imageUrl: imageUrlResponse ? imageUrlResponse : defaultImage,
                isPrivate: isPrivate?.value === "private" ? true : false,
                isActive: isActive?.value === "active" ? true : false,
                userId: userId ? userId : user?.id,
                // createdByAdminId: values?.userId,
                // ownedByAccountId:
              },
            },
          });

          const successMessage = `${response?.data?.insert_lounge?.returning[0]?.name} added successfully`;
          successNotify(successMessage);

          if (isAdmin) {
            history.push("/lounges");
          } else {
            history.push("/lounge-owner");
          }
        }
      } else {
        errorNotify("Some require fields are missing");
      }
    } catch (error) {
      const err = error?.toString()?.split(":")[2]?.trim();
      errorNotify(err || "Something went wrong");
    }
  };

  const handleFile = async ({ target: { files } }) => {
    const reader = new FileReader();
    if (files) {
      reader.readAsDataURL(files[0]);
    }

    reader.onloadend = function (e) {
      setPreview(reader.result);
    };

    setImageUrlFile(files[0]);
  };

  const userOptions = _.map(users, (user) => ({
    id: user?.id,
    label: user?.username,
    value: user?.id,
  }));

  return (
    <Wrapper>
      <Row>
        <Col>
          <DetailLayoutView
            image={preview}
            onChange={handleFile}
            imageLoading={imageLoading}
            breadcrumbTitle="Lounges"
            breadcrumbItem="Create Lounge"
            breadcrumbRoute={isAdmin ? "/lounges" : "/lounge-owner"}
          >
            <NewDetailView
              key1={{
                label: "Lounge Name",
                placeholder: "Lounge Name",
                name: "name",
                type: "input",
                status: "create",
              }}
              key2={{
                label: "Owner",
                options: userOptions,
                name: "user",
                status: "create",
                type: "search",
              }}
              key3={{
                label: "Lounge Description",
                placeholder: "Lounge Description",
                name: "description",
                type: "description",
                status: "create",
              }}
              // key4={{
              //   label: "Privacy",
              //   options: privacyOptions,
              //   name: "isPrivate",
              //   type: "select",
              //   status: "create",
              // }}
              key5={{
                label: "Status",
                options: enableOptions,
                type: "select",
                name: "isActive",
                status: "create",
              }}
              action={{
                type: "action",
                actionText: {
                  btn3Text: "Create",
                  status: "create",
                },
                action: {
                  btn3: handleAddLounge,
                  status: "create",
                },
                actionLoading: {
                  btn3Loading: addLoungeLoading,
                },
              }}
              values={values}
              setValues={setValues}
              query={queryUsername}
              setQuery={setQueryUsername}
            />
          </DetailLayoutView>
        </Col>
      </Row>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  margin-top: 10vh;
  padding: 0;
`;

export default CreateLounge;
