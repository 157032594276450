import React, { memo, useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Tabs, List, Typography, Button, Pagination } from 'antd';
import { useMutation, useQuery } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';
import { NOTIFY_ADMIN, NOTIFY_ADMIN_AGGREGATE } from 'graphql/query/portalNotification';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { DELETE_ALL_NOTIFY_ADMIN } from 'graphql/delete/delete_portal_notification';
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const { TabPane } = Tabs;

function callback(key) {}

const Header = ({ hasData }) => {
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [deleteAllAdminNotification, { loading: deleting }] = useMutation(DELETE_ALL_NOTIFY_ADMIN);

  async function handleDeleteAll() {
    try {      
      setDeleteAllModal(false);
      await deleteAllAdminNotification({
        refetchQueries: ['NOTIFY_ADMIN']
      });
    } catch(e) {
      console.log(e.message)
    }
  }

  return (
    hasData && <div>
    <Button 
      onClick={() => setDeleteAllModal(true)}
      type="primary" 
      style={{ background: '#5039C8', border: 'none' }} 
      shape="round" 
      icon={
        deleting ? (<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i>)
        : (<i class="fa fa-trash" aria-hidden="true"></i>)
        } size={30}>
        &nbsp;&nbsp;Delete All
    </Button>


    <Modal isOpen={deleteAllModal} centered={false}>
      <ModalHeader>
        Do you really want to Delete All notification?
      </ModalHeader>
      <ModalFooter>
        <Button
          color="warning"
          onClick={handleDeleteAll}
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={() => setDeleteAllModal(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </div>
)}

const NotificationTab = memo(() => {
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const pageSize = 8;
  const offset = (currentPage - 1) * pageSize;
  const { user } = useContext(UserContext);
  const { data, refetch } = useQuery(NOTIFY_ADMIN, {
    variables: { limit: pageSize, offset },
  });
  const { data: notifyAdminAgg } = useQuery(NOTIFY_ADMIN_AGGREGATE);

  useLayoutEffect(() => {
    if (process.env.REACT_APP_ENV === 'development' && user?.id !== "09bf00a8-ebc3-49a9-8202-cf215189d073") {
      history.push('/');
    } else if (process.env.REACT_APP_ENV === 'production' && user?.id !== "2938db47-439f-4035-8f18-b538f62ccd7d") {
      history.push('/');
    }
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    refetch({ limit: pageSize, offset });
  }, [currentPage, refetch]);

  return (
    <StyledTabs defaultActiveKey="1" onChange={callback}>
    <StyledTabPane tab={<StyledTab>All Notifications</StyledTab>} key="1">
      <List
        size="large"
        header={<Header hasData={!!data?.notifyAdmin[0]}/>}
        footer={!!data?.notifyAdmin[0] && <StyledPagination 
          current={currentPage}
          pageSize={pageSize}
          total={notifyAdminAgg?.notifyAdmin_aggregate?.aggregate?.count}
          onChange={handlePageChange}
          showLessItems={true}
        />}
        bordered
        style={{
          background: 'white',
        }}
        dataSource={data?.notifyAdmin}
        renderItem={(item, index) => (
          <StyledListItem key={index}>
            <ListItemOuterContainer>
              <ListItemInnerContainer>
                <i className="fa fa-circle" 
                style={{ color: '#5039C8', marginRight: 10}} 
                aria-hidden="true"></i>
                <Typography>{item?.message}</Typography>
              </ListItemInnerContainer>
              <Typography style={{ alignSelf: 'flex-start'}}>{moment(item?.createdAt).fromNow()}</Typography>
            </ListItemOuterContainer>
          </StyledListItem>
        )}
      />
    </StyledTabPane>
  </StyledTabs>
  )
});

const NotifyAdmin = () => {
  return (
    <NotificationWrapper>
      <Breadcrumbs title="Notifications" breadcrumbItem="Notifications" />
      <NotificationTab />
    </NotificationWrapper>
  )
}

const NotificationWrapper = styled.div`
  padding: 8% 5% 5% 2%;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: black;
  }
`;

const StyledTabPane = styled(TabPane)`
  color: black;
`;

const StyledTab = styled.span`
  color: black;
`;

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;

const ListItemOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ListItemInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    border-color: #5039C8;
  }
  .ant-pagination-item a {
    color: #5039C8;
  }
  .ant-pagination-item-active {
    border-color: #5039C8;
    font-weight: bold;
  }
  .ant-pagination-item-active a {
    color: #5039C8;
  }
`;

export default NotifyAdmin
