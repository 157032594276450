import { useMutation, useQuery } from '@apollo/react-hooks';
import { DetailLayoutView } from 'components/DetailView';
import React, { useContext, useState } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Col,
	Row,
	Spinner,
} from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { AddBookMutation } from 'graphql/insert/insert_book';
import { errorNotify, successNotify } from 'utils/toaster';
import { AddBookGenreMutation } from 'graphql/insert/insert_book_genre';
import { AddBookToLoungeMutation } from 'graphql/insert/insert_lounge_book';
import { GetGenreQuery } from 'graphql/genre';
import { LoungeQuery } from 'graphql/lounges';
import { Config } from '../../config';
import { getExtName } from 'utils/getExtName';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { priceCurrencyOptions } from './static/currency';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { AddToContentDraftMutation } from 'graphql/insert/insert_contentDrafts';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { RemoveExistingCover } from 'graphql/update/remove_book_cover';

const CreateContent = () => {
	const history = useHistory();

	const { isAdmin, user } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);

	const [preview, setPreview] = useState('');
	const [bookUrlFile, setBookUrlFile] = useState();
	const [bookPreview, setBookPreview] = useState();
	const [imageUrlFile, setImageUrlFile] = useState();

	const [publishModal, setPublishModal] = useState(false);
	const [draftModal, setDraftModal] = useState(false);

	const [values, setValues] = useState({
		title: '',
		author: '',
		description: '',
		priceValue: '',
		priceCurrency: '',
		isWebReadable: false,
		isExclusive: false,
		isLoungeCover: false,
		loungeQuery: '',
		genreQuery: '',
		exclusiveId: null,
	});
	const [, setBookId] = useState('');

	const [addBookGenre, { loading: bookGenreLoading }] =
		useMutation(AddBookGenreMutation);
	const [addBookToLounge, { loading: bookLoungeLoading }] = useMutation(
		AddBookToLoungeMutation
	);
	const [addBook, { loading: bookLoading }] = useMutation(AddBookMutation);
	const [addToContentDraft, { loading: contentDraftLoading }] = useMutation(
		AddToContentDraftMutation
	);
	const [removeExistingBookCover] = useMutation(RemoveExistingCover);

	const { data: genreData } = useQuery(GetGenreQuery);
	// const { data: loungeData } = useQuery(LoungeQuery, {
	//   variables: {
	//     search: {
	//       name: {
	//         _ilike: `%${values?.loungeQuery}%`,
	//       },
	//     },
	//   },
	// });

	const { data: loungeData } = useQuery(LoungeQuery, {
		variables: {
			search: {
				_and: [
					{
						name: {
							_ilike: `%%`,
						},
					},
					!isAdmin ? { id: { _eq: loungeId } } : {},
				],
			},
		},
	});

	let genres = genreData?.genre;

	let YesOrNoSelection = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];

	let lounges = loungeData?.lounge;

	genres = _.map(genres, (genre) => ({
		label: genre?.title,
		value: genre?.id,
	}));

	lounges = _.map(lounges, (lounge) => ({
		label: lounge?.name,
		value: lounge?.id,
	}));

	const handleImageUpload = async ({ target: { files } }) => {
		const imageSizeLimit = 500;
		const reader = new FileReader();

		if (files) {
			reader.readAsDataURL(files[0]);
		}

		const imageSize = files[0]?.size / 1024;

		if (imageSize > imageSizeLimit) {
			return errorNotify(
				'The size of the image should be less than equal to 500kb'
			);
		}

		setImageUrlFile(files[0]);

		reader.onloadend = function (e) {
			setPreview(reader.result);
			// const image = new Image();
			// image.src = e.target?.result;

			// image.onload = function () {
			//   if (!(this.height > this.width + 200)) {
			//     return errorNotify("Please upload a portrait image");
			//   }
			//   setImageUrlFile(files[0]);
			// };
		};
	};

	const handleContentUpload = async ({ target: { files } }) => {
		const read = URL.createObjectURL(files[0]);
		const extName = getExtName(files[0]);
		const contentSize = files[0]?.size / 1000;
		const max = 3000;
		if (contentSize > max) {
			return errorNotify('Content size should be less than 3mb');
		}
		if (!(extName === 'pdf' || extName === 'epub')) {
			return errorNotify('Please upload a valid content');
		}
		setBookPreview(read);
		setBookUrlFile(files[0]);
	};

	const handleAddBook = async () => {
		const {
			title,
			author,
			description,
			priceValue,
			priceCurrency,
			isWebReadable,
			isExclusive,
			isLoungeCover,
			genreQuery,
			loungeQuery,
			exclusiveId,
		} = values;

		if (!bookUrlFile) {
			return errorNotify(
				'Please upload a content or check the size of content uploaded'
			);
		}

		if (!imageUrlFile) {
			return errorNotify(
				'Please upload an image or check the size of image uploaded'
			);
		}

		const form = new FormData();
		form.append('bookUrl', bookUrlFile);
		form.append('imageUrl', imageUrlFile);

		try {
			if (title && author && description) {
				const res = await fetch(`${Config.restApiUrl}/uploads/content`, {
					method: 'POST',
					'Content-type': 'multipart/form-data',
					body: form,
				});

				const response = await res.json();

				const bookUrl = response?.data?.bookUrl;
				const imageUrl = response?.data?.imageUrl;

				// successNotify('Content uploaded successfully')

				if (isLoungeCover?.value) {
					await removeExistingBookCover({
						variables: {
							loungeId,
						},
					});
				}

				if (bookUrl && imageUrl) {
					const res = await addBook({
						variables: {
							objects: {
								title,
								author,
								description,
								bookUrl,
								imageUrl,
								priceValue: priceValue ? parseFloat(priceValue) : 0,
								priceCurrency: priceCurrency?.value,
								isWebReadable: isWebReadable?.value,
								isExclusive: isExclusive?.value,
								isLoungeCover: isLoungeCover?.value,
								userId: exclusiveId ? exclusiveId : null,
								publishedBy: `${user.firstName} ${user.lastName}`,
								loungeId: loungeQuery?.value
							},
						},
					});

					setBookId(res?.data?.insert_book?.returning[0]?.id);

					const bookId = res?.data?.insert_book?.returning[0]?.id;

					await addBookGenre({
						variables: {
							objects: {
								genreId: genreQuery?.value,
								bookId,
							},
						},
					});

					await addBookToLounge({
						variables: {
							objects: {
								loungeId: loungeQuery?.value,
								bookId,
							},
						},
					});

					const successMessage = `${res?.data?.insert_book?.returning[0]?.title} added successfully`;

					history.push(isAdmin ? '/contents' : '/content-owner');
					successNotify(successMessage);
				} else {
					errorNotify('Content not uploaded');
				}
			} else {
				errorNotify('Some fields are required');
			}
		} catch (error) {
			const err = error?.toString()?.split(':')[2]?.trim();
			errorNotify(err || 'Something went wrong');
		}
	};

	const handleContentDraft = async () => {
		const {
			title,
			author,
			description,
			priceValue,
			priceCurrency,
			isWebReadable,
			isExclusive,
			isLoungeCover,
			genreQuery,
			loungeQuery,
			exclusiveId,
		} = values;

		const form = new FormData();
		form.append('bookUrl', bookUrlFile);
		form.append('imageUrl', imageUrlFile);

		try {
			if (title && author && description) {
				const res = await fetch(`${Config.restApiUrl}/uploads/content`, {
					method: 'POST',
					'Content-type': 'multipart/form-data',
					body: form,
				});

				const response = await res.json();

				const bookUrl = response?.data?.bookUrl;
				const imageUrl = response?.data?.imageUrl;

				// successNotify('Content uploaded successfully')

				if (bookUrl && imageUrl) {
					const res = await addToContentDraft({
						variables: {
							objects: {
								title,
								author,
								description,
								bookUrl,
								imageUrl,
								genreId: genreQuery?.value,
								loungeId: loungeQuery?.value,
								priceValue: priceValue ? parseFloat(priceValue) : 0,
								priceCurrency: priceCurrency?.value,
								isWebReadable: isWebReadable?.value,
								isExclusive: isExclusive?.value,
								isLoungeCover: isLoungeCover?.value,
								userId: exclusiveId ? exclusiveId : null,
							},
						},
					});

					setBookId(res?.data?.insert_contentDrafts?.returning[0]?.id);

					successNotify(
						`Save ${res?.data?.insert_contentDrafts?.returning[0]?.title} to draft.`
					);
					history.push(isAdmin ? '/contents' : '/content-owner');
				} else {
					errorNotify('Content not uploaded');
				}
			} else {
				errorNotify('Some fields are required');
			}
		} catch (error) {
			const err = error?.toString()?.split(':')[2]?.trim();
			errorNotify(err || 'Something went wrong');
		}
	};

	const finalLoading = bookGenreLoading || bookLoungeLoading || bookLoading;

	return (
		<Wrapper>
			<Row>
				<Col>
					<DetailLayoutView
						image={preview}
						onChange={handleImageUpload}
						uploadName='Content'
						breadcrumbTitle='Content'
						breadcrumbItem='Create Content'
						breadcrumbRoute={isAdmin ? '/contents' : '/content-owner'}
					>
						<NewDetailView
							key1={{
								label: 'Content Title',
								placeholder: 'Content Title',
								name: 'title',
								status: 'create',
								type: 'input',
							}}
							key2={{
								label: 'Content Author',
								placeholder: 'Content Author',
								name: 'author',
								status: 'create',
								type: 'input',
							}}
							key3={{
								label: 'Content Description',
								placeholder: 'Content Description',
								name: 'description',
								status: 'create',
								type: 'description',
							}}
							key4={{
								label: 'Upload Content limit(3mb)',
								show: true,
								type: 'upload',
								handleUpload: handleContentUpload,
								status: 'create',
							}}
							key5={{
								label: 'Genre',
								status: 'create',
								name: 'genreQuery',
								type: 'select',
								options: genres,
							}}
							key10={{
								label: 'Is Exclusive',
								status: 'create',
								name: 'isExclusive',
								type: 'select',
								options: YesOrNoSelection,
							}}
							key11={{
								label:
									'Make this content your (Exclusive Lounge Cover Content) ?',
								status: 'create',
								name: 'isLoungeCover',
								type: 'select',
								options: YesOrNoSelection,
							}}
							key6={{
								label: 'Web Readable',
								status: 'create',
								name: 'isWebReadable',
								type:
									bookUrlFile != null && bookUrlFile['name'].slice(-4) == '.pdf'
										? 'select'
										: '',
								options: YesOrNoSelection,
							}}
							key7={{
								label: 'Lounge',
								status: 'create',
								name: 'loungeQuery',
								type: 'select',
								options: lounges,
							}}
							key8={{
								label: 'Price Value',
								show: true,
								placeholder: 'Price Value',
								name: 'priceValue',
								status: 'create',
								type: values.genreQuery?.label === 'Books' ? 'input' : '',
							}}
							key9={{
								label: 'Price Currency',
								show: true,
								placeholder: 'Price Currency',
								name: 'priceCurrency',
								status: 'create',
								options: priceCurrencyOptions,
								type: values.genreQuery?.label === 'Books' ? 'select' : '',
							}}
							action={{
								type: 'action',
								actionText: {
									btn1Text: bookUrlFile ? 'Preview' : '',
									btn2Text: 'Save To Draft',
									btn3Text: 'Create',
								},
								action: {
									btn2: () => setDraftModal(true),
									btn3: () => setPublishModal(true),
								},
								// actionLoading: {
								//   btn2Loading: contentDraftLoading,
								//   btn3Loading: finalLoading,
								// },
							}}
							previewUrl={bookPreview}
							values={values}
							setValues={setValues}
						/>
					</DetailLayoutView>
				</Col>
			</Row>

			{/* publish publishModal */}
			<Modal isOpen={publishModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to publish {values.title?.toUpperCase()}</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setPublishModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							handleAddBook(false);
							setPublishModal(false);
						}}
					>
						{finalLoading ? (
							<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			{/* draft publishModal */}
			<Modal isOpen={draftModal} centered={true}>
				<ModalBody>
					<p>
						Are you sure you want to save {values.title?.toUpperCase()} to Draft
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setDraftModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							handleContentDraft();
							setDraftModal(false);
						}}
					>
						{contentDraftLoading ? (
							<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<ToastContainer />
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	margin-top: 10vh;
`;

export default CreateContent;
