/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { LoungeQuery } from "graphql/lounges";
import { UpdateLoungeMutation } from "graphql/update/updateLounge";
import DetailTitle from "./detailComponents/DetailTitle";
import DetailOwner from "./detailComponents/DetailOwner";
import DetailDescription from "./detailComponents/DetailDescription";
import DetailVisibility from "./detailComponents/DetailVisibility";
import DetailEnable from "./detailComponents/DetailEnable";
import DetailOthers from "./detailComponents/DetailOthers";
import DetailActionBtn from "./detailComponents/DetailActionBtn";
import { Container, Flex } from "./style";
import { UpadatePlanMutation } from "graphql/update/updatePlan";
import ErrorHandler from "utils/CatchError";
import Pages500 from "pages/Utility/pages-500";
import DetailGenre from "./detailComponents/DetailGenre";
import DetailLounge from "./detailComponents/DetailLounge";
import DetailPriceValue from "./detailComponents/DetailPriceValue";
import DetailPriceCurrency from "./detailComponents/DetailPriceCurrency";
import { GetGenreQuery } from "graphql/genre";
import { UpdateBookMutation } from "graphql/update/updateBook";
import { UpdateBookGenreMutation } from "graphql/update/updateBookGenre";
import { errorNotify, successNotify } from "utils/toaster";
import DetailUpload from "./detailComponents/DetailUpload";
import { UserContext } from "context/UserContext";
import DetailExclusive from "./detailComponents/DetailExclusive";
import { Config } from "../../config";
import { getExtName } from "utils/getExtName";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";

const DetailView = ({
  lounge,
  group,
  book,
  title,
  owner,
  description,
  status,
  privacy,
  count1,
  count2,
  date,
  create,
  edit,
  isGroup,
  isLounge,
  isContent,
  handleCreate,
  setValues,
  handleContentUpload,
  values,
  loading,
  setSelected,
  placeholder,
  setQueryUsername,
  setLoungeQuery,
  contentObj,
  queryLounge,
  priceObj,
  file,
  isDraft,
}) => {
  const { user } = useContext(UserContext);

  const [updateLounge, { loading: updateLoungeLoading }] =
    useMutation(UpdateLoungeMutation);

  const [
    updateGroup,
    { loading: updateGroupLoading, error: updateGroupError },
  ] = useMutation(UpadatePlanMutation);

  const [updateBook, { loading: updateBookLoading, error: updateBookError }] =
    useMutation(UpdateBookMutation);

  const [
    updateBookGenre,
    { loading: updateBookGenreLoading, error: updateBookGenreError },
  ] = useMutation(UpdateBookGenreMutation);

  const { data: loungesData, loungeError } = useQuery(LoungeQuery, {
    fetchPolicy: "network-only",
  });

  const { data: genreData } = useQuery(GetGenreQuery);

  const genresForContents = genreData?.genre;
  const loungesForContents = loungesData?.lounge;

  const lounges = _.map(loungesData?.lounge, (lounge) => ({
    label: lounge?.name,
    value: lounge?.id,
  }));

  const [statusLabel, setStatusLabel] = useState(
    status?.status ? "Active" : "Inactive"
  );

  const [publishedStatus, setPublishedStatus] = useState(
    book?.isPublished ? "Live" : "Draft"
  );

  const [publishModal, setPublishModal] = useState(false);
  const [saveAsDraftModal, setSaveAsDraftModal] = useState(false);

  const [genreType, setGenreType] = useState("");

  const [priceValueLabel, setPriceValueLabel] = useState(priceObj?.price);
  const [priceCurrencyLabel, setPriceCurrencyLabel] = useState(
    priceObj?.currency
  );

  const [privacyLabel, setPrivacyLabel] = useState(
    privacy?.privacy ? "Private" : "Public"
  );
  const [open, setOpen] = useState(true);
  const [state, setState] = useState({
    loungeNameState: false,
    descriptionState: false,
    ownerState: false,
    statusState: false,
    exclusiveState: false,
    privateState: false,
    priceValueState: false,
    priceCurrencyState: false,
  });

  const [editValues, setEditValues] = useState({
    name: "",
    author: "",
    description: "",
    isPrivate: false,
    isActive: false,
    approvedAt: null,
    bookUrl: "",
    loungeQuery: "",
    genreQuery: "",
    userId: "",
    exclusiveId: null,
    priceValue: 0,
    priceCurrency: "gbp",
  });

  const collapse = () => setOpen(!open);

  const handleSelected = (select) => {
    if (setSelected) {
      setSelected(select?.value);
    }
  };

  const btnLabel = create ? "Create" : "Update";

  const editIcon = (obj) =>
    !create &&
    !isGroup && <i className="bx bx-pencil" onClick={() => setState(obj)}></i>;

  const closeIcon = (obj) =>
    !create &&
    !isGroup && <i className="bx bx-x" onClick={() => setState(obj)}></i>;

  useEffect(() => {
    if (!create) {
      setEditValues({
        name: title,
        author: owner?.author,
        description,
        exclusiveId: book?.userId,
      });
    }
  }, [state.loungeNameState, editValues.exclusiveId]);

  const handleUpdateContentUpload = async ({ target: { files } }) => {
    try {
      const form = new FormData();
      form.append("bookUrl", files[0]);

      const res = await fetch(
        `${Config.restApiUrl}/uploads/content/update-book-url`,
        {
          method: "POST",
          "Content-type": "multipart/form-data",
          body: form,
        }
      );

      const response = await res.json();

      const bookUrl = response?.data?.bookUrl;

      successNotify("Content uploaded successfully");

      if (bookUrl) {
        setEditValues({
          ...editValues,
          bookUrl,
        });
      }
    } catch (error) {}
  };

  // update lounge
  const handleUpdate = async () => {
    const {
      name,
      description,
      isPrivate,
      isActive,
      approvedAt,
      priceValue,
      priceCurrency,
      bookUrl,
      author,
      userId,
      exclusiveId,
      loungeQuery,
      genreQuery,
    } = editValues;
    let msgVar;
    try {
      if (isLounge && edit && lounge?.id) {
        if (editValues.name && editValues.description) {
          const response = await updateLounge({
            variables: {
              where: { id: { _eq: lounge?.id } },
              set: {
                name,
                description,
                isPrivate,
                isActive,
                approvedAt,
                updatedByAdminId: user?.adminId,
              },
            },
          });
          msgVar = response?.data?.update_lounge?.returning[0]?.name;
          successNotify(`Successfully updated ${msgVar} lounge.`);
        }
      } else if (isGroup && group?.id) {
        await updateGroup({
          variables: {
            where: { id: { _eq: group?.id } },
            set: {
              name,
              description,
            },
          },
        });
      } else if (isContent && book?.id) {
        const response = await updateBook({
          variables: {
            where: { id: { _eq: book?.id } },
            set: {
              title: name,
              author,
              description,
              priceCurrency,
              priceValue,
              bookUrl,
              userId: exclusiveId ? exclusiveId : null,
              isPublished: true,
            },
          },
        });

        setPublishedStatus("Live");

        msgVar = response?.data?.update_book?.returning[0]?.title;

        successNotify(`Successfully updated ${msgVar} lounge.`);
      }
    } catch (error) {
      errorNotify("Something went wrong, try again");
    }
  };

  const setAsDraft = async () => {
    const {
      name,
      description,
      priceValue,
      priceCurrency,
      bookUrl,
      author,
      exclusiveId,
    } = editValues;

    let msgVar;
    try {
      if (isContent && book?.id) {
        const response = await updateBook({
          variables: {
            where: { id: { _eq: book?.id } },
            set: {
              title: name,
              author,
              description,
              priceCurrency,
              priceValue,
              bookUrl,
              userId: exclusiveId ? exclusiveId : null,
              isPublished: false,
            },
          },
        });

        setPublishedStatus("Draft");

        msgVar = response?.data?.update_book?.returning[0]?.title;

        successNotify(`Successfully set ${msgVar} to draft.`);
      }
    } catch (error) {}
  };

  const error = ErrorHandler.getUserFriendlyError(loungeError?.toString());

  if (loungeError?.toString()) {
    return <Pages500 error={error} />;
  }

  const isExtName = getExtName(file) === "pdf" || getExtName(file) === "epub";

  const finalLoading =
    loading ||
    updateLoungeLoading ||
    updateGroupLoading ||
    updateBookLoading ||
    updateBookGenreLoading;

  return (
    <Container>
      {/* name section */}
      <DetailTitle
        state={state}
        setState={setState}
        create={create}
        title={title}
        editIcon={editIcon}
        closeIcon={closeIcon}
        editValues={editValues}
        setEditValues={setEditValues}
        values={values}
        setValues={setValues}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        loading={finalLoading}
        btnLabel={btnLabel}
        isGroup={isGroup}
        placeholder={placeholder}
      />
      {/* owner section */}
      <DetailOwner
        state={state}
        create={create}
        edit={edit}
        owner={owner}
        editIcon={editIcon}
        closeIcon={closeIcon}
        handleSelected={handleSelected}
        isGroup={isGroup}
        isContent={isContent}
        lounges={lounges}
        placeholder={placeholder}
        setValues={setValues}
        values={values}
        editValues={editValues}
        setEditValues={setEditValues}
        isLounge={isLounge}
      />
      {/* description section */}
      <DetailDescription
        open={open}
        state={state}
        create={create}
        description={description}
        editValues={editValues}
        editIcon={editIcon}
        closeIcon={closeIcon}
        collapse={collapse}
        setEditValues={setEditValues}
        placeholder={placeholder}
        setValues={setValues}
        values={values}
      />
      {/* upload */}
      {isContent && (
        <DetailUpload
          handleContentUpload={
            edit ? handleUpdateContentUpload : handleContentUpload
          }
          isContent={isContent}
          placeholder={placeholder}
        />
      )}
      {/* visible/status section */}
      {Boolean(!isGroup) && Boolean(!isContent) && (
        <DetailVisibility
          create={create}
          isGroup={isGroup}
          setValues={setValues}
          setEditValues={setEditValues}
          editValues={editValues}
          status={status}
          privacy={privacy}
          setPrivacyLabel={setPrivacyLabel}
          privacyLabel={privacyLabel}
          values={values}
          isLounge={isLounge}
          editIcon={editIcon}
          state={state}
          closeIcon={closeIcon}
        />
      )}
      {/* enable section */}
      {Boolean(!isGroup) && Boolean(!isContent) && (
        <DetailEnable
          isGroup={isGroup}
          setValues={setValues}
          values={values}
          closeIcon={closeIcon}
          create={create}
          editIcon={editIcon}
          editValues={editValues}
          isLounge={isLounge}
          setEditValues={setEditValues}
          state={state}
          status={status}
          setStatusLabel={setStatusLabel}
          statusLabel={statusLabel}
        />
      )}
      {/*  */}
      {isContent && (
        <DetailGenre
          values={values}
          setValues={setValues}
          data={{ lounges: null, genres: genresForContents }}
          setQueryUsername={setQueryUsername}
          setLoungeQuery={setLoungeQuery}
          contentObj={contentObj}
          isGenre={true}
          state={state}
          setState={setState}
          editIcon={editIcon}
          closeIcon={closeIcon}
          editValues={editValues}
          setEditValues={setEditValues}
          create={create}
          edit={edit}
          setGenreType={setGenreType}
        />
      )}
      {/*  */}
      {isContent && (
        <DetailLounge
          values={values}
          setValues={setValues}
          data={{ lounges: loungesForContents, genres: null }}
          setQueryUsername={setQueryUsername}
          setLoungeQuery={setLoungeQuery}
          queryLounge={queryLounge}
          contentObj={contentObj}
          isLounge={true}
          state={state}
          setState={setState}
          editIcon={editIcon}
          closeIcon={closeIcon}
          editValues={editValues}
          setEditValues={setEditValues}
          edit={edit}
          create={create}
        />
      )}
      {/*  */}
      {isContent && genreType === "Books" && (
        <DetailPriceValue
          values={values}
          setValues={setValues}
          create={create}
          price={priceObj?.price}
          currency={priceObj?.currency}
          state={state}
          setState={setState}
          editIcon={editIcon}
          closeIcon={closeIcon}
          editValues={editValues}
          setEditValues={setEditValues}
          edit={edit}
          priceValueLabel={priceValueLabel}
          setPriceValueLabel={setPriceValueLabel}
        />
      )}
      {/*  */}
      {isContent && genreType === "Books" && (
        <DetailPriceCurrency
          values={values}
          setValues={setValues}
          create={create}
          currency={priceObj?.currency}
          editIcon={editIcon}
          editValues={editValues}
          setEditValues={setEditValues}
          state={state}
          setState={setState}
          closeIcon={closeIcon}
          edit={edit}
          priceCurrencyLabel={priceCurrencyLabel}
          setPriceCurrencyLabel={setPriceCurrencyLabel}
        />
      )}
      {isContent && (
        <Flex borderBottom padding={`20px 5%`}>
          <Label>Published Status: </Label>{" "}
          <span style={{ marginLeft: 20 }}>
            {publishedStatus || book?.isPublished}
          </span>
        </Flex>
      )}
      {/* {isContent && (
        <DetailExclusive
          setValues={setValues}
          values={values}
          editValues={editValues}
          setEditValues={setEditValues}
          closeIcon={closeIcon}
          editIcon={editIcon}
          state={state}
          setState={setState}
          create={create}
          edit={edit}
          book={book}
        />
      )} */}
      {/*  */}
      <DetailOthers
        create={create}
        date={date}
        count2={count2}
        count1={count1}
        isContent={isContent}
        isGroup={isGroup}
      />
      {/*  */}
      {!isGroup && (
        <DetailActionBtn
          create={create}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          loading={finalLoading}
          setState={setState}
          setEditValues={setEditValues}
          isContent={isContent}
          setPublishModal={setPublishModal}
          setSaveAsDraftModal={setSaveAsDraftModal}
          isExtName={isExtName}
          book={book}
          isDraft={isDraft}
        />
      )}

      {/* publish publishModal */}
      <Modal isOpen={publishModal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to publish {title}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setPublishModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (isContent && !create) {
                handleUpdate();
                setPublishModal(false);
                return;
              }
              if (isContent && create) {
                handleCreate(false);
                setPublishModal(false);
                return;
              }
              setState({
                loungeNameState: false,
                descriptionState: false,
                ownerState: false,
                statusState: false,
              });
              setEditValues({
                name: "",
                description: "",
                isPrivate: false,
                approvedAt: null,
                loungeQuery: "",
                genreQuery: "",
                priceValue: 0,
                priceCurrency: "gbp",
              });
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      {/* draft publishModal */}
      <Modal isOpen={saveAsDraftModal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to save {title} to Draft</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setSaveAsDraftModal(false)}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              if (isContent && !create) {
                setAsDraft();
                setSaveAsDraftModal(false);
                return;
              }
              if (isContent && create) {
                handleCreate(true);
                setSaveAsDraftModal(false);
                return;
              }
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>

      {/* Epub content modal */}
      {/* <Modal isOpen={saveAsDraftModal} centered={true}>
        <ModalBody>
          <ReactReader
            url={book?.bookUrl}
            title={"Alice in wonderland"}
            location={"epubcfi(/6/2[cover]!/6)"}
            locationChanged={(epubcifi) => console.log(epubcifi)}
          />
        </ModalBody>
      </Modal> */}
    </Container>
  );
};

export default DetailView;
