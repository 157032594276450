import gql from "graphql-tag";

export const AdminAccountQuery = gql`
  query adminAccountQuery($search: adminAccount_bool_exp, $limit: Int) {
    adminAccount(where: $search, limit: $limit) {
      id
      shortName
      entityName
      type
      avatarUrl
      admins {
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          username
        }
      }
    }
  }
`;
