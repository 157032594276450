import React, { useState } from "react";
import { Table, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMutation } from "react-apollo";
import _ from "lodash";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from 'moment';

import { UpdateBookWithIncMutation } from 'graphql/update/updateBook'
import { RemoveBookMutation } from "graphql/delete/remove_book";
import { errorNotify, successNotify } from "utils/toaster";
import { Colors } from "assets/colors/Colors";
import UITooltip from "pages/Ui/UITooltip";

const ActionComponent = ({ book, history }) => {
  const [modal, setModal] = useState(false);
  const [updateBook, { loading }] = useMutation(UpdateBookWithIncMutation);

  return (
    <ActionFlex>
      <BookUrl>
        <a
          href={book?.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: 12 }}
          className="text-info btn btn-outline-info"
        >
          Preview
        </a>
      </BookUrl>
      <Button
        onClick={() => history.push(`/content/${book?.key}`)}
        style={{ marginRight: 10 }}
        color="primary"
      >
        Edit 
      </Button>
      <Button
        onClick={() => setModal(true)}
        color="warning"
        disabled={!book?.showShareBtn}
      >
        Reshare
      </Button>

      <Modal isOpen={modal} centered={false}>
        <ModalHeader>
          Please Review Below Information Before You Reshare
        </ModalHeader>
        <ModalBody>
          <p>Content: {book?.fullTitle}</p>
          <p>Number of times reshared: {book?.numberOfTimesReshared}</p>
          <p>Last time reshared: {!book?.resharedDate ? 'N/A' : moment(book?.resharedDate).fromNow()}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={async () => {
              try {
                await updateBook({
                  variables: {
                    where: {
                      id: {
                        _eq: book?.key
                      }
                    }, 
                    inc: {
                      numberOfTimesReshared: 1
                    }, 
                    set: {
                      createdAt: new Date().toISOString(),
                      resharedDate: new Date().toISOString()
                    }
                  },
                  refetchQueries: ["booksQuery", "contentDraftsQuery"]
                });
                setModal(false);
                successNotify(`Article Reshared Successfully`);
              } catch(e) {
                errorNotify(`Unable To Reshare Article`);
                console.log(e.message)
              }
            }}
          >
           { loading ? "Please Wait" : "Reshare"}
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </ActionFlex>
  );
};

const getColor = (title) => {
  return title?.toLowerCase() === "books"
    ? "primary"
    : title?.toLowerCase() === "articles"
    ? "info"
    : title?.toLowerCase() === "blogs"
    ? "success"
    : title?.toLowerCase() === "magazines"
    ? "warning"
    : title?.toLowerCase() === "non"
    ? "danger"
    : "secondary";
};

const BookList = (props) => {
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [bookId, setBookId] = useState();
  const [bookName, setBookName] = useState("");

  const [, setSuccess] = useState(null);
  const [, setError] = useState(null);

  const [removeBook] = useMutation(RemoveBookMutation);

  const handleRemoveBook = async () => {
    try {
      if (bookId) {
        const res = await removeBook({
          variables: {
            bookId,
          },
        });
        const message = `${res?.data?.delete_lounge?.returning[0]?.username} successfully removed`;

        setSuccess(message);
        history.push("/contents");
        setModal(false);
      }
    } catch (error) {
      const err = error?.toString()?.split(":")[2]?.trim();
      setError(err || "Something went wrong");
    }
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "isPublished",
      width: 100,
    },
    {
      title: "Content Image",
      dataIndex: "image",
      width: 150,
    },
    {
      title: "Content Name",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Content Author",
      dataIndex: "author",
      width: 150,
    },
    {
      title: "Content Description",
      dataIndex: "description",
      width: 300,
    },
    {
      title: "Content Genre",
      dataIndex: "genre",
      width: 150,
      render: (genre) => <Badge color={getColor(genre)}>{genre}</Badge>,
    },
    {
      title: "Total Reader",
      dataIndex: "totalReader",
      width: 150,
    },
    {
      title: "Content Created",
      dataIndex: "date",
      width: 150,
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: 250,
      render: (data) => (
        <ActionComponent
          book={data}
          history={history}
          setModal={setModal}
          setBookId={setBookId}
          setBookName={setBookName}
        />
      ),
    },
  ];

  const books = _.map(props?.bookData, (book) => {
    return {
      key: book?.id,
      isPublished: book?.isPublished ? (
        <Badge style={{ backgroundColor: Colors.dyshPurple }}>Publish</Badge>
      ) : (
        <Badge style={{ backgroundColor: Colors.dyshYellow }}>Draft</Badge>
      ),
      image: book?.imageUrl ? (
        <img
          src={book?.imageUrl}
          alt="url"
          width={54}
          height={54}
          style={{ borderRadius: 50 }}
        />
      ) : (
        <Avatar size={54} icon={<UserOutlined />} />
      ),
      title: (
        <UITooltip message={book?.title} target={`book-title-${book?.id}`} />
      ),
      fullTitle: book?.title,
      author: book?.author,
      description: (
        <UITooltip
          message={book?.description}
          target={`book-description-${book?.id}`}
        />
      ),
      genre: book?.genre ? book?.genre?.title : "Non",
      totalReader:
        book?.readers_aggregate?.aggregate.count <= 0
          ? "No Reader"
          : book?.readers_aggregate?.aggregate.count === 1
          ? "1 Reader"
          : book?.readers_aggregate?.aggregate.count + " Readers",
      date: book?.createdAt?.slice(0, 10),
      link: book?.bookUrl,
      numberOfTimesReshared: book?.numberOfTimesReshared,
      resharedDate: book?.resharedDate,
      showShareBtn: book?.isPublished
    };
  });

  const [pageSizeNumber, setPageSizeNumber] = useState(12);

  return (
    <div>
      <TableStyle
        align={"center"}
        columns={columns}
        dataSource={books}
        pagination={{
          pageSize: pageSizeNumber,
          onChange() {
            setPageSizeNumber(pageSizeNumber);
          },
        }}
        scroll={{ y: 550 }}
      />

      <Modal isOpen={modal} centered={true}>
        <ModalBody>
          <p>Are you sure you want to delete {bookName}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              handleRemoveBook();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const TableStyle = styled(Table)`
  /* max-height: 90vh; */
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
  }

  .ant-table-body {
    max-height: 100% !important;
  }

  .ant-table-pagination-right {
    justify-content: center;
  }
`;

const ActionFlex = styled.div`
  display: flex;
  align-items: center;
`;
const BookUrl = styled.div`
  margin-right: 15px;

  a {
    color: #555555;
    font-size: 20px;
  }
`;
// const Action = styled.div`
//   margin-right: 10px;
// `;

export default BookList;
