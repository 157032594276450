import React, { createContext, useReducer } from "react";

export const LoungePortalContext = createContext({
  loungeId: "",
  setLoungeId: () => {},
  loungeName: "",
  setLoungeName: () => {}
});

const LOUNGE_ID = "LOUNGE_ID";
const LOUNGE_NAME = "LOUNGE_NAME";

const initialState = {};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOUNGE_ID:
      return {
        ...state,
        loungeId: payload,
      };
    
    case LOUNGE_NAME:
      return {
        ...state,
        loungeName: payload
      }

    default:
      return state;
  }
};

export const LoungePortalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoungeId = (loungeId) => {
    dispatch({ type: LOUNGE_ID, payload: loungeId });
  };

  const setLoungeName = (loungeName) => {
    dispatch({ type: LOUNGE_NAME, payload: loungeName });
  };

  return (
    <LoungePortalContext.Provider
      value={{
        ...state,
        setLoungeId,
        setLoungeName
      }}
    >
      {children}
    </LoungePortalContext.Provider>
  );
};
