import gql from 'graphql-tag';

export const ReviewAQuery = gql`
	query reviewAQuery($reviewId: uuid) {
		reviews(
			where: {
				_and: [
					{ objectionableContents: { id: { _is_null: false } } }
					# { objectionableContents: { contentType: { _eq: "REVIEW" } } }
					# { objectionableContents: { reviewedAsObjectionable: { _eq: true } } }
					{ id: { _eq: $reviewId } }
				]
			}
		) {
			id
			review
			bookId
			deleted
			createdAt
			user {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			book {
				id
				title
				author
				description
				bookUrl
				createdAt
				updatedAt
				imageUrl
			}
			objectionableContents_aggregate {
				aggregate {
					count
				}
			}
			objectionableContents {
				id
				contentType
				contentId
				reason
				reviewedByAdminId
				raisedByUserId
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
			}
		}
	}
`;

export const GetObjectionableContentDetails = gql`
	query GetObjectionableContentDetails($where: objectionableContent_bool_exp!) {
		objectionableContent(where: $where) {
			createdAt
			planImage
			reviewReply {
				id
				reply
				deleted
				createdAt
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
				review {
					id
					review
					book {
						id
						title
						author
						description
						bookUrl
						createdAt
						updatedAt
						imageUrl
					}
				}
				objectionableContents_aggregate {
					aggregate {
						count
					}
				}
				objectionableContents {
					id
					contentType
					contentId
					reason
					reviewedByAdminId
					raisedByUserId
					reviewDate
					reviewedAsObjectionable
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
			}
			review {
				id
				review
				bookId
				deleted
				createdAt
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
				book {
					id
					title
					author
					description
					bookUrl
					createdAt
					updatedAt
					imageUrl
				}
				objectionableContents_aggregate {
					aggregate {
						count
					}
				}
				objectionableContents {
					id
					contentType
					contentId
					reason
					reviewedByAdminId
					raisedByUserId
					reviewDate
					reviewedAsObjectionable
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
			}
			chat {
				id
				planId
				message
				plan {
					name
				}
				objectionableContentsByChat_aggregate {
					aggregate {
						count
					}
				}
				objectionableContentsByChat {
					id
					contentType
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
				user {
					username
				}
			}
			reportedUser {
				id
				firstName
				lastName
				username
				email
				avatarUrl
				reportedUserObjectionCount {
					id
					contentType
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
				reportedUserObjectionCount_aggregate {
					aggregate {
						count
					}
				}
			}
		}
	}
`;
