import gql from 'graphql-tag';

export const GET_PORTAL_NOTIFICATION = gql`
  query GET_PORTAL_NOTIFICATION($limit: Int!, $offset: Int!) {
    portalNotification(order_by: {createdAt: desc}, limit: $limit, offset: $offset) {
      id
      message
      read
      objectionId
      createdAt
      updatedAt
    }
  }
`;

export const PORTAL_NOTIFICATION_AGGREGATE = gql`
  query PORTAL_NOTIFICATION_AGGREGATE {
    portalNotification_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const PORTAL_NOTIFICATION_COUNT = gql`
  query PORTAL_NOTIFICATION_COUNT {
    portalNotification_aggregate(where: {read: {_eq: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const NOTIFY_ADMIN = gql`
  query NOTIFY_ADMIN($limit: Int!, $offset: Int!) {
    notifyAdmin(order_by: {createdAt: desc}, limit: $limit, offset: $offset) {
      id
      message
      createdAt
      updatedAt
    }
  }
`;

export const NOTIFY_ADMIN_AGGREGATE = gql`
  query NOTIFY_ADMIN_AGGREGATE {
    notifyAdmin_aggregate {
      aggregate {
        count
      }
    }
  }
`;
