import { Colors } from "assets/colors/Colors";
import React from "react";
import { Input, Label } from "reactstrap";
import { Flex } from "../style";

const DetailUpload = ({ handleContentUpload, isContent, placeholder }) => {
  const titlePlaceholderBookUrl = placeholder && placeholder["bookUrl"];

  return (
    <div>
      {isContent && (
        <Flex padding={`20px 5%`} borderBottom>
          <div className="row m-0">
            <Label className="m-0 p-0 mb-2">
              <span style={{ color: Colors.red800 }}>*</span>{" "}
              {titlePlaceholderBookUrl ? titlePlaceholderBookUrl : "Upload"}
            </Label>
            <Input type="file" onChange={handleContentUpload} />
          </div>
        </Flex>
      )}
    </div>
  );
};

export default DetailUpload;
