import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import ImageUi from 'components/Common/ImageUi';

export const mostActiveGroup = (groups) => {
	const columns = [
		{ id: 1, title: 'Image' },
		{ id: 2, title: 'Name' },
		{ id: 3, title: 'Participants' },
		{ id: 4, title: 'Message Count' },
		{ id: 5, title: 'Last Message Date' },
	];

	const data = _.map(groups, (group) => ({
		id: group?.id,
		image: (
			<ImageUi imageUrl={group?.books[0]?.book?.imageUrl} text={group?.name} />
		),
		name: group?.name,
		participants: group?.users_aggregate?.aggregate?.count + 1,
		count: group?.chatMessages_aggregate?.aggregate?.count,
		date: moment(group?.chatMessages[0]?.createdAt).fromNow(),
	}));

	return {
		columns,
		data,
	};
};
