import gql from 'graphql-tag';

export const GroupQuery = gql`
	query groups($search: plan_bool_exp, $limit: Int, $offset: Int) {
		plan(
			where: $search
			limit: $limit
			offset: $offset
			order_by: { createdAt: desc }
		) {
			id
			userId
			name
			description
			private
			deleted
			createdAt
			duration
			updatedAt
			lounges {
				lounge {
					id
					name
					description
					imageUrl
				}
			}
			books {
				book {
					id
					imageUrl
					title
					author
					bookUrl
				}
			}
			user {
				id
				firstName
				lastName
				username
				email
			}
			users {
				user {
					id
					firstName
					lastName
					username
					email
				}
			}
			chatMessages_aggregate {
				aggregate {
					count
				}
			}

			chatMessages(order_by: { updatedAt: desc }) {
				id
				message
				createdAt
			}

			users_aggregate(
				where: {
					declinedAt: { _is_null: true }
					acceptedAt: { _is_null: false }
				}
			) {
				aggregate {
					count
				}
			}
		}

		plan_aggregate(where: $search) {
			aggregate {
				count
			}
		}
	}
`;

export const LoungeOwnerGroupQuery = gql`
	query groups($search: plan_bool_exp, $limit: Int, $offset: Int) {
		plan(
			where: $search
			limit: $limit
			offset: $offset
			order_by: { createdAt: desc }
		) {
			id
			userId
			name
			description
			private
			createdAt
			duration
			updatedAt
			books {
				book {
					id
					imageUrl
					title
					author
					bookUrl
				}
			}
			user {
				id
				firstName
				lastName
				username
				email
			}
			users {
				user {
					id
					firstName
					lastName
					username
					email
				}
			}
			chatMessages_aggregate {
				aggregate {
					count
				}
			}
		}

		plan_aggregate(where: $search) {
			aggregate {
				count
			}
		}
	}
`;
