import React from "react";
import { Label, Input } from "reactstrap";
import { FlexDiv } from "../style";

const NewUpload = ({ upload }) => {
  return (
    <>
      {upload && (
        <FlexDiv direction="column" borderBottom>
          <Label>{upload?.label}</Label>
          <Input type="file" onChange={upload?.handleUpload} />
        </FlexDiv>
      )}
    </>
  );
};

export default NewUpload;
