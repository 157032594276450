import { Colors } from "assets/colors/Colors";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Flex } from "../style";

const DetailTitle = ({
  state,
  create,
  title,
  editIcon,
  closeIcon,
  editValues,
  setEditValues,
  values,
  setValues,
  placeholder,
}) => {
  const titlePlaceholder = placeholder && placeholder["title"];

  return (
    <Flex justify={"space-between"} borderBottom padding={`20px 5%`}>
      {!state.loungeNameState && !create && (
        <Flex align="center" width="75%">
          <h2 className="header-title">{title}</h2>
          {editIcon({ ...state, loungeNameState: true })}
        </Flex>
      )}
      {(state.loungeNameState || create) && (
        <Flex width="100%">
          <FormGroup className="mb-0 w-100">
            {!create ? (
              <Flex>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={editValues.name}
                  className="form-control"
                  placeholder={
                    titlePlaceholder ? titlePlaceholder : "Lounge Name..."
                  }
                  onChange={({ target: { value } }) => {
                    setEditValues({
                      ...editValues,
                      name: value,
                    });
                  }}
                  style={{ width: "90%" }}
                />
                {closeIcon && closeIcon({ ...state, loungeNameState: false })}
              </Flex>
            ) : (
              <div>
                <Label>
                  <span style={{ color: Colors.red800 }}>*</span> {title}
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder={
                    titlePlaceholder ? titlePlaceholder : "Lounge Name..."
                  }
                  onChange={({ target: { value } }) => {
                    setValues({
                      ...values,
                      name: value,
                      title: value,
                    });
                  }}
                  style={{ width: "90%" }}
                />
              </div>
            )}
          </FormGroup>
        </Flex>
      )}

      {/* {!isGroup && (
        <Button
          onClick={() => {
            setState({
              loungeNameState: false,
              descriptionState: false,
              ownerState: false,
              statusState: false,
            });
            if (create) {
              handleCreate();
            } else {
              handleUpdate();
            }
          }}
          color='primary'
          outline
          className='waves-effect waves-light align-self-center'
        >
          {loading ? (
            <Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
          ) : (
            btnLabel
          )}
        </Button>
      )} */}
    </Flex>
  );
};

export default DetailTitle;
