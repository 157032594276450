import { useMutation, useQuery } from '@apollo/react-hooks';
import { DetailLayoutView } from 'components/DetailView';
import React, { useContext, useState } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Col, 
	Row,
	Spinner,
} from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { errorNotify, successNotify } from 'utils/toaster';
import { Config } from '../../config';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { PrivateVideoPlaylistMutation } from 'graphql/query/video';

const CreatePlaylist = () => {
	const history = useHistory();

	const { token, user } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext);

	const [preview, setPreview] = useState('');
	const [imageUrlFile, setImageUrlFile] = useState();

	const [publishModal, setPublishModal] = useState(false);

	const [values, setValues] = useState({
		title: '',
		description: ''
	});

	const [addPlaylist, { loading: addPlaylistLoading }] = useMutation(PrivateVideoPlaylistMutation);

	const handleImageUpload = async ({ target: { files } }) => {
		const imageSizeLimit = 500;
		const reader = new FileReader();

		if (files) {
			reader.readAsDataURL(files[0]);
		}

		const imageSize = files[0]?.size / 1024;

		if (imageSize > imageSizeLimit) {
			return errorNotify(
				'The size of the image should be less than equal to 500kb'
			);
		}

		setImageUrlFile(files[0]);

		reader.onloadend = function (e) {
			setPreview(reader.result);
		};
	};

	const handleAddPlaylist = async () => {
		const {
			title,
			description,
		} = values;

		if (!imageUrlFile) {
			return errorNotify(
				'Please upload playlist image or check the size of image uploaded'
			);
		}

		const form = new FormData();
		form.append('imageUrl', imageUrlFile);

		try {
			if (title && description && imageUrlFile) {
				const res = await fetch(`${Config.restApiUrl}/uploads/video/playlist-image`, {
					method: 'POST',
					headers: {
            "Authorization": token,
          },
					body: form,
				});

				const response = await res.json();
				const imageUrl = response?.data?.imageUrl;

				if (imageUrl) {
					const res = await addPlaylist({
						variables: {
							object: {
								title,
								description,
								imageUrl,
								loungeId,
								createdBy: `${user.firstName} ${user.lastName}`,
							},
						},
						refetchQueries: ['PrivateVideoPlaylistsQuery']
					});

					history.push('/playlists');
					successNotify('Playlist added successfully');
				} else {
					errorNotify('Playlist not created');
				}
			} else {
				errorNotify('Some fields are required');
			}
		} catch (error) {
			const err = error?.toString()?.split(':')[2]?.trim();
			errorNotify(err || 'Something went wrong');
		}
	};

	return (
		<Wrapper>
			<Row>
				<Col>
					<DetailLayoutView
						image={preview}
						onChange={handleImageUpload}
						uploadName='Playlist'
						breadcrumbTitle='Playlists'
						breadcrumbItem='Create Video Playlist'
						breadcrumbRoute={"/playlists"}
					>
						<NewDetailView
							key1={{
								label: 'Playlist Title',
								placeholder: 'Playlist Title',
								name: 'title',
								status: 'create',
								type: 'input',
							}}
							key2={{
								label: 'Playlist Description',
								placeholder: 'Playlist Description',
								name: 'description',
								status: 'create',
								type: 'description',
							}}
							action={{
								type: 'action',
								disabled: _.isEmpty(values.title) || _.isEmpty(values.description),
								actionText: {
									btn3Text: 'Create',
								},
								action: {
									btn3: () => setPublishModal(true),
								},
								actionLoading: {
								  btn3Loading: addPlaylistLoading,
								},
							}}
							values={values}
							setValues={setValues}
						/>
					</DetailLayoutView>
				</Col>
			</Row>

			{/* publish publishModal */}
			<Modal isOpen={publishModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to publish {values.title?.toUpperCase()} playlist?</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setPublishModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							handleAddPlaylist(false);
							setPublishModal(false);
						}}
					>
						{addPlaylistLoading ? (
							<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<ToastContainer />
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	margin-top: 10vh;
`;

export default CreatePlaylist;
