import gql from "graphql-tag";

export const LatestTransactionQuery = gql`
  query latestTransactions(
    $where: userPurchase_bool_exp
    $limit: Int
    $offset: Int
  ) {
    userPurchase(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      id
      transactionId
      receiptUrl
      success
      createdAt
      user {
        id
        avatarUrl
        firstName
        lastName
        username
        email
      }
      book {
        id
        title
        imageUrl
        author
        description
        bookUrl
        priceValue
        createdAt
      }
    }

    userPurchase_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const LoungeOwnerLatestTransactionQuery = gql`
  query latestTransactions($adminId: uuid, $adminAccountId: uuid) {
    userPurchase(
      where: {
        _or: [
          {
            book: {
              lounges: {
                lounge: {
                  _and: [
                    {
                      _or: [
                        { createdByAdminId: { _eq: $adminId } }
                        { updatedByAdminId: { _eq: $adminId } }
                      ]
                    }
                    { ownedByAccountId: { _eq: $adminAccountId } }
                  ]
                }
              }
            }
          }
        ]
      }
      order_by: { createdAt: desc }
    ) {
      id
      transactionId
      receiptUrl
      success
      createdAt
      user {
        id
        avatarUrl
        firstName
        lastName
        username
        email
      }
      book {
        id
        title
        imageUrl
        author
        description
        bookUrl
        priceValue
        createdAt
      }
    }
  }
`;
