import gql from 'graphql-tag';

export const ReviewsQuery = gql`
	query reviewsQuery($limit: Int, $offset: Int, $query: String) {
		reviews(
			where: {
				_and: [
					{ objectionableContents: { id: { _is_null: false } } }
					{ objectionableContents: { reviewedByAdminId: { _is_null: true } } }
					{ objectionableContents: { reviewDate: { _is_null: true } } }
					{ objectionableContents: { reason: { _ilike: $query } } }
					{ objectionableContents: { reviewedAsObjectionable: { _eq: false } } }
				]
			}
			limit: $limit
			offset: $offset
			order_by: { objectionableContents_aggregate: { max: {}, count: desc } }
		) {
			id
			review
			bookId
			deleted
			createdAt
			user {
				id
				username
				firstName
				lastName
				email
				avatarUrl
			}
			book {
				id
				title
				author
				description
				bookUrl
				createdAt
				updatedAt
				imageUrl
			}
			objectionableContents_aggregate {
				aggregate {
					count
				}
			}
			objectionableContents {
				id
				contentType
				contentId
				reason
				reviewedByAdminId
				raisedByUserId
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
			}
		}

		reviews_aggregate(
			where: {
				_and: [
					{ objectionableContents: { id: { _is_null: false } } }
					{ objectionableContents: { reviewedByAdminId: { _is_null: true } } }
					{ objectionableContents: { reviewDate: { _is_null: true } } }
					{ objectionableContents: { reason: { _ilike: $query } } }
					{ objectionableContents: { reviewedAsObjectionable: { _eq: false } } }
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GetObjectionableContents = gql`
	query GetObjectionableContents($limit: Int, $offset: Int) {
		objectionableContent(
			where: {
				reviewedByAdminId: { _is_null: true }
				reviewDate: { _is_null: true }
				reviewedAsObjectionable: { _eq: false }
			}
			order_by: { createdAt: desc }
			limit: $limit
			offset: $offset
		) {
			planImage
			reviewReply {
				id
				reply
				deleted
				createdAt
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
				review {
					id
					book {
						id
						title
						author
						description
						bookUrl
						createdAt
						updatedAt
						imageUrl
					}
				}
				objectionableContents_aggregate {
					aggregate {
						count
					}
				}
				objectionableContents {
					id
					contentType
					contentId
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
			}
			review {
				id
				review
				bookId
				deleted
				createdAt
				user {
					id
					username
					firstName
					lastName
					email
					avatarUrl
				}
				book {
					id
					title
					author
					description
					bookUrl
					createdAt
					updatedAt
					imageUrl
				}
				objectionableContents_aggregate {
					aggregate {
						count
					}
				}
				objectionableContents {
					id
					contentType
					contentId
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
			}
			chat {
				id
				planId
				message
				objectionableContentsByChat_aggregate {
					aggregate {
						count
					}
				}
				objectionableContentsByChat {
					id
					contentType
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
				user {
					username
				}
			}
			reportedUser {
				id
				firstName
				lastName
				username
				email
				avatarUrl
				reportedUserObjectionCount {
					id
					contentType
					reason
					reviewedByAdminId
					raisedByUserId
					user {
						id
						username
						firstName
						lastName
						email
						avatarUrl
					}
				}
				reportedUserObjectionCount_aggregate {
					aggregate {
						count
					}
				}
			}
		}
		objectionableContent_aggregate(
			where: {
				reviewedByAdminId: { _is_null: true }
				reviewDate: { _is_null: true }
				reviewedAsObjectionable: { _eq: false }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
