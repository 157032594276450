import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";

import { FlexDiv } from "../style";

const NewSelect = ({ select, values, setValues, onSelectChange, deactivateSelection }) => {
  const name = select?.name;
  const location = useLocation();
  const isGroupPage = location.pathname.split('/')[1] === 'groups';

  const [state, setState] = useState(false);

  useEffect(() => {
    setState(false);
  }, [deactivateSelection])

  const editPage =
    (select?.status === "edit" && !state) || select?.type === "text";

  const isCreate = select?.status === "create";
  const isEdit = select?.status === "edit" && state;

  return (
    <div>
      {editPage ? (
        <FlexDiv
          direction={select?.status === "create" ? "column" : "row"}
          borderBottom
        >
          <Label>{select?.label}:</Label>
          <span style={{ marginLeft: 10, max: 200, whiteSpace: "nowrap" }}>
            {select?.value}
          </span>
          {!isCreate && select?.isEditable && (
            <i onClick={() => setState(true)} className="bx bx-pencil ml-3"></i>
          )}
        </FlexDiv>
      ) : (
        isCreate && (
          <FlexDiv
            direction={select?.status === "create" ? "column" : "row"}
            borderBottom
          >
            <Label>{select?.label}</Label>
            <Select
              options={select?.options}
              style={{ width: "30% !important" }}
              onChange={({ value, label }) => {
                setValues({
                  ...values,
                  [`${name}`]: { value, label },
                });
              }}
            />
          </FlexDiv>
        )
      )}
      {isEdit && (
        <FlexDiv borderBottom width="100%" align="center">
          <Label>
            <span style={{ whiteSpace: "nowrap" }}>{select?.label}:</span>
          </Label>
          <div style={{ width: "100%", marginLeft: 10 }}>
            <Select
              options={select?.options}
              style={{ width: "100%" }}
              className="select"
              onChange={({ value, label }) => {
                if (isGroupPage) {
                  onSelectChange({ value, label });
                } else {
                  setValues({
                    ...values,
                    [`${name}`]: { value, label },
                  });
                }
              }}
            />
          </div>
          <i onClick={() => setState(false)} className="fas fa-times ml-3"></i>
        </FlexDiv>
      )}
    </div>
  );
};

export default NewSelect;
