import React from "react";
import { Label } from "reactstrap";
import { FlexDiv } from "../style";

const NewText = ({ text }) => {
  return (
    <>
      {text && (
        <FlexDiv borderBottom align="flex-start">
          <Label
            style={{
              margin: 0,
            }}
          >
            {text?.label}:{" "}
          </Label>{" "}
          <span style={{ marginLeft: 10, ...text?.style }}>{text?.value}</span>
        </FlexDiv>
      )}
    </>
  );
};

export default NewText;
