import { UserContext } from "context/UserContext";
import { Wrapper } from "pages/Lounges/CreateLounge";
import React, { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

const EditUser = ({
  user,
  role,
  handleConfirmUser,
  handleUnConfirmUser,
  setIsEmailConfirmed,
  setIsSuspended,
  isEmailConfirmed,
  isSuspended,
  loading,
  hideSelect,
  onClick,
  setIsDeleteAdminModalOpen,
  deleting,
  btn1, 
  btn2,
  btn = {
    label: "Save",
    color: "primary",
  },
}) => {
  const { user: loggedInUser, isAdminPortal } = useContext(UserContext);
  const showSuperAdminActionButtons = (loggedInUser?.role === 'SUPER-ADMIN') || (loggedInUser?.role === 'ADMIN' && !isAdminPortal);

  return (
    <Wrapper>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form>
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="firstName"
                    className="col-form-label col-lg-2"
                  >
                    First Name
                  </Label>
                  <Col lg="10">
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={user?.firstName}
                      className="form-control"
                      disabled={true}
                      style={{ backgroundColor: "#eeeeee" }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="mb-4" row>
                  <Label htmlFor="lastName" className="col-form-label col-lg-2">
                    Last Name
                  </Label>
                  <Col lg="10">
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={user?.lastName}
                      className="form-control"
                      disabled={true}
                      style={{ backgroundColor: "#eeeeee" }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="mb-4" row>
                  <Label htmlFor="username" className="col-form-label col-lg-2">
                    Username
                  </Label>
                  <Col lg="10">
                    <Input
                      id="username"
                      name="username"
                      type="text"
                      value={user?.username}
                      className="form-control"
                      disabled={true}
                      style={{ backgroundColor: "#eeeeee" }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="mb-4" row>
                  <Label htmlFor="email" className="col-form-label col-lg-2">
                    Email
                  </Label>
                  <Col lg="10">
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      value={user?.email}
                      className="form-control"
                      disabled={true}
                      style={{ backgroundColor: "#eeeeee" }}
                    />
                  </Col>
                </FormGroup>
                {role && (
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="email" className="col-form-label col-lg-2">
                      Admin Type
                    </Label>
                    <Col lg="10">
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        value={role}
                        className="form-control"
                        disabled={true}
                        style={{ backgroundColor: "#eeeeee" }}
                      />
                    </Col>
                  </FormGroup>
                )}
                {!hideSelect && (
                  <>
                    <div className="form-group row">
                      <label className="col-md-2 col-form-label">
                        Confirmed
                      </label>
                      <div className="col-md-2">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setIsEmailConfirmed(
                              e.target.value === "yes" ? true : false
                            )
                          }
                        >
                          <option value="">
                            {isEmailConfirmed ? "Yes" : "No"}
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-2 col-form-label">
                        Suspended
                      </label>
                      <div className="col-md-2">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setIsSuspended(
                              e.target.value === "yes" ? true : false
                            )
                          }
                        >
                          <option value="">{isSuspended ? "Yes" : "No"}</option>
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </Form>
              {
                showSuperAdminActionButtons && (
                  <Row className="justify-content-end">
                    <Button
                      className="d-flex align-items-center mr-4"
                      type="submit"
                      color={btn1.color}
                      onClick={onClick}
                      disabled={isSuspended || loggedInUser?.id === user?.id}
                    >
                      {loading && (
                        <Spinner
                          style={{ width: 12, height: 12, marginRight: 10 }}
                        />
                      )}{" "}
                      {btn1?.label}
                    </Button>

                    <Button
                      className="d-flex align-items-center"
                      type="submit"
                      style={{ marginRight: 12.5 }}
                      color={btn2.color}
                      onClick={setIsDeleteAdminModalOpen}
                      disabled={loggedInUser?.id === user?.id}
                    >
                      {deleting && (
                        <Spinner
                          style={{ width: 12, height: 12, marginRight: 10 }}
                        />
                      )}{" "}
                      {btn2?.label}
                    </Button>
                </Row>
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default EditUser;
