import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "react-apollo";
import moment from "moment";

import { GetALoungeQuery } from "../../graphql/a_lounge_query";
import { UpdateLoungeMutation } from "../../graphql/update/updateLounge";
import CustomSpinner from "../../ui/CustomSpinner";
import { DetailLayoutView } from "components/DetailView";
import Pages500 from "pages/Utility/pages-500";
import ErrorHandler from "utils/CatchError";
import { ToastContainer } from "react-toastify";
import { errorNotify, successNotify } from "utils/toaster";
import { Config } from "config";
import NewDetailView from "components/DetailView/NewDetailScreen";
import { UserContext } from "context/UserContext";
import { pluralizeText } from "utils/pluralizeText";
import { enableOptions } from "./static/activeOrPrivate";
import { LoungePortalContext } from "context/LoungePortalContext";

const LoungeDetail = () => {
  const { user, isAdmin } = useContext(UserContext);
  const { loungeId } = useContext(LoungePortalContext);

  const [preview, setPreview] = useState();

  const [values, setValues] = useState({
    name: "",
    description: "",
    imageUrl: "",
    isPrivate: false,
    isActive: false,
  });

  const { id } = useParams();

  const [updateLounge, { loading: updateLoungeLoading }] = useMutation(
    UpdateLoungeMutation,
    {
      fetchPolicy: "no-cache",
    }
  );

  const {
    data: loungeData,
    loading: loungeLoading,
    error: loungeError,
  } = useQuery(GetALoungeQuery, {
    fetchPolicy: "network-only",
    variables: { loungeId: loungeId ? loungeId : id },
  });

  const loungeCount =
    loungeData?.lounge[0]?.loungeUsers_aggregate?.aggregate?.count;

  const groupCount = loungeData?.lounge[0]?.plans_aggregate?.aggregate?.count;
  const lounge = loungeData?.lounge[0];

  const [, setPrivacy] = useState(lounge?.isPrivate ? "Private" : "Public");

  const [active, setActive] = useState();

  const customError = ErrorHandler.getUserFriendlyError(
    loungeError?.toString()
  );

  useEffect(() => {
    setValues({
      name: lounge?.name,
      description: lounge?.description,
    });

    setPrivacy(lounge?.isPrivate ? "Private" : "Public");

    // eslint-disable-next-line
  }, []);

  const handleFile = async ({ target: { files } }) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onloadend = function (e) {
      setPreview(reader.result);
    };

    const form = new FormData();
    form.append("imageUrl", files[0]);

    const res = await fetch(`${Config.restApiUrl}/uploads/lounge`, {
      method: "POST",
      "Content-type": "multipart/form-data",
      body: form,
    });

    const response = await res.json();

    const imageUrl = response?.data?.imageUrl;

    if (imageUrl) {
      await updateLounge({
        variables: {
          where: { id: { _eq: id } },
          set: {
            imageUrl,
          },
        },
      });
    }
  };

  const handleUpdateLounge = async () => {
    const { name, description, isPrivate, isActive } = values;
    try {
      const response = await updateLounge({
        variables: {
          where: { id: { _eq: lounge?.id } },
          set: {
            name,
            description,
            isPrivate: isPrivate?.value === "private" ? true : false,
            isActive: isActive?.value === "active" ? true : false,
            approvedAt: new Date().toISOString(),
            updatedByAdminId: user?.adminId,
          },
        },
      });

      setPrivacy(isPrivate?.label);
      setActive(isActive?.label);

      const msgVar = response?.data?.update_lounge?.returning[0]?.name;
      successNotify(`Successfully updated ${msgVar} lounge.`);
    } catch (error) {
      errorNotify("Something went wrong, try again");
    }
  };

  if (loungeError?.toString()) {
    return <Pages500 error={customError} />;
  }

  return (
    <Wrapper>
      {loungeLoading ? (
        <CustomSpinner />
      ) : (
        <DetailLayoutView
          image={preview ? preview : lounge?.imageUrl}
          onChange={handleFile}
          update
          breadcrumbTitle="Lounges"
          breadcrumbItem={lounge?.name}
          breadcrumbRoute={isAdmin ? "/lounges" : `/lounge/${id}`}
        >
          <NewDetailView
            key1={{
              label: "Lounge Name",
              placeholder: "Lounge Name",
              name: "name",
              status: "edit",
              type: "input",
              isEditable: true,
              value: lounge?.name,
            }}
            key2={{
              label: "Owner",
              status: "edit",
              type: "text",
              value: lounge?.user?.username ? lounge?.user?.username : "N/A",
            }}
            key3={{
              label: "Lounge Description",
              placeholder: "Lounge Description",
              name: "description",
              type: "description",
              status: "edit",
              isEditable: true,
              value: lounge?.description,
            }}
            // key4={{
            //   label: "Privacy",
            //   options: privacyOptions,
            //   name: "isPrivate",
            //   status: "edit",
            //   type: "select",
            //   isEditable: true,
            //   value: privacy,
            // }}
            key5={{
              label: "Status",
              options: enableOptions,
              name: "isActive",
              status: "edit",
              type: "select",
              isEditable: true,
              value: active
                ? active
                : lounge?.isActive
                ? "Active"
                : "Not Active",
            }}
            key6={{
              label: "Created At",
              type: "text",
              value: moment(lounge?.createdAt).fromNow(),
            }}
            key7={
              isAdmin && {
                label: "Lounge User Counts",
                type: "text",
                value: loungeCount ? pluralizeText(loungeCount, "User") : "N/A",
              }
            }
            key8={
              isAdmin && {
                label: "Group User Counts",
                type: "text",
                value: groupCount ? pluralizeText(groupCount, "User") : "N/A",
              }
            }
            action={{
              type: "action",
              actionText: {
                btn3Text: "Update",
                status: "edit",
              },
              action: {
                btn3: () => handleUpdateLounge(),
                status: "edit",
              },
              actionLoading: {
                btn3Loading: updateLoungeLoading,
              },
            }}
            values={values}
            setValues={setValues}
          />
          <ToastContainer />
        </DetailLayoutView>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 10vh);
  margin-top: 10vh;
  padding-bottom: 5vh;
  overflow-y: auto;
`;

export default LoungeDetail;
