import gql from 'graphql-tag';

export const DELETE_FRIEND_MUTATION = gql`
	mutation deleteFriend($userId1: uuid!, $userId2: uuid!) {
		delete_friend(
			where: {
				_or: [
					{
						_and: [
							{ user1Id: { _eq: $userId1 } }
							{ user2Id: { _eq: $userId2 } }
						]
					}
					{
						_and: [
							{ user1Id: { _eq: $userId2 } }
							{ user2Id: { _eq: $userId1 } }
						]
					}
				]
			}
		) {
			affected_rows
		}
	}
`;

export const BLOCK_INFO = gql`
	query blockInfo($userId: uuid, $blockedUserId: uuid) {
		block_aggregate(
			where: {
				userId: { _eq: $userId }
				blockedUserId: { _eq: $blockedUserId }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const BLOCK_USER = gql`
	mutation blockUser($objects: [block_insert_input!]!) {
		insert_block(objects: $objects) {
			affected_rows
		}
	}
`;

export const UNBLOCK_USER = gql`
	mutation unBlockUser($userId: uuid, $blockedUserId: uuid) {
		delete_block(
			where: {
				userId: { _eq: $userId }
				blockedUserId: { _eq: $blockedUserId }
			}
		) {
			affected_rows
		}
	}
`;

export const USER_SUSPENSION_INFO = gql`
	query USER_SUSPENSION_INFO($userId: uuid!) {
		user(where: { id: { _eq: $userId } }) {
			isSuspended
		}
	}
`;

export const SUSPEND_USER = gql`
	mutation SUSPEND_USER($userId: uuid!, $suspended: Boolean!) {
		update_user(
			where: { id: { _eq: $userId } }
			_set: { isSuspended: $suspended }
		) {
			affected_rows
		}
	}
`;
