/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { Colors } from 'assets/colors/Colors';
import styled from 'styled-components';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { GetOwnedLounges } from 'graphql/query/ownedLounges';
import { useQuery } from 'react-apollo';

const SidebarContent = (props) => {
	const { isAdmin, adminAccountId, admin } = useContext(UserContext);
	const { loungeId, setLoungeId, setLoungeName } = useContext(LoungePortalContext);

	const { data: loungeData, refetch } = useQuery(GetOwnedLounges, {
		variables: {
			search: adminAccountId
				? { adminAccount: { id: { _eq: adminAccountId } } }
				: {},
		},
	});

	useEffect(() => {
		initMenu();
		setLoungeId(loungeData?.lounge[0]?.id);
		setLoungeName(loungeData?.lounge[0]?.name);
		if (refetch) {
			refetch();
		}

		// eslint-disable-next-line
	}, [loungeData?.lounge]);

	const initMenu = () => {
		new MetisMenu('#side-menu');

		let matchingMenuItem = null;
		let ul = document.getElementById('side-menu');
		let items = ul.getElementsByTagName('a');
		for (let i = 0; i < items.length; ++i) {
			if (props.location.pathname === items[i].pathname) {
				matchingMenuItem = items[i];
				break;
			}
		}
		if (matchingMenuItem) {
			activateParentDropdown(matchingMenuItem);
		}
	};

	const activateParentDropdown = (item) => {
		item.classList.add('active');
		const parent = item.parentElement;

		if (parent) {
			parent.classList.add('mm-active');
			const parent2 = parent.parentElement;

			if (parent2) {
				parent2.classList.add('mm-show');

				const parent3 = parent2.parentElement;

				if (parent3) {
					parent3.classList.add('mm-active'); // li
					parent3.childNodes[0].classList.add('mm-active'); //a
					const parent4 = parent3.parentElement;
					if (parent4) {
						parent4.classList.add('mm-active');
					}
				}
			}
			return false;
		}
		return false;
	};

	return (
		<>
			<div id='sidebar-menu'>
				<ul className='metismenu list-unstyled' id='side-menu'>
					<li className='menu-title'>{props.t('Menu')}</li>
					<li>
						<Link to='/' className='waves-effect'>
							<i className='bx bx-home-circle'></i>
							<Span>{props.t('Dashboard')}</Span>
						</Link>
					</li>
					{isAdmin ? (
						<li>
							<Link to='/lounges' className='waves-effect'>
								<i className='bx bx-book-content'></i>
								<Span>{props.t('Lounge')}</Span>
							</Link>
						</li>
					) : (
						<li>
							<a href={`/lounge/${loungeId}`} className='waves-effect'>
								<i className='bx bx-book-content'></i>
								<Span>{props.t('Lounge')}</Span>
							</a>
						</li>
					)}
					{isAdmin ? (
						<li>
							<Link to='/groups' className='waves-effect'>
								<i class='fas fa-object-group'></i>
								<Span>{props.t('Groups')}</Span>
							</Link>
						</li>
					) : (
						<li>
							<Link to='/group-owner' className='waves-effect'>
								<i class='fas fa-object-group'></i>
								<Span>{props.t('Groups')}</Span>
							</Link>
						</li>
					)}
					{isAdmin ? (
						<>
							<li>
								<Link to='/contents' className='waves-effect'>
									<i className='bx bx-book'></i>
									<Span>{props.t('Editorial')}</Span>
								</Link>
							</li>
							<li>
								<Link to='/youtube' className='waves-effect'>
									<i className='fab fa-youtube'></i>
									<Span>{props.t('Youtube')}</Span>
								</Link>
							</li>
						</>
					) : (
						<>
							<li>
								<Link to='/content-owner' className='waves-effect'>
									<i className='bx bx-book'></i>
									<Span>{props.t('Editorial')}</Span>
								</Link>
							</li>
							<li>
								<Link to='/youtube' className='waves-effect'>
									<i className='fab fa-youtube'></i>
									<Span>{props.t('Youtube')}</Span>
								</Link>
							</li>
							<li>
								<Link to='/playlists' className='waves-effect'>
								  <i class="far fa-play-circle" style={{ fontSize: 22 }}></i>
									<Span>{props.t('Video')}</Span>
								</Link>
							</li>
						</>
					)}
					{isAdmin && (
						<li>
							<Link to='/transactions' className='waves-effect'>
								<i class='fas fa-dollar-sign'></i>
								<Span>{props.t('Payments')}</Span>
							</Link>
						</li>
					)}
					{isAdmin && (
						<li>
							<Link to='/reviews' className='waves-effect'>
								<i class='fas fa-exclamation'></i>
								<Span>{props.t('Reports')}</Span>
							</Link>
						</li>
					)}
					{isAdmin ? (
						<li>
							<Link to='/users' className='waves-effect'>
								<i className='fas fa-users'></i>
								<Span>{props.t('Users')}</Span>
							</Link>
						</li>
					) : (
						<li>
							<Link to='/lounge-user' className='waves-effect'>
								<i className='fas fa-users'></i>
								<Span>{props.t('Lounge Users')}</Span>
							</Link>
						</li>
					)}
					{isAdmin && (
						<li>
							<Link to='/applications' className='waves-effect'>
								<i className='fas fa-chalkboard-teacher'></i>
								<Span>{props.t('Applications')}</Span>
							</Link>
						</li>
					)}
					{isAdmin ? (
						<li>
							<Link to='/admins' className='waves-effect'>
								<i className='fas fa-user-cog'></i>
								<Span>{props.t('Admins')}</Span>
							</Link>
						</li>
					) : (
						<li>
							<Link to='/owner-admins' className='waves-effect'>
								<i className='fas fa-user-cog'></i>
								<Span>{props.t('Team Members')}</Span>
							</Link>
						</li>
					)}
					{isAdmin && (
						<li>
							<Link to='/account-deletion' className='waves-effect'>
								<i className='fas fa-user-minus'></i>
								<Span>{props.t('User Delete Applications')}</Span>
							</Link>
						</li>
					)}
					<li>
						<Link to='/profile' className='waves-effect'>
							<i className='fas fa-user'></i>
							<Span>{props.t('Profile')}</Span>
						</Link>
					</li>
				</ul>
			</div>
		</>
	);
};

export const Span = styled.span`
	&.hover {
		color: ${Colors.dyshPurple};
	}
`;

export default withRouter(withNamespaces()(SidebarContent));
