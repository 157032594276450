/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import ErrorHandler from "utils/CatchError";
import Pages500 from "pages/Utility/pages-500";
import NewTableList from "components/TableList/NewTable";
import ImageUi from "components/Common/ImageUi";
import { LoungePortalContext } from "context/LoungePortalContext";
import { LoungeUsersQuery } from "graphql/query/loungeUsers";
import NewBreadcrumbs from "components/Common/NewBreadcrumb";

const limit = 12;

const LoungeUsers = () => {
  const { loungeId } = useContext(LoungePortalContext);

  const history = useHistory();
  // const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(0);

  const {
    data: loungeUserData,
    loading: loungeUserLoading,
    error: loungeUserError,
    refetch: loungeUserRefetch,
  } = useQuery(LoungeUsersQuery, {
    variables: {
      // search: user?.id ? { userId: { _eq: user?.id ? user?.id : "" } } : {},
      loungeId,
      limit,
      offset,
    },
  });

  const loungeUsers = loungeUserData?.loungeUser;
  const loungeUserCount =
    loungeUserData?.loungeUser_aggregate?.aggregate?.count;

  useEffect(() => {
    if (loungeUserRefetch) {
      loungeUserRefetch();
    }
  }, [loungeUserRefetch]);

  // const handleQuery = ({ target: { value } }) => {
  //   setQuery(value);
  // };

  const error = ErrorHandler.getUserFriendlyError(loungeUserError?.toString());

  if (loungeUserError?.toString()) {
    return <Pages500 error={error} />;
  }

  const columns = [
    { id: 1, title: "Image" },
    { id: 4, title: "First Name" },
    { id: 4, title: "Last Name" },
    { id: 2, title: "Username" },
    { id: 6, title: "Date Joined" },
  ];

  const data = _.map(loungeUsers, ({ id, user }) => ({
    id,
    image: <ImageUi imageUrl={user?.avatarUrl} text={user?.username} />,
    firstName: user?.firstName,
    lastName: user?.lastName,
    username: user?.username,
    date: moment(user?.createdAt).fromNow(),
  }));

  return (
    <Wrapper>
      <NewBreadcrumbs
        title="Lounge Users"
        breadcrumbItem={`Lounge Users (${loungeUserCount ?? 0})`}
        subBreadcrumbItem="Lounge Users"
      />
      <NewTableList
        // onChange={handleQuery}
        placeholder="Search groups by name or description..."
        btnLabel="Create Group"
        // route="/create-group"
        isFilter={false}
        data={data}
        columns={columns}
        // title="Groups"
        totalCount={loungeUserCount}
        limit={limit}
        paginate
        setOffset={setOffset}
        onRow={(record) => history.push(`/lounge-user/${record?.id}`)}
        loading={loungeUserLoading}
        trStyle={{
          cursor: "pointer",
        }}
        // search
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 5%;
  padding-top: calc(10vh + 3%);
  overflow-y: auto;
`;

export default LoungeUsers;
