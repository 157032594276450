import gql from 'graphql-tag'

export const UpdateLoungeMutation = gql`
  mutation updateLounge($where: lounge_bool_exp!, $set: lounge_set_input) {
    update_lounge(where: $where, _set: $set) {
      returning {
        id
        name
      }
    }
  }
`