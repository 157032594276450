import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "react-apollo";
import moment from "moment";

import CustomSpinner from "../../ui/CustomSpinner";
import { DetailLayoutView } from "components/DetailView";
import { ToastContainer } from "react-toastify";
import { errorNotify, successNotify } from "utils/toaster";
import { Config } from "config";
import NewDetailView from "components/DetailView/NewDetailScreen";
import { UserContext } from "context/UserContext";
import { PrivateVideoPlaylist, UpdatePrivateVideoPlaylist } from 'graphql/query/video';


const PlaylistDetails = () => {
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(PrivateVideoPlaylist, { variables: { id }});
  const { token } = useContext(UserContext);
  
  const playlist = data?.privateVideoPlaylist[0];

  const [preview, setPreview] = useState();
  const [imageUrlFile, setImageUrlFile] = useState();

  const [values, setValues] = useState({
    title: "",
    description: "",
  });

  const [updateVideoPlaylist, { loading: updatingPlaylist }] = useMutation(
    UpdatePrivateVideoPlaylist,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    setValues({
      title: playlist?.title,
      description: playlist?.description,
      imageUrl: playlist?.imageUrl
    });

  }, [playlist]);
 
  const handleFile = async ({ target: { files } }) => {
    try {
      const imageSizeLimit = 500;
      const reader = new FileReader();
      const imageSize = files[0]?.size / 1024;

      if (imageSize > imageSizeLimit) {
        return errorNotify(
          'The size of the image should be less than equal to 500kb'
        );
      }

      if (files) {
        reader.readAsDataURL(files[0]);
      }

      setImageUrlFile(files[0]);

      reader.onloadend = function (e) {
        setPreview(reader.result);
      };

    } catch(e) {
      console.log(e.message, '....upload error')
    }
  
  };

  const handleUpdatePlaylist = async () => {
    const { title, description } = values;

    try {
      if (imageUrlFile) {
        const form = new FormData();
        form.append("imageUrl", imageUrlFile);
    
        const res = await fetch(`${Config.restApiUrl}/uploads/video/playlist-image`, {
          method: "POST",
          headers: {
            "Authorization": token
          },
          body: form,
        });
    
        const response = await res.json();
        const imageUrl = response?.data?.imageUrl;
    
        if (imageUrl) {
          await updateVideoPlaylist({
            variables: {
              id, 
              set: {
                imageUrl
              },
            },
          });
        }
      }
  
      await updateVideoPlaylist({
        variables: {
          id, 
          set: {
            title,
            description,
          },
        },
      });

      await refetch();
      successNotify(`Playlist Updated Successfully.`);
    } catch (error) {
      errorNotify("Something went wrong, try again");
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <CustomSpinner />
      ) : (
        <DetailLayoutView
          image={preview ? preview : playlist?.imageUrl}
          onChange={handleFile}
          update
          uploadName="Playlist"
          breadcrumbTitle="Playlists"
          breadcrumbItem={playlist?.title}
          breadcrumbRoute={"/playlists"}
        >
          <NewDetailView
            key1={{
              label: "Playlist Title",
              placeholder: "Playlist Title",
              name: "title",
              status: "edit",
              type: "input",
              isEditable: true,
              value: playlist?.title,
            }}
            key3={{
              label: "Playlist Description",
              placeholder: "Playlist Description",
              name: "description",
              type: "description",
              status: "edit",
              isEditable: true,
              value: playlist?.description,
            }}
            key4={{
              label: "Playlist Created",
              type: "text",
              value: moment(playlist?.createdAt).fromNow(),
            }}
            action={{
              type: "action",
              actionText: {
                btn3Text: "Update",
                status: "edit",
              },
              action: {
                btn3: () => handleUpdatePlaylist(),
                status: "edit",
              },
              actionLoading: {
                btn3Loading: updatingPlaylist,
              },
            }}
            values={values}
            setValues={setValues}
          />
          <ToastContainer />
        </DetailLayoutView>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 10vh);
  margin-top: 10vh;
  padding-bottom: 5vh;
  overflow-y: auto;
`;

export default PlaylistDetails;
