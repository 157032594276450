/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { formatPrice } from "utils/formatPrice";
import { Flex } from "../style";

const DetailPriceValue = ({
  values,
  setValues,
  create,
  price = 0,
  currency,
  state,
  setState,
  editIcon,
  closeIcon,
  editValues,
  setEditValues,
  edit,
  priceValueLabel,
  setPriceValueLabel,
}) => {
  const priceValue = formatPrice(priceValueLabel, currency?.toUpperCase());

  useEffect(() => {
    setEditValues({
      ...editValues,
      priceValue: price,
    });
  }, [state?.priceValueState, setPriceValueLabel]);

  return (
    <Flex borderBottom padding={`20px 5%`} style={{ position: "relative" }}>
      {/* {!create && <span>Price Value: {price ? priceValue : "N/A"}</span>} */}
      {!create && !state?.priceValueState && (
        <Flex width="100%">
          {<span>Price Value: {price ? priceValue : "FREE"}</span>}
          {!state?.loungeState && editIcon({ ...state, priceValueState: true })}
        </Flex>
      )}

      {create && (
        <Flex width="100%">
          <FormGroup className="select2-container row w-100">
            <Label className="col-3">Price Value (optional)</Label>
            <Col>
              <Input
                style={{ width: "100% !important" }}
                onChange={({ target: { value } }) => {
                  setPriceValueLabel(value);
                  setValues({
                    ...values,
                    priceValue: parseFloat(value),
                  });
                }}
                placeholder="Price value"
              />
            </Col>
          </FormGroup>
        </Flex>
      )}

      {!create && edit && state?.priceValueState && (
        <Flex width="100%">
          <FormGroup className="select2-container w-100 row">
            <Label className="col-3">Price Value: </Label>
            <Col>
              <Input
                style={{ width: "100% !important" }}
                type="number"
                value={editValues?.priceValue}
                onChange={({ target: { value } }) => {
                  setPriceValueLabel(value);
                  setEditValues({
                    ...editValues,
                    priceValue: parseFloat(value),
                  });
                }}
              />
            </Col>
          </FormGroup>
          {state?.priceValueState &&
            closeIcon({ ...state, priceValueState: false })}
        </Flex>
      )}
    </Flex>
  );
};

export default DetailPriceValue;
