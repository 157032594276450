import React, { useContext, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-apollo';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Colors } from 'assets/colors/Colors';
import NewTableList from 'components/TableList/NewTable';
import YoutubeForm from 'components/YoutubeForm';
import {
	GetLoungeYoutubeQuery,
	GetLoungesYoutubeQuery,
	DeleteLoungeYoutube,
} from '../../graphql/query/loungeYoutube';
import { errorNotify, successNotify } from '../../utils/toaster';
import { UserContext } from 'context/UserContext';
import { LoungePortalContext } from 'context/LoungePortalContext';

const limit = 20;

const initialRowData = {
	channelId: '',
	playlistId: '',
	channelName: '',
	loungeId: '',
	loungeName: '',
};

const Youtube = () => {
	const [offset, setOffset] = useState(0);
	const [addData, setAddData] = useState(true);
	const [rowData, setRowData] = useState(initialRowData);
	const [modal, setModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoungeYoutube, { loading: deleting }] =
		useMutation(DeleteLoungeYoutube);
	const { isAdminPortal } = useContext(UserContext);
	const { loungeId } = useContext(LoungePortalContext)

	const {
		data: loungesYoutubeData,
		refetch,
	} = useQuery(GetLoungesYoutubeQuery, {
		skip: !isAdminPortal,
		variables: {
			limit,
			offset,
		},
	});

	const {
		data: loungeYoutubeData,
		refetch: refetchLoungeYoutube,
	} = useQuery(GetLoungeYoutubeQuery, {
		skip: isAdminPortal,
		variables: {
			loungeId,
		},
	});

	const loungesYoutube = isAdminPortal ? loungesYoutubeData?.loungeYoutube : loungeYoutubeData?.loungeYoutube;
	const loungesYoutubeAggregate = isAdminPortal ? 
		loungesYoutubeData?.loungeYoutube_aggregate?.aggregate?.count : loungeYoutubeData?.loungeYoutube_aggregate?.aggregate?.count;

	const data = _.map(
		loungesYoutube,
		({
			id,
			lounge: { name: loungeName, id: loungeId },
			channelId,
			channelName,
			playlistId,
		}) => ({
			id,
			lounge: loungeName,
			channelName,
			channelId,
			playlistId,
			update: (
				<IconContainer
					onClick={() => {
						setModal(true);
						setAddData(false);
						setRowData({
							channelId,
							channelName,
							playlistId,
							loungeName,
							loungeId,
						});
					}}
				>
					<i class='fas fa-edit'></i>
				</IconContainer>
			),
			delete: (
				<IconContainer
					onClick={() => {
						setDeleteModal(true);
						setRowData({
							channelId,
							channelName,
							playlistId,
							loungeName,
							loungeId,
						});
					}}
				>
					<i class='fas fa-trash'></i>
				</IconContainer>
			),
		})
	);

	const columns = [
		{ id: 1, title: 'Lounge' },
		{ id: 2, title: 'Channel Name' },
		{ id: 3, title: 'Channel ID' },
		{ id: 4, title: 'Playlist ID' },
		{ id: 5, title: 'Update' },
		{ id: 6, title: 'Delete' },
	];

	return (
		<Wrapper>
			<div>
				<NewTableList
					data={data}
					columns={columns}
					title='Lounge Youtube'
					totalCount={loungesYoutubeAggregate}
					limit={limit}
					setOffset={setOffset}
					paginate
					loading={false}
					actions={{
						title: 'Hide',
						background: Colors.red200,
						color: Colors.red800,
					}}
					actionBtn={
						<ActionBtn>
							<Button
								color={'success'}
								disabled={!isAdminPortal && loungesYoutubeAggregate}
								onClick={() => {
									setModal(true);
									setAddData(true);
								}}
							>
								Add Channel Data
							</Button>
						</ActionBtn>
					}
				/>
			</div>
			<Modal isOpen={deleteModal} centered={true}>
				<ModalBody>
					<p>
						Are you sure you want to delete{' '}
						<span style={{ textTransform: 'lowercase', fontWeight: 'bold' }}>
							{rowData?.loungeName}
						</span>{' '}
						channel data?
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						onClick={() => {
							setDeleteModal(false);
							setRowData(initialRowData);
						}}
					>
						No
					</Button>{' '}
					<Button
						color='danger'
						onClick={async () => {
							try {
								setDeleteModal(false);
								await deleteLoungeYoutube({
									variables: {
										loungeId: rowData.loungeId,
									},
								});
								if (isAdminPortal) {
									await refetch();
								} else {
									await refetchLoungeYoutube();
								}
								successNotify('Channel Data Deleted Successfully');
							} catch (e) {
								errorNotify('Unable To Delete Data');
							} finally {
								setRowData(initialRowData);
							}
						}}
					>
						Yes
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={modal}>
				<ModalBody style={{ position: 'relative' }}>
					<h3 className='text-center mb-5'>
						{addData ? 'Add Channel Data' : 'Update Channel Data'}
					</h3>
					<span
						onClick={() => {
							setModal(!modal);
							setRowData(initialRowData);
						}}
						style={{
							fontSize: 25,
							position: 'absolute',
							top: 10,
							right: 15,
							cursor: 'pointer',
						}}
					>
						&times;
					</span>
					<YoutubeForm
						addData={addData}
						setModal={setModal}
						rowData={rowData}
						setRowData={setRowData}
						refetch={refetch}
						refetchLoungeYoutube={refetchLoungeYoutube}
					/>
				</ModalBody>
			</Modal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 50px;
	padding-top: 15vh;
	overflow-y: auto;
`;

const ActionBtn = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
`;

const IconContainer = styled.div`
	padding: 10px 0px;
	width: 50%;
	cursor: pointer;
`;

export default Youtube;
