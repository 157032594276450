import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import moment from 'moment';
import { UserDeleteApplicationQuery } from 'graphql/query/userDeleteApplication';
import { Colors } from 'assets/colors/Colors';
import NewTableList from 'components/TableList/NewTable';

const limit = 20;

const AccountDeletion = () => {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		if (refetch) {
			refetch();
		}

		// eslint-disable-next-line
	}, []);

	const {
		data: userDeleteApplication,
		error,
		refetch,
	} = useQuery(UserDeleteApplicationQuery, {
		variables: {
			limit,
			offset,
		},
	});

	const userDeleteApplications =
		userDeleteApplication?.userAccountDeleteApplication;
	const userAccountAggregate =
		userDeleteApplication?.userAccountDeleteApplication_aggregate?.aggregate
			?.count;

	const data = _.map(
		userDeleteApplications,
		({
			id,
			dateOfApplicationSubmission,
			dateOfAccountDeletion,
			accountDeleted,
			email,
			reasonOfAccountDeletion,
			username,
		}) => ({
			id,
			username: `@${username}`,
			email,
			reasonOfAccountDeletion,
			dateOfApplicationSubmission: moment(dateOfApplicationSubmission).format(
				'DD/MM/YYYY'
			),
			dateOfAccountDeletion: moment(dateOfAccountDeletion).format('DD/MM/YYYY'),
			status: accountDeleted ? 'deleted' : 'active',
		})
	);

	const columns = [
		{ id: 1, title: 'Username' },
		{ id: 2, title: 'Email' },
		{ id: 3, title: 'Reason' },
		{ id: 4, title: 'Date of Submission' },
		{ id: 5, title: 'Date of Deletion' },
		{ id: 6, title: 'Status' },
	];

	return (
		<Wrapper>
			<div>
				<NewTableList
					data={data}
					columns={columns}
					title='User Delete Applications'
					totalCount={userAccountAggregate}
					limit={limit}
					setOffset={setOffset}
					paginate
					loading={false}
					actions={{
						title: 'Hide',
						background: Colors.red200,
						color: Colors.red800,
					}}
					trStyle={{
						cursor: 'pointer',
					}}
				/>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100vh;
	padding: 50px;
	padding-top: 15vh;
	overflow-y: auto;
`;

export default AccountDeletion;
